const message = {
  'zh-CN': {
    invitation: {
      "awardCount": "奖励会员",
      "awardRecord": "邀请记录",
      "awardTime": "时间",
      "awardType": "事件",
      "awardVipCount": "获得会员时长",
      "collectMorePlatform": "全能云下载",
      "commentName1": "52***ku",
      "commentName2": "李**了",
      "commentName3": "IKi***u",
      "commentName4": "m***te",
      "commentName5": "fh**ro",
      "commentName6": "旅***行",
      "commentName7": "周**明",
      "commentText1": "体验很好，脱机下载成功率很高，在线播放流畅。",
      "commentText2": "PikPak是用过最好的磁力下载软件，转存也很方便。",
      "commentText3": "软件非常棒，脱机下载速度非常快，云盘的容量也很大，非常nice！开发者加油！",
      "commentText4": "接口简洁、功能强大Telegram的视频极速秒存",
      "commentText5": "资源群里大佬推荐用的，脱机下载速度超快，关键是资源都不会失效比****强太多了，很多高清视频可以瞬间保存，直接播放，，各种分辨率都很流畅。有免费试用期而且现在App内的订阅价格在优惠促销，10T存储空间来说性价比很不错，已经出手年费。",
      "commentText6": "抱着试一试的态度使用了这个APP结果出乎意料；终于找到了一个能下载磁力的神器满速下载播放也很流畅。",
      "commentText7": "太香了吧，这个软件简直太强大了，解决了收藏视频很麻烦的痛点，而且有超大容量不限速的空间，支持。",
      "communityComment": "社交动态",
      "continueInvite": "邀请每位好友还额外奖励",
      "copyThat": "邀请链接已复制快去粘贴吧!",
      "day": "天",
      "earnFromYourInvited": "受邀好友的每次购买给您奖励",
      "firstInviteAward": "首次邀请好友还额外奖励",
      "fiveDay": "5天",
      "freeVip": "免费会员",
      "friend": "好友",
      "friendAward": "好友可多获得5天免费会员",
      "friendBuyVip": "好友购买会员",
      "friendInstall": "好友安装PikPak并完成登录",
      "group": "群组",
      "hadInvited": "已邀请好友",
      "hotToInvite": "如何邀请好友?",
      "introduceApp": "推荐给你一个很棒的应用:",
      "invitationPageTitle": "邀请奖励计划",
      "inviteAward": "邀请得无限会员",
      "inviteAwardTitle": "邀请成功",
      "invitedPageTitle": "好友邀请你使用PikPak",
      "invitedUser": "受邀用户",
      "inviteNow": "立即邀请",
      "inviteSuccessful": "邀请成功",
      "lookVipDataInfo": "查看邀请记录",
      "myInvitation": "我的邀请",
      "ok": "好的",
      "people": "人",
      "times": "次",
      "quickInstall": "赶快安装吧!",
      "quickInstallIos": "安装后在会员页面输入兑换码 <span style='color: #306EFF; font-weight: 600;'>{0}</span> 领取",
      "rules1": "您邀请的用户注册后一年内每次成功购买PikPak会员，赠送您购买天数的30%。例如：您邀请的用户一年内购买4次月会员，您得36天会员，购买年会员，您得110天会员。",
      "rules10": "此活动仅限PikPak用户参加，最终解释权归PikPak官方拥有。",
      "rules2": "成功邀请第1位新用户安装App您可额外得5天会员，后续您每邀请1位可额外获得2天会员。",
      "rules3": "被您邀请的App用户可在注册后额外获得5天会员。",
      "rules4": "新使用者通过您的专属链接下载PikPak App，在App内成功登录则视为有效邀请。除了直接发送链接，您也可以通过您的链接下载专属APK包，发送给新用户安装。",
      "rules13": "使用邀请码邀请时，新用户可以通过任意方法安装PikPak App，登录后在此页面（或在会员兑换码框）输入您的专属邀请码，验证成功则视为有效邀请。注意：新用户应在安装PikPak App后12小时内输入邀请码并提交验证，超出时限会导致验证失败。",
      "rules5": "新用户在注册之日起一年（365天），内通过官方会员订阅、App内购、会员码、体验会员码开通会员视为成功购买会员，但通过免费兑换码、赠送会员时间等得到免费会员资格，不被视为购买会员。",
      "rules6": "邀请安装App奖励限定前50位有效邀请，超出后邀请人将不再获得此奖励，但购买分成奖励没有人数、次数限制，上不封顶。",
      "rules7": "邀请或购买成功后系统将会在30分钟内发放会员时长给邀请人，特殊情况可能会有延迟。",
      "rules8": "同一台设备 或 同一个账号 仅会被计算为被邀请的新用户一次，PikPak官方会过滤异常数据。",
      "rules9": "对于违规行为（包括但不限于恶意刷量、仿真用户行为等），PikPak官方有权取消参与者的活动资格，同时采取收回奖励等措施。如果对于违规有异议，可以通过官方Telegram群进行申诉。",
      "rules11": "针对订阅类会员，试用期结束后的每次成功扣费视为有效开通。",
      "rules12": "邀请用户付费成功后退款，则相应的邀请天数可能会被收回。",
      "rulesDescribe": "规则说明",
      "rulesTitle1": "1. 奖励规则",
      "rulesTitle2": "2. 判定细则",
      "rulesTitle3": "3. 其它说明",
      "secondSaveAndPressPlay": "极速秒存 一键播放",
      "shareToFriend": "分享链接给好友",
      "supportTenT": "高达10T超大空间",
      "takeYouTenDayVip": "送你5天免费会员",
      "techYouShare": "你可以粘贴在多种场景成功率提高300%哦!",
      "tenAndInfinite": "畅享10T云盘空间 + 无限传输次数",
      "tenDayVip": "5天会员",
      "vip": "会员",
      "vipDay": "会员天数",
      "vipDays": "的会员天数",
      "vipDivided": "购买会员",
      "yourFriend": "你的好友",
      "noSuccessInvited": "当前无好友邀请记录",
      "ikV4HbwO1qOt4nPo0QiCa": "链接邀请好友",
      "dvwW3MMztFrPm1RhXOw": "另一种方法 - 邀请码邀请好友 >",
      "sgZ1opIxw0KhKoJsSsXx1": "输入邀请码",
      "u_3DrnZw0z7CQo9xT7WDo": "分享给好友",
      "8xCu7TNslf2gBjIouq3az": "推荐一个很棒的应用PikPak，现在安装还能获得5天免费会员%{0}",
      "ny_8N0IxY2SvAk66kOawb": "填写邀请码兑换会员",
      "ryRwlaLw0D2JxMpecnw0h": "输入来自邀请人的邀请码",
      "now08ek267KShO7vBUpOv": "立即兑换",
      "sPh3hjWiKhDoScsF8Krp": "恭喜你获得{0}天会员",
      "8XAhTbae4We_4dzOt75nZ": "邀请码确认成功",
      "aWlkrn8DckQmySqf6nNux": "好的",
      "zm6rO5J8c4pR0qR0FoIyN": "已建立邀请关系",
      "hB7uwO3Acv3A6kCdWigzJ": "当前绑定的邀请码%{0}",
      "3AJqq9tNfua7ObBOgoKt": "兑换失败请稍后重试",
      "jueAOc8UkZu9_3C4a1uL": "邀请码邀请好友",
      "78GfAohy4Ms7xV5AaCqXw": "好友安装PikPak并完成登录",
      "z9IPdSzpJxOmt6g1EbGrm": "好友输入邀请码",
      "z1Slg9a1Hvn7lrZ0ChLNg": "我的邀请码",
      "oHlo9H83unnDaAnWFp0B": "复制邀请码",
      "jwniqgQ0ndGisnxEzft6s": "推荐一个很棒的应用PikPak，安装后输入邀请码 {0} 还能获取5天免费会员，下载地址：{1}",
      "if1saWFaNUj7WZl8F4Ya": "情人节限时特惠",
      "lhPoztU7Mogu2jDhlmVz": "新用户享7天免费会员",
      "gbIzzbslRXfW0bZbpp71L": "购买会员史上最低7.5折",
      "mTNofZNzPUeAwncWpNPvL": "邀请享三重福利",
      "bu1O6BrObADowmjocv3bC": "好友可获得5天免费会员",
      "yMUxffrxJ6SXtLCoZh73S": "当前App版本过低",
      "kG5XWhDkOsLkBiAcW7svK": "请前往官网升级到最新版本",
      "bi3D6kOp71Rbp0sMn_9U9": "复制链接",
      "more-method-to-invite": "多种方式邀请好友",
      "g4HnOjI94Mv8zQy8IqrGe": "安装链接邀请",
      "3Je8aKdiynlFeQa_0ghh6": "立即邀请",
      "bFm47Z76KfqeSznR1x1Nm": "分享文件邀请",
      "wLl4vetKaCvzRdGlfGqv": "去分享文件",
      "stDuM9Vxbjk33cf8KcGq0": "邀请码邀请",
      "4kS5Bpf_2Q5aixlBzqn0Z": "我的邀请码",
      "wXUwHoCjXqksk75d2XPp": "复制邀请码",
      "u10KVi1oM1ofcDg6z5iEg": "分享给好友",
      "49ATeBXcmc21mJrg1GaFq": "创建分享并发送给好友",
      "h6M5kFr2SxJlBy0usxCzT": "好友存档并安装PikPak",
      "yjz7Yev6UufY8tyVxTj1E": "APP版本过低，请前往官网升级到最新版本， 访问地址已复制到剪切板",
      "jlXz6iCidsDMf5wK6PnJ": "APP版本过低 请前往\\设置-关于PikPak\\\"升级到最新版本\"",
      "k_6MjGjxI9BrmYaJMeiYa": "已复制到剪切板",
      "rules14": "使用分享文件邀请时，新用户创建账号并保存您分享的文件视为有效邀请，您可以获得新用户的购买分成奖励。新用户在创建账号后12小时内通过任意方法安装PikPak App您方可获得邀请安装奖励。",
      "appleDisclaimer": "本活动与Apple inc无关。",
      "iosInviteTips": "使用iPhone或iPad的好友，安装App后需要输入邀请码才能完成邀请",
    }
  },
  'zh-TW': {
    invitation: {
      "awardCount": "獎勵會員",
      "awardRecord": "邀請記錄",
      "awardTime": "時間",
      "awardType": "事件",
      "awardVipCount": "獲得會員時長",
      "collectMorePlatform": "全能雲下載",
      "commentName1": "52***ku",
      "commentName2": "李**了",
      "commentName3": "IKi***u",
      "commentName4": "m***te",
      "commentName5": "fh**ro",
      "commentName6": "旅***行",
      "commentName7": "周**明",
      "commentText1": "體驗很好，脫機下載成功率很高，在線播放流暢。",
      "commentText2": "PikPak是用過最好的磁力下載軟件，轉存也很方便。",
      "commentText3": "軟件非常棒，脫機下載速度非常快，雲盤的容量也很大，非常nice！開發者加油！",
      "commentText4": "介面簡潔、功能強大Telegram的視頻極速秒存",
      "commentText5": "資源群里大佬推薦用的，脫機下載速度超快，關鍵是資源都不會失效比****強太多了，很多高清視頻可以瞬間保存，直接播放，，各種分辨率都很流暢。有免費試用期而且現在App內的訂閱價格在優惠促銷，10T存儲空間來說性價比很不錯，已經出手年費。",
      "commentText6": "抱著試一試的態度使用了這個APP結果出乎意料；終於找到了一個能下載磁力的神器滿速下載播放也很流暢。",
      "commentText7": "太香了吧，這個軟件簡直太強大了，解決了收藏視頻很麻煩的痛點，而且有超大容量不限速的空間，支持。",
      "communityComment": "社交動態",
      "continueInvite": "邀請每位好友還額外獎勵",
      "copyThat": "邀請鏈接已複製快去粘貼吧!",
      "day": "天",
      "earnFromYourInvited": "受邀好友的每次購買給您獎勵",
      "firstInviteAward": "首次邀請好友還額外獎勵",
      "fiveDay": "5天",
      "freeVip": "免費會員",
      "friend": "好友",
      "friendAward": "好友可多獲得5天免費會員",
      "friendBuyVip": "好友購買會員",
      "friendInstall": "好友安裝PikPak並完成登錄",
      "group": "群組",
      "hadInvited": "已邀請好友",
      "hotToInvite": "如何邀請好友?",
      "introduceApp": "推薦給你一個很棒的應用:",
      "invitationPageTitle": "邀請獎勵計畫",
      "inviteAward": "邀請得無限會員",
      "inviteAwardTitle": "邀請成功",
      "invitedPageTitle": "好友邀請你使用PikPak",
      "invitedUser": "受邀用戶",
      "inviteNow": "立即邀請",
      "inviteSuccessful": "邀請成功",
      "lookVipDataInfo": "查看邀請記錄",
      "myInvitation": "我的邀請",
      "ok": "好的",
      "people": "人",
      "times": "次",
      "quickInstall": "趕快安裝吧!",
      "quickInstallIos": "安裝後在會員頁面輸入兌換碼 <span style='color: #306EFF; font-weight: 600;'>{0}</span> 領取",
      "rules1": "您邀請的用戶註冊後一年內每次成功購買PikPak會員，贈送您購買天數的30%。例如：您邀請的用戶一年內購買4次月會員，您得36天會員，購買年會員，您得110天會員。",
      "rules10": "此活動僅限PikPak用戶參加，最終解釋權歸PikPak官方擁有。",
      "rules2": "成功邀請第1位新用戶安裝App您可額外得5天會員，後續您每邀請1位可額外獲得2天會員。",
      "rules3": "被您邀請的App用戶可在註冊後額外獲得5天會員。",
      "rules4": "新使用者通過您的專屬鏈接下載PikPak App，在App內成功登錄則視為有效邀請。除了直接發送鏈接，您也可以通過您的鏈接下載專屬APK包，發送給新用戶安裝。",
      "rules13": "使用邀請碼邀請時，新用戶可以通過任意方法安裝PikPak App，登錄後在此頁面（或在會員兌換碼框）輸入您的專屬邀請碼，驗證成功則視為有效邀請。注意：新用戶應在安裝PikPak App後12小時內輸入邀請碼並提交驗證，超出時限會導致驗證失敗。",
      "rules5": "新用戶在註冊之日起一年（365天），內通過官方會員訂閱、App內購、會員碼、體驗會員碼開通會員視為成功購買會員，但通過免費兌換碼、贈送會員時間等得到免費會員資格，不被視為購買會員。",
      "rules6": "邀請安裝App獎勵限定前50位有效邀請，超出後邀請人將不再獲得此獎勵，但購買分成獎勵沒有人數、次數限制，上不封頂。",
      "rules7": "邀請或購買成功後系統將會在30分鐘內發放會員時長給邀請人，特殊情況可能會有延遲。",
      "rules8": "同一台設備 或 同一個賬號 僅會被計算為被邀請的新用戶一次，PikPak官方會過濾異常數據。",
      "rules9": "對於違規行為（包括但不限於惡意刷量、仿真用戶行為等），PikPak官方有權取消參與者的活動資格，同時採取收回獎勵等措施。如果對於違規有異議，可以通過官方Telegram群進行申訴。",
      "rules11": "針對訂閱類會員，試用期結束後的每次成功扣費視為有效開通。",
      "rules12": "邀請用戶付費成功後退款，則相應的邀請天數可能會被收回。",
      "rulesDescribe": "規則說明",
      "rulesTitle1": "1. 獎勵規則",
      "rulesTitle2": "2. 判定細則",
      "rulesTitle3": "3. 其它說明",
      "secondSaveAndPressPlay": "極速秒存 一鍵播放",
      "shareToFriend": "分享鏈接給好友",
      "supportTenT": "高達10T超大空間",
      "takeYouTenDayVip": "送你5天免費會員",
      "techYouShare": "你可以粘貼在多種場景成功率提高300%哦!",
      "tenAndInfinite": "暢享10T雲盤空間 + 無限傳輸次數",
      "tenDayVip": "5天會員",
      "vip": "會員",
      "vipDay": "會員天數",
      "vipDays": "的會員天數",
      "vipDivided": "購買會員",
      "yourFriend": "你的好友",
      "noSuccessInvited": "當前無好友邀請記錄",
      "ikV4HbwO1qOt4nPo0QiCa": "鏈接邀請好友",
      "dvwW3MMztFrPm1RhXOw": "另一種方法 - 邀請碼邀請好友 >",
      "sgZ1opIxw0KhKoJsSsXx1": "輸入邀請碼",
      "u_3DrnZw0z7CQo9xT7WDo": "分享給好友",
      "8xCu7TNslf2gBjIouq3az": "推薦一個很棒的應用PikPak，現在安裝還能獲得5天免費會員%{0}",
      "ny_8N0IxY2SvAk66kOawb": "填寫邀請碼兌換會員",
      "ryRwlaLw0D2JxMpecnw0h": "輸入來自邀請人的邀請碼",
      "now08ek267KShO7vBUpOv": "立即兌換",
      "sPh3hjWiKhDoScsF8Krp": "恭喜你獲得{0}天會員",
      "8XAhTbae4We_4dzOt75nZ": "邀請碼確認成功",
      "aWlkrn8DckQmySqf6nNux": "好的",
      "zm6rO5J8c4pR0qR0FoIyN": "已建立邀請關係",
      "hB7uwO3Acv3A6kCdWigzJ": "當前綁定的邀請碼%{0}",
      "3AJqq9tNfua7ObBOgoKt": "兌換失敗請稍後重試",
      "jueAOc8UkZu9_3C4a1uL": "邀請碼邀請好友",
      "78GfAohy4Ms7xV5AaCqXw": "好友安裝PikPak並完成登錄",
      "z9IPdSzpJxOmt6g1EbGrm": "好友輸入邀請碼",
      "z1Slg9a1Hvn7lrZ0ChLNg": "我的邀請碼",
      "oHlo9H83unnDaAnWFp0B": "複製邀請碼",
      "jwniqgQ0ndGisnxEzft6s": "推薦一個很棒的應用PikPak，安裝後輸入邀請碼 {0} 還能獲取5天免費會員下載地址：{1}",
      "if1saWFaNUj7WZl8F4Ya": "情人節限時特惠",
      "lhPoztU7Mogu2jDhlmVz": "新用戶享7天免費會員",
      "gbIzzbslRXfW0bZbpp71L": "購買會員史上最低7.5折",
      "mTNofZNzPUeAwncWpNPvL": "邀請享三重福利",
      "bu1O6BrObADowmjocv3bC": "好友可獲得5天免費會員",
      "yMUxffrxJ6SXtLCoZh73S": "當前App版本過低",
      "kG5XWhDkOsLkBiAcW7svK": "請前往官網升級到最新版本",
      "bi3D6kOp71Rbp0sMn_9U9": "複製鏈接",
      "more-method-to-invite": "多種方式邀請好友",
      "g4HnOjI94Mv8zQy8IqrGe": "安裝鏈接邀請",
      "3Je8aKdiynlFeQa_0ghh6": "立即邀請",
      "bFm47Z76KfqeSznR1x1Nm": "分享文件邀請",
      "wLl4vetKaCvzRdGlfGqv": "去分享文件",
      "stDuM9Vxbjk33cf8KcGq0": "邀請碼邀請",
      "4kS5Bpf_2Q5aixlBzqn0Z": "我的邀請碼",
      "wXUwHoCjXqksk75d2XPp": "複製邀請碼",
      "u10KVi1oM1ofcDg6z5iEg": "分享給好友",
      "49ATeBXcmc21mJrg1GaFq": "創建分享併發送給好友",
      "h6M5kFr2SxJlBy0usxCzT": "好友保存檔並安裝PikPak",
      "yjz7Yev6UufY8tyVxTj1E": "APP版本過低，請前往官網升級到最新版本，訪問位址已複製到剪切板",
      "jlXz6iCidsDMf5wK6PnJ": "APP版本過低 請前往\\設置-關於PikPak\\\"升級到最新版本\"",
      "k_6MjGjxI9BrmYaJMeiYa": "已複製到剪切板",
      "rules14": "使用分享文件邀請時,新用戶創建賬號並保存您分享的文件視為有效邀請,您可以獲得新用戶的購買分成獎勵。 新用戶在創建賬號後12小時內通過任意方法安裝PikPak App您方可獲得邀請安裝獎勵。",
      "appleDisclaimer": "本活動與Apple inc無關。",
      "iosInviteTips": "使用iPhone或iPad的好友，安裝App後需要輸入邀請碼才能完成邀請",
    }
  },
  'en-US': {
    invitation: {
      "awardCount": "Reward Premium",
      "awardRecord": "Invitation Record",
      "awardTime": "Time",
      "awardType": "Event",
      "awardVipCount": "Obtain Premium time",
      "collectMorePlatform": "All-in-one cloud download",
      "commentName1": "52***ku,",
      "commentName2": "李**了,",
      "commentName3": "IKi***u,",
      "commentName4": "m***te,",
      "commentName5": "fh**ro,",
      "commentName6": "旅***行,",
      "commentName7": "周**明,",
      "commentText1": "The experience is very good, the success rate of offline download is very high and the online playback is smooth.",
      "commentText2": "PikPak is the best magnet download software ever used, plus it's also easy to transfer and safe files.",
      "commentText3": "The software is great, the offline download speed is very fast, the large cloud storage capacity is also very nice! Good job devs! .",
      "commentText4": "Simple interface, powerful function and the app can quickily downloand Telegram videos.",
      "commentText5": "Got recommended by mates. The offline download speed is super fast and the key is that the resources will not fail, so much stronger than ****.  A lot of HD video can be instantly saved, directly play, all kinds of resolutions are very smooth. There is a free trial period, and the current subscription price within the app is in the promotional discount, 10T storage space is very cost-effective. So i brought the annual plan already.",
      "commentText6": "I used this APP with an attitude of trying it out, and the results were unexpected. Finally found an app that excels in torrent download, also the app provides full speed download, the playback is also very smooth.",
      "commentText7": "This software is simply too powerfu. It solved the annoyence of collecting wanted videos, and has a large capacity unlimited space. Surely gets my support.",
      "communityComment": "Social Dynamic",
      "continueInvite": "Additional bonus for each invitation",
      "copyThat": "Invite link copied. Go share it!",
      "day": "Day",
      "earnFromYourInvited": "Each purchase of your invited friends will give you a reward",
      "firstInviteAward": "There will be a bonus for inviting friends for the first time",
      "fiveDay": "5 Days",
      "freeVip": "Free Premium",
      "friend": "Friend",
      "friendAward": "Friends get 5 more days of free Premium",
      "friendBuyVip": "Friends purchase Premium",
      "friendInstall": "Your friend installs PikPak and login",
      "group": "Groups",
      "hadInvited": "Invited friends",
      "hotToInvite": "How to invite friends?",
      "introduceApp": "Recommend a great app to you",
      "invitationPageTitle": "Referral reward program",
      "inviteAward": "Referral for Free Premium",
      "inviteAwardTitle": "Invitation Successful",
      "invitedPageTitle": "Friends invite you to use PikPak",
      "invitedUser": "Invited User",
      "inviteNow": "Invite Now",
      "inviteSuccessful": "Successfully invite",
      "lookVipDataInfo": "View Invitation History",
      "myInvitation": "My Invitations",
      "ok": "Ok",
      "people": "People",
      "times": "Times",
      "quickInstall": "Install it now!",
      "quickInstallIos": "After installation, enter the redeem code <span style='color: #306EFF; font-weight: 600;'>{0}</span> on the Premium page to get receive it",
      "rules1": "Each time your invited users successfully purchase PikPak Premium within one year after registration, you will get 30% of the number of days purchased. For example, if the user you invited buys  one month Premium  four times within one year, you will get 36 days of Premium, and if the user you invited buys annual Premium, you will get 110 days of Premium.",
      "rules10": "This program is only available for PikPak users, and the final interpretation of the rules shall be made by PikPak officials.",
      "rules2": "You will get 5 days of Premium if you invite the first new user to install the App, and you will get another 2 days of Premium by next every user you invited successfully.",
      "rules3": "The App user you invite will get 5 extra days of Premium after registration.",
      "rules4": "New users who download the PikPak App through your exclusive link and successfully login in the App will be considered as a valid invitation. In addition to sending the link directly, you can also download the exclusive APK package through your link and send it to new users to install.",
      "rules13": "New users can install the PikPak App through any method by using the invitation code. After logging in, new users need to submit exclusive invitation code from inviter on this page (or in the Premium Redeem box), in order that new users will be considered as valid invitation after successful verification. Note: New users should enter the invitation code and verify within 12 hours after installing. Exceeding the time limit will result in verification failure.",
      "rules5": "Within one year (365 days) from the date of registration, new users who purchase Premium through official Premium subscription, in-app purchase, Premium code, or experience Premium code are considered as purchased Premium successfully, but getting a free Premium through a free redemption code, gift of Premium time, etc. is not considered as a purchased Premium.",
      "rules6": "Bonus for invitation to install the App is limited to the first 50 valid invitations, after that the invitee will no longer get this bonus, but there is no limit to the number of people and times of premium purchase bonus, and it is not capped.",
      "rules7": "After the invitation or purchase is successful,  the system will automatically award days of Premium to the inviter. There can be delays under special circumstances.",
      "rules8": "The same device or account will only be counted as 1 invited new user. PikPak officials will filter out abnormal data.\",",
      "rules9": "In the event of rule violations (including, but not limited to, malicious spamming and botting), PikPak officials have the right to cancel the participant's event qualifications and revoke all rewards. If you have any objections, you may appeal through the official Telegram group.",
      "rules11": "For subscription members, each successful deduction after the end of the trial period is deemed to be a valid activation.",
      "rules12": "If the invited user is refunded after a successful payment, the corresponding bonus days may be withdrawn.",
      "rulesDescribe": "Rules",
      "rulesTitle1": "Reward Rules",
      "rulesTitle2": "Judgment detailed rules",
      "rulesTitle3": "Other Clarifications",
      "secondSaveAndPressPlay": "Instant save, 1-click playback",
      "shareToFriend": "Share the link with your friend",
      "supportTenT": "Up to 10T storage capacity",
      "takeYouTenDayVip": "Now provide you with 5 days of Premium for free",
      "techYouShare": "You can now paste the share link with tripple the success rate.",
      "tenAndInfinite": "10TB cloud storage cpacity + unlimited cloud transfer",
      "tenDayVip": "5 day Premium",
      "vip": "Premium",
      "vipDay": "Premium Days",
      "vipDays": "Days of Premium",
      "vipDivided": "Purchase Premium",
      "yourFriend": "Your Friend",
      "noSuccessInvited": "There is no invitation history currently",
      "ikV4HbwO1qOt4nPo0QiCa": "Link to invite friends",
      "dvwW3MMztFrPm1RhXOw": "Another method - Invite friends by Invitation Code",
      "sgZ1opIxw0KhKoJsSsXx1": "Enter the Invitation Code",
      "u_3DrnZw0z7CQo9xT7WDo": "Share with friends",
      "8xCu7TNslf2gBjIouq3az": "Recommend a great app PikPak, install it now and get 5 days free Premium: {0}",
      "ny_8N0IxY2SvAk66kOawb": "Enter the Invitation Code to redeem Premium",
      "ryRwlaLw0D2JxMpecnw0h": "Enter the code from the inviter",
      "now08ek267KShO7vBUpOv": "Redeem Now",
      "sPh3hjWiKhDoScsF8Krp": "Congratulations on your {0} days Premium!",
      "8XAhTbae4We_4dzOt75nZ": "Invitation code confirmed successfully",
      "aWlkrn8DckQmySqf6nNux": "OK",
      "zm6rO5J8c4pR0qR0FoIyN": "Invitation relationship established",
      "hB7uwO3Acv3A6kCdWigzJ": "Currently binded Invitation Code: {0}",
      "3AJqq9tNfua7ObBOgoKt": "Redemption failed, please try again later",
      "jueAOc8UkZu9_3C4a1uL": "Invite friends by Invitation Code",
      "78GfAohy4Ms7xV5AaCqXw": "Friend installed PikPak and finished login",
      "z9IPdSzpJxOmt6g1EbGrm": "Friends enter Invitation Code",
      "z1Slg9a1Hvn7lrZ0ChLNg": "My Inivitation Code",
      "oHlo9H83unnDaAnWFp0B": "Copy Invitation Code",
      "jwniqgQ0ndGisnxEzft6s": "Recommend a great app PikPak, after installation enter Invitation Code {0} and get 5 days free Premium, download here: {1}",
      "if1saWFaNUj7WZl8F4Ya": "Limited time offer in Valentines Day",
      "lhPoztU7Mogu2jDhlmVz": "7 days free Premium for new users",
      "gbIzzbslRXfW0bZbpp71L": "25% off Premium price - the lowest ever",
      "mTNofZNzPUeAwncWpNPvL": "Invite to enjoy triple benefits",
      "bu1O6BrObADowmjocv3bC": "Friends can get 5-day free Premium",
      "yMUxffrxJ6SXtLCoZh73S": "Current app version is too low",
      "kG5XWhDkOsLkBiAcW7svK": "Go to the official website to upgrade to the latest version",
      "bi3D6kOp71Rbp0sMn_9U9": "Copy link",
      "more-method-to-invite": "Invite friends in multiple ways",
      "g4HnOjI94Mv8zQy8IqrGe": "Install Link Invites",
      "3Je8aKdiynlFeQa_0ghh6": "Invite Now",
      "bFm47Z76KfqeSznR1x1Nm": "Invite by sharing files",
      "wLl4vetKaCvzRdGlfGqv": "To share files",
      "stDuM9Vxbjk33cf8KcGq0": "Invite with Invitation Code",
      "4kS5Bpf_2Q5aixlBzqn0Z": "My Invitation Code",
      "wXUwHoCjXqksk75d2XPp": "Copy Invitation Code",
      "u10KVi1oM1ofcDg6z5iEg": "Share to friends",
      "49ATeBXcmc21mJrg1GaFq": "Create & Share & Send link to friends.",
      "h6M5kFr2SxJlBy0usxCzT": "Friends save files and install PikPak",
      "yjz7Yev6UufY8tyVxTj1E": "App version is too low. Please go to the official website to upgrade to the latest version. The access address has been copied to the clipboard.",
      "jlXz6iCidsDMf5wK6PnJ": "App version is too low. Please go to \\\"Settings - About PikPak \\\" to upgrade to the latest version",
      "k_6MjGjxI9BrmYaJMeiYa": "Copied to clipboard",
      "rules14": "An invite considered valid when a file is shared, and a new user creates an account and saves the file you’ve shared. You will then receive Purchase Bonus. If a new user installs the PikPak App in any way within 12 hours after creating the account, you will receive Invite-To-Install Reward.",
      "appleDisclaimer": "This activity has nothing to do with Apple inc.",
      "iosInviteTips": "For your friends who uses an iPhone or iPad, they will need to enter an invitation code after installing PikPak.",
    }
  },
  'id': {
    invitation: {
      "awardCount": "Hadiah Premium",
      "awardRecord": "Catatan Undangan",
      "awardTime": "Waktu",
      "awardType": "Peristiwa",
      "awardVipCount": "Dapatkan waktu Premium",
      "collectMorePlatform": "Unduhan cloud all-in-one",
      "commentName1": "52***ku",
      "commentName2": "李**了",
      "commentName3": "IKi***u",
      "commentName4": "m***te",
      "commentName5": "fh**ro",
      "commentName6": "旅***行",
      "commentName7": "周**明",
      "commentText1": "Pengalamannya sangat bagus, tingkat keberhasilan unduhan offline sangat tinggi dan pemutaran online lancar.",
      "commentText2": "PikPak adalah perangkat lunak pengunduhan magnetik terbaik yang pernah digunakan, ditambah juga mudah untuk mentransfer dan file yang aman.",
      "commentText3": "Perangkat lunaknya hebat, kecepatan unduh offline sangat cepat, kapasitas cloud disk yang besar juga sangat bagus! Kerja bagus devs! .",
      "commentText4": "Antarmuka yang sederhana, fungsi yang kuat, dan aplikasi dapat dengan cepat mengunduh video Telegram.",
      "commentText5": "Direkomendasikan oleh teman-teman. Kecepatan unduh offline sangat cepat dan kuncinya adalah sumber daya tidak akan gagal, jauh lebih kuat daripada ****. Banyak video HD dapat langsung disimpan, langsung diputar, semua jenis resolusi sangat halus. Ada masa percobaan gratis, dan harga berlangganan saat ini dalam aplikasi dalam diskon promosi, ruang penyimpanan 10T sangat hemat biaya. Jadi saya sudah membawa rencana tahunan.",
      "commentText6": "Saya menggunakan APP ini dengan sikap mencoba, dan hasilnya tidak terduga. Akhirnya menemukan aplikasi yang unggul dalam unduhan torrent, juga aplikasi ini menyediakan unduhan kecepatan penuh, pemutarannya juga sangat lancar.",
      "commentText7": "Perangkat lunak ini terlalu kuat. Ini memecahkan gangguan mengumpulkan video yang diinginkan, dan memiliki kapasitas ruang tak terbatas yang besar. Pasti mendapat dukungan saya.",
      "communityComment": "Dinamika Sosial",
      "continueInvite": "Bonus tambahan untuk setiap undangan",
      "copyThat": "Tautan undangan telah disalin dan ditempel!",
      "day": "Hari",
      "earnFromYourInvited": "Setiap pembelian teman yang diundang akan memberikan hadiah",
      "firstInviteAward": "Akan ada bonus untuk mengundang teman untuk pertama kalinya",
      "fiveDay": "5 hari",
      "freeVip": "Premium Gratis",
      "friend": "teman",
      "friendAward": "Teman mendapatkan 5 hari lagi Premium gratis",
      "friendBuyVip": "Teman membeli Premium",
      "friendInstall": "Teman Anda menginstal PikPak dan login",
      "group": "Grup",
      "hadInvited": "Teman yang diundang",
      "hotToInvite": "Bagaimana cara mengundang teman?",
      "introduceApp": "Rekomendasikan aplikasi hebat untuk Anda",
      "invitationPageTitle": "Program hadiah rujukan",
      "inviteAward": "Referensi untuk Premium Gratis",
      "inviteAwardTitle": "Undangan Berhasil",
      "invitedPageTitle": "Teman mengundang Anda untuk menggunakan PikPak",
      "invitedUser": "Pengguna yang Diundang",
      "inviteNow": "Undang Sekarang",
      "inviteSuccessful": "Berhasil mengundang",
      "lookVipDataInfo": "Lihat Riwayat Undangan",
      "myInvitation": "Undangan saya",
      "ok": "Oke",
      "people": "Rakyat",
      "times": "Waktu",
      "quickInstall": "Instal sekarang!",
      "quickInstallIos": "Setelah instalasi, masukkan kode pertukaran <span style='color: #306EFF; font-weight: 600;'>{0}</span> di halaman Premium untuk ",
      "rules1": "Setiap kali pengguna yang Anda undang berhasil membeli PikPak Premium dalam waktu satu tahun setelah pendaftaran, Anda akan mendapatkan 30% dari jumlah hari yang Anda beli. Misalnya, jika pengguna yang Anda undang membeli Premium satu bulan empat kali dalam satu tahun, Anda akan mendapatkan Premium 36 hari, dan jika pengguna yang Anda undang membeli Premium tahunan, Anda akan mendapatkan Premium 110 hari.",
      "rules10": "Acara ini hanya tersedia untuk pengguna PikPak, dan interpretasi akhir dari aturan akan dibuat oleh pejabat PikPak.",
      "rules2": "Anda akan mendapatkan 5 hari Premium jika Anda mengundang pengguna baru pertama untuk menginstal Aplikasi, dan Anda akan mendapatkan 2 hari Premium berikutnya setiap pengguna yang Anda undang berhasil.",
      "rules3": "Pengguna Aplikasi yang Anda undang akan mendapatkan 5 hari ekstra Premium setelah pendaftaran.",
      "rules4": "Pengguna baru yang mengunduh Aplikasi PikPak melalui tautan eksklusif Anda dan berhasil masuk ke Aplikasi akan dianggap sebagai undangan yang valid. Selain mengirim tautan secara langsung, Anda juga dapat mengunduh paket APK eksklusif melalui tautan Anda dan mengirimkannya ke pengguna baru untuk dipasang.",
      "rules13": "Pengguna baru dapat menginstal Aplikasi PikPak melalui metode apa pun dengan menggunakan kode undangan. Setelah login, pengguna baru harus mengirimkan kode undangan eksklusif dari pengundang di halaman ini (atau di kotak Premium Redeem), agar pengguna baru akan dianggap sebagai undangan yang valid setelah verifikasi berhasil. Catatan: Pengguna baru harus memasukkan kode undangan dan memverifikasi dalam waktu 12 jam setelah menginstal. Melebihi batas waktu akan mengakibatkan kegagalan verifikasi.",
      "rules5": "Dalam satu tahun (365 hari) sejak tanggal pendaftaran, pengguna baru yang membeli premium melalui langganan premium resmi, pembelian dalam aplikasi, kode premium, atau kode premium pengalaman dianggap berhasil membeli premium, tetapi mendapatkan premium gratis melalui kode penukaran, hadiah waktu premium, dll. tidak dianggap sebagai premi yang dibeli.",
      "rules6": "Bonus undangan untuk menginstal Aplikasi terbatas pada 50 undangan pertama yang valid, setelah itu yang diundang tidak akan lagi mendapatkan bonus ini, tetapi tidak ada batasan jumlah orang dan waktu bonus pembelian premium, dan tidak dibatasi.",
      "rules7": "Setelah undangan atau pembelian berhasil, sistem akan secara otomatis memberikan hari Premium kepada pengundang. Mungkin ada penundaan dalam keadaan khusus.",
      "rules8": "Perangkat atau akun yang sama hanya akan dihitung sebagai 1 pengguna baru yang diundang. Petugas PikPak akan menyaring data yang tidak normal.\",",
      "rules9": "Jika terjadi pelanggaran aturan (termasuk, namun tidak terbatas pada, spamming dan botting yang berbahaya), petugas PikPak berhak untuk membatalkan kualifikasi acara peserta dan mencabut semua hadiah. Jika Anda memiliki keberatan, Anda dapat mengajukan banding melalui grup Telegram resmi.",
      "rules11": "Untuk anggota berlangganan, setiap pengurangan yang berhasil setelah akhir periode uji coba dianggap sebagai aktivasi yang valid.",
      "rules12": "Jika pengguna yang diundang dikembalikan dananya setelah pembayaran berhasil, hari bonus yang sesuai dapat ditarik.",
      "rulesDescribe": "Aturan",
      "rulesTitle1": "Aturan Hadiah",
      "rulesTitle2": "Aturan detail penilaian",
      "rulesTitle3": "Klarifikasi lainnya",
      "secondSaveAndPressPlay": "Simpan instan, pemutaran 1-klik",
      "shareToFriend": "Bagikan tautan dengan teman Anda",
      "supportTenT": "Kapasitas penyimpanan hingga 10T",
      "takeYouTenDayVip": "Sekarang memberi Anda 5 hari Premium gratis",
      "techYouShare": "Anda sekarang dapat menempelkan tautan berbagi dengan tiga kali lipat tingkat keberhasilan.",
      "tenAndInfinite": "Penyimpanan cloud 10TB + transfer cloud tanpa batas",
      "tenDayVip": "Premi 5 hari",
      "vip": "Premium",
      "vipDay": "Hari Premium",
      "vipDays": "Hari Premium",
      "vipDivided": "Beli Premium",
      "yourFriend": "Temanmu",
      "noSuccessInvited": "Tidak ada riwayat undangan saat ini",
      "ikV4HbwO1qOt4nPo0QiCa": "Tautan untuk mengundang teman",
      "dvwW3MMztFrPm1RhXOw": "Metode lain - Undang teman dengan Kode Undangan",
      "sgZ1opIxw0KhKoJsSsXx1": "Masukkan Kode Undangan",
      "u_3DrnZw0z7CQo9xT7WDo": "Bagikan dengan teman",
      "8xCu7TNslf2gBjIouq3az": "Merekomendasikan aplikasi hebat PikPak, instal sekarang dan dapatkan Premium gratis 5 hari: {0}",
      "ny_8N0IxY2SvAk66kOawb": "Masukkan Kode Undangan untuk menebus Premium",
      "ryRwlaLw0D2JxMpecnw0h": "Masukkan kode dari pengundang",
      "now08ek267KShO7vBUpOv": "Tukarkan Sekarang",
      "sPh3hjWiKhDoScsF8Krp": "Selamat atas Premium {0} hari Anda!",
      "8XAhTbae4We_4dzOt75nZ": "Kode undangan berhasil dikonfirmasi",
      "aWlkrn8DckQmySqf6nNux": "Oke",
      "zm6rO5J8c4pR0qR0FoIyN": "Hubungan undangan terjalin",
      "hB7uwO3Acv3A6kCdWigzJ": "Kode Undangan yang saat ini terikat: {0}",
      "3AJqq9tNfua7ObBOgoKt": "Penukaran gagal, coba lagi nanti",
      "jueAOc8UkZu9_3C4a1uL": "Undang teman dengan Kode Undangan",
      "78GfAohy4Ms7xV5AaCqXw": "Teman menginstal PikPak dan selesai login",
      "z9IPdSzpJxOmt6g1EbGrm": "Teman masukkan Kode Undangan",
      "z1Slg9a1Hvn7lrZ0ChLNg": "Kode Undangan Saya",
      "oHlo9H83unnDaAnWFp0B": "Salin Kode Undangan",
      "jwniqgQ0ndGisnxEzft6s": "Merekomendasikan aplikasi PikPak yang hebat, setelah pemasangan masukkan Kode Undangan {0} dan dapatkan 5 hari Premium gratis, unduh di sini: {1}",
      "if1saWFaNUj7WZl8F4Ya": "Penawaran waktu terbatas di Hari Kasih Sayang",
      "lhPoztU7Mogu2jDhlmVz": "Premium gratis 7 hari untuk pengguna baru",
      "gbIzzbslRXfW0bZbpp71L": "Diskon 25% dari harga Premium - terendah yang pernah ada",
      "mTNofZNzPUeAwncWpNPvL": "Undang untuk menikmati manfaat tiga kali lipat",
      "bu1O6BrObADowmjocv3bC": "Teman bisa mendapatkan Premium gratis selama 5 hari",
      "yMUxffrxJ6SXtLCoZh73S": "Versi aplikasi saat ini terlalu rendah",
      "kG5XWhDkOsLkBiAcW7svK": "Buka situs web resmi untuk meningkatkan ke versi terbaru",
      "bi3D6kOp71Rbp0sMn_9U9": "Salin tautan",
      "more-method-to-invite": "Undang teman dengan berbagai cara",
      "g4HnOjI94Mv8zQy8IqrGe": "Instal Undangan Tautan",
      "3Je8aKdiynlFeQa_0ghh6": "Undang Sekarang",
      "bFm47Z76KfqeSznR1x1Nm": "Undang dengan berbagi file",
      "wLl4vetKaCvzRdGlfGqv": "Untuk berbagi file",
      "stDuM9Vxbjk33cf8KcGq0": "Undang dengan Kode Undangan",
      "4kS5Bpf_2Q5aixlBzqn0Z": "Kode Undangan Saya",
      "wXUwHoCjXqksk75d2XPp": "Salin Kode Undangan",
      "u10KVi1oM1ofcDg6z5iEg": "Bagikan ke teman",
      "49ATeBXcmc21mJrg1GaFq": "Buat & Bagikan & Kirim tautan ke teman.",
      "h6M5kFr2SxJlBy0usxCzT": "Teman menyimpan file dan menginstal PikPak",
      "yjz7Yev6UufY8tyVxTj1E": "Versi aplikasi terlalu rendah. Silakan pergi ke situs resmi untuk meng-upgrade ke versi terbaru. Alamat akses telah disalin ke clipboard.",
      "jlXz6iCidsDMf5wK6PnJ": "Versi aplikasi terlalu rendah. Silakan buka \\\"Pengaturan - Tentang PikPak\\\" untuk meningkatkan ke versi terbaru",
      "k_6MjGjxI9BrmYaJMeiYa": "Disalin ke papan klip",
      "rules14": "Undangan dianggap valid saat file dibagikan, dan pengguna baru membuat akun dan menyimpan file yang Anda bagikan. Anda kemudian akan menerima Bonus Pembelian. Jika pengguna baru menginstal Aplikasi PikPak dengan cara apa pun dalam waktu 12 jam setelah membuat akun, Anda akan menerima Hadiah Undang-Untuk-Instal.",
      "appleDisclaimer": "Aktivitas ini tidak ada hubungannya dengan Apple inc.",
      "iosInviteTips": "Untuk teman Anda yang menggunakan iPhone atau iPad, mereka harus memasukkan kode undangan setelah menginstal PikPak.",
    }
  },
  'ar': {
    invitation: {
      "awardCount": "مكافأة قسط",
      "awardRecord": "سجل الدعوة",
      "awardTime": "زمن",
      "awardType": "حدث",
      "awardVipCount": "احصل على وقت مميز",
      "collectMorePlatform": "تنزيل سحابة الكل في واحد",
      "commentName1": "52***ku",
      "commentName2": "李**了",
      "commentName3": "IKi***u",
      "commentName4": "m***te",
      "commentName5": "fh**ro",
      "commentName6": "旅***行",
      "commentName7": "周**明",
      "commentText1": "التجربة جيدة جدًا ، ومعدل نجاح التنزيل في وضع عدم الاتصال مرتفع جدًا والتشغيل عبر الإنترنت سلس.",
      "commentText2": "PikPak هو أفضل برنامج تنزيل مغناطيسي تم استخدامه على الإطلاق ، بالإضافة إلى أنه من السهل أيضًا نقل الملفات وتأمينها.",
      "commentText3": "البرنامج رائع ، وسرعة التنزيل في وضع عدم الاتصال سريعة جدًا ، كما أن سعة القرص السحابي الكبيرة لطيفة جدًا! المطورين بعمل جيد! .",
      "commentText4": "واجهة بسيطة ووظيفة قوية ويمكن للتطبيق تنزيل مقاطع فيديو Telegram بسرعة.",
      "commentText5": "حصلت على موصى به من قبل الاصحاب. سرعة التنزيل دون اتصال بالإنترنت فائقة السرعة والمفتاح هو أن الموارد لن تفشل ، فهي أقوى بكثير من ****. يمكن حفظ الكثير من مقاطع الفيديو عالية الدقة على الفور ، وتشغيلها مباشرة ، وجميع أنواع الدقة سلسة للغاية. هناك فترة تجريبية مجانية ، وسعر الاشتراك الحالي داخل التطبيق في الخصم الترويجي ، ومساحة تخزين 10T فعالة للغاية من حيث التكلفة. لذلك أحضرت الخطة السنوية بالفعل.",
      "commentText6": "لقد استخدمت هذا التطبيق بموقف تجربته ، وكانت النتائج غير متوقعة. وجدت أخيرًا تطبيقًا يتفوق في تنزيل التورنت ، كما يوفر التطبيق سرعة تنزيل كاملة ، كما أن التشغيل سلس للغاية.",
      "commentText7": "هذا البرنامج هو ببساطة قوي للغاية. لقد حلت مشكلة جمع مقاطع الفيديو المطلوبة ، ولها سعة كبيرة مساحة غير محدودة. بالتأكيد يحصل على دعمي.",
      "communityComment": "ديناميكية اجتماعية",
      "continueInvite": "مكافأة إضافية لكل دعوة",
      "copyThat": "تم نسخ رابط الدعوة ولصقه!",
      "day": "يوم",
      "earnFromYourInvited": "ستمنحك كل عملية شراء لأصدقائك المدعوين مكافأة",
      "firstInviteAward": "ستكون هناك مكافأة لدعوة الأصدقاء لأول مرة",
      "fiveDay": "5 ايام",
      "freeVip": "علاوة مجانيه",
      "friend": "صديق",
      "friendAward": "يحصل الأصدقاء على 5 أيام إضافية من Premium المجاني",
      "friendBuyVip": "يشتري الأصدقاء Premium",
      "friendInstall": "يقوم صديقك بتثبيت PikPak وتسجيل الدخول",
      "group": "مجموعات",
      "hadInvited": "أصدقاء مدعوون",
      "hotToInvite": "كيف تدعو الأصدقاء؟",
      "introduceApp": "اقترح عليك تطبيقًا رائعًا",
      "invitationPageTitle": "برنامج مكافأة الإحالة",
      "inviteAward": "الإحالة للحصول على قسط مجاني",
      "inviteAwardTitle": "نجحت الدعوة",
      "invitedPageTitle": "يدعوك الأصدقاء لاستخدام PikPak",
      "invitedUser": "المستخدم المدعو",
      "inviteNow": "ادعُ الآن",
      "inviteSuccessful": "نجح الدعوة",
      "lookVipDataInfo": "عرض سجل الدعوة",
      "myInvitation": "دعواتي",
      "ok": "موافق",
      "people": "الناس",
      "times": "مرات",
      "quickInstall": "قم بتثبيته الآن!",
      "quickInstallIos": "بعد التثبيت ، أدخل رمز التبادل <span style='color: #306EFF; font-weight: 600;'>{0}</span> في صفحة Premium للحصول عليه",
      "rules1": "في كل مرة يشتري فيها المستخدمون المدعوون PikPak Premium بنجاح في غضون عام واحد بعد التسجيل ، ستحصل على 30٪ من عدد الأيام التي اشتريتها. على سبيل المثال ، إذا اشترى المستخدم الذي قمت بدعوته شهرًا Premium أربع مرات خلال عام واحد ، فستحصل على 36 يومًا من Premium ، وإذا اشترى المستخدم الذي قمت بدعوته Premium سنويًا ، فستحصل على 110 يومًا من Premium.",
      "rules10": "هذا الحدث متاح فقط لمستخدمي PikPak ، وسيتولى مسؤولو PikPak التفسير النهائي للقواعد.",
      "rules2": "ستحصل على 5 أيام من Premium إذا قمت بدعوة أول مستخدم جديد لتثبيت التطبيق ، وستحصل على يومين آخرين من Premium عن طريق كل مستخدم قمت بدعوته بنجاح.",
      "rules3": "سيحصل مستخدم التطبيق الذي تدعو إليه على 5 أيام إضافية من Premium بعد التسجيل.",
      "rules4": "سيتم اعتبار المستخدمين الجدد الذين يقومون بتنزيل تطبيق PikPak من خلال الرابط الحصري الخاص بك ويقومون بتسجيل الدخول بنجاح في التطبيق بمثابة دعوة صالحة. بالإضافة إلى إرسال الرابط مباشرة ، يمكنك أيضًا تنزيل حزمة APK الحصرية من خلال الرابط الخاص بك وإرسالها إلى مستخدمين جدد لتثبيتها.",
      "rules13": "يمكن للمستخدمين الجدد تثبيت تطبيق PikPak من خلال أي طريقة باستخدام رمز الدعوة. بعد تسجيل الدخول ، يحتاج المستخدمون الجدد إلى إرسال رمز دعوة حصري من الداعي على هذه الصفحة (أو في مربع Premium Redeem) ، حتى يتم اعتبار المستخدمين الجدد دعوة صالحة بعد التحقق الناجح. ملاحظة: يجب على المستخدمين الجدد إدخال رمز الدعوة والتحقق خلال 12 ساعة بعد التثبيت. تجاوز الحد الزمني سيؤدي إلى فشل التحقق.",
      "rules5": "في غضون عام واحد (365 يومًا) من تاريخ التسجيل ، يُعتبر المستخدمون الجدد الذين يشترون Premium من خلال الاشتراك الرسمي المميز أو الشراء داخل التطبيق أو الرمز المميز أو تجربة الرمز المميز بمثابة قسط تم شراؤه بنجاح ، ولكن يحصلون على قسط مجاني من خلال اشتراك مجاني لا يعتبر رمز الاسترداد وهدية الوقت المميز وما إلى ذلك بمثابة قسط تم شراؤه.",
      "rules6": "تقتصر مكافأة الدعوة لتثبيت التطبيق على أول 50 دعوة صالحة ، وبعد ذلك لن يحصل المدعو على هذه المكافأة ، ولكن لا يوجد حد لعدد الأشخاص وأوقات مكافأة الشراء المتميزة ، ولم يتم تحديدها.",
      "rules7": "بعد نجاح الدعوة أو الشراء ، سيقوم النظام تلقائيًا بمنح أيام Premium إلى الداعي. يمكن أن يكون هناك تأخير في ظل ظروف خاصة.",
      "rules8": "سيتم احتساب نفس الجهاز أو الحساب كمستخدم واحد جديد تمت دعوته. سيقوم مسؤولو PikPak بتصفية البيانات غير الطبيعية. \"،",
      "rules9": "في حالة انتهاك القواعد (بما في ذلك ، على سبيل المثال لا الحصر ، البريد الإلكتروني العشوائي الخبيث والتخزين) ، يحق لمسؤولي PikPak إلغاء مؤهلات المشاركة في الحدث وإلغاء جميع المكافآت. إذا كان لديك أي اعتراضات ، فيمكنك الاستئناف من خلال مجموعة Telegram الرسمية.",
      "rules11": "بالنسبة لأعضاء الاشتراك ، يعتبر كل خصم ناجح بعد نهاية الفترة التجريبية بمثابة تنشيط صالح.",
      "rules12": "إذا تم رد أموال المستخدم المدعو بعد دفع ناجح ، فقد يتم سحب أيام المكافأة المقابلة.",
      "rulesDescribe": "قواعد",
      "rulesTitle1": "قواعد المكافأة",
      "rulesTitle2": "قواعد تفصيلية للحكم",
      "rulesTitle3": "توضيحات أخرى",
      "secondSaveAndPressPlay": "حفظ فوري ، تشغيل بنقرة واحدة",
      "shareToFriend": "شارك الرابط مع صديقك",
      "supportTenT": "سعة تخزين تصل إلى 10 تيرابايت",
      "takeYouTenDayVip": "نوفر لك الآن 5 أيام من Premium مجانًا",
      "techYouShare": "يمكنك الآن لصق رابط المشاركة بثلاثة أضعاف معدل النجاح.",
      "tenAndInfinite": "تخزين سحابي 10 تيرابايت + نقل سحابي غير محدود",
      "tenDayVip": "5 أيام قسط",
      "vip": "الممتازة",
      "vipDay": "الأيام المميزة",
      "vipDays": "أيام قسط",
      "vipDivided": "شراء قسط",
      "yourFriend": "صديقك",
      "noSuccessInvited": "لا يوجد سجل دعوة حاليا",
      "ikV4HbwO1qOt4nPo0QiCa": "رابط لدعوة الأصدقاء",
      "dvwW3MMztFrPm1RhXOw": "طريقة أخرى - دعوة الأصدقاء عن طريق رمز الدعوة",
      "sgZ1opIxw0KhKoJsSsXx1": "أدخل رمز الدعوة",
      "u_3DrnZw0z7CQo9xT7WDo": "شارك مع الاصدقاء",
      "8xCu7TNslf2gBjIouq3az": "أوصي بتطبيق PikPak الرائع ، وثبته الآن واحصل على 5 أيام مجانًا بريميوم: {0}",
      "ny_8N0IxY2SvAk66kOawb": "أدخل رمز الدعوة لاسترداد قسط",
      "ryRwlaLw0D2JxMpecnw0h": "أدخل الرمز من الداعي",
      "now08ek267KShO7vBUpOv": "تخليص الآن",
      "sPh3hjWiKhDoScsF8Krp": "تهانينا على اشتراكك في الإصدار المتميز الذي يبلغ {0} يومًا!",
      "8XAhTbae4We_4dzOt75nZ": "تم تأكيد رمز الدعوة بنجاح",
      "aWlkrn8DckQmySqf6nNux": "نعم",
      "zm6rO5J8c4pR0qR0FoIyN": "تم إنشاء علاقة دعوة",
      "hB7uwO3Acv3A6kCdWigzJ": "رمز الدعوة الملتزم حاليًا: {0}",
      "3AJqq9tNfua7ObBOgoKt": "فشل الاسترداد ، يرجى المحاولة مرة أخرى في وقت لاحق",
      "jueAOc8UkZu9_3C4a1uL": "دعوة الأصدقاء عن طريق رمز الدعوة",
      "78GfAohy4Ms7xV5AaCqXw": "قام الصديق بتثبيت PikPak وانتهى من تسجيل الدخول",
      "z9IPdSzpJxOmt6g1EbGrm": "أدخل الأصدقاء رمز الدعوة",
      "z1Slg9a1Hvn7lrZ0ChLNg": "رمز دعوتي",
      "oHlo9H83unnDaAnWFp0B": "نسخ كود الدعوة",
      "jwniqgQ0ndGisnxEzft6s": "أوصي بتطبيق PikPak الرائع ، بعد التثبيت ، أدخل رمز الدعوة {0} واحصل على اشتراك مجاني لمدة 5 أيام ، قم بالتنزيل من هنا: {1}",
      "if1saWFaNUj7WZl8F4Ya": "عرض لفترة محدودة في عيد الحب",
      "lhPoztU7Mogu2jDhlmVz": "اشتراك مجاني لمدة 7 أيام للمستخدمين الجدد",
      "gbIzzbslRXfW0bZbpp71L": "خصم 25٪ على السعر المتميز - أقل سعر على الإطلاق",
      "mTNofZNzPUeAwncWpNPvL": "ادعُ للاستمتاع بالمزايا الثلاثية",
      "bu1O6BrObADowmjocv3bC": "يمكن للأصدقاء الحصول على قسط مجاني لمدة 5 أيام",
      "yMUxffrxJ6SXtLCoZh73S": "إصدار التطبيق الحالي منخفض جدًا",
      "kG5XWhDkOsLkBiAcW7svK": "انتقل إلى الموقع الرسمي للترقية إلى أحدث إصدار",
      "bi3D6kOp71Rbp0sMn_9U9": "انسخ الرابط",
      "more-method-to-invite": "ادعُ الأصدقاء بعدة طرق",
      "g4HnOjI94Mv8zQy8IqrGe": "تثبيت رابط الدعوات",
      "3Je8aKdiynlFeQa_0ghh6": "ادعُ الآن",
      "bFm47Z76KfqeSznR1x1Nm": "دعوة من خلال مشاركة الملفات",
      "wLl4vetKaCvzRdGlfGqv": "لمشاركة الملفات",
      "stDuM9Vxbjk33cf8KcGq0": "دعوة مع رمز الدعوة",
      "4kS5Bpf_2Q5aixlBzqn0Z": "رمز دعوتي",
      "wXUwHoCjXqksk75d2XPp": "نسخ كود الدعوة",
      "u10KVi1oM1ofcDg6z5iEg": "شارك مع الأصدقاء",
      "49ATeBXcmc21mJrg1GaFq": "إنشاء ارتباط ومشاركته وإرساله إلى الأصدقاء.",
      "h6M5kFr2SxJlBy0usxCzT": "يقوم الأصدقاء بحفظ الملفات وتثبيت PikPak",
      "yjz7Yev6UufY8tyVxTj1E": "إصدار التطبيق منخفض جدًا. يرجى الانتقال إلى الموقع الرسمي للترقية إلى أحدث إصدار. تم نسخ عنوان الوصول إلى الحافظة.",
      "jlXz6iCidsDMf5wK6PnJ": "إصدار التطبيق منخفض جدًا. يرجى الانتقال إلى \\ \"الإعدادات - حول PikPak \\\" للترقية إلى أحدث إصدار",
      "k_6MjGjxI9BrmYaJMeiYa": "نسخ إلى الحافظة",
      "rules14": "تعتبر الدعوة صالحة عند مشاركة ملف ، ويقوم مستخدم جديد بإنشاء حساب وحفظ الملف الذي شاركته. ستحصل بعد ذلك على مكافأة الشراء. إذا قام مستخدم جديد بتثبيت تطبيق PikPak بأي طريقة في غضون 12 ساعة بعد إنشاء الحساب ، فستتلقى Invite-To-Install Reward.",
      "appleDisclaimer": "هذا الحدث ليس تابعًا لشركة Apple Inc.",
      "iosInviteTips": "بالنسبة لأصدقائك الذين يستخدمون iPhone أو iPad ، سيحتاجون إلى إدخال رمز دعوة بعد تثبيت PikPak.",
    }
  },
  'ms': {
    invitation: {
      "awardCount": "Ganjaran Premium",
      "awardRecord": "Rekod Jemputan",
      "awardTime": "Masa",
      "awardType": "Peristiwa",
      "awardVipCount": "Dapatkan masa Premium",
      "collectMorePlatform": "Muat turun awan semua-dalam-satu",
      "commentName1": "52***ku",
      "commentName2": "李**了",
      "commentName3": "IKi***u",
      "commentName4": "m***te",
      "commentName5": "fh**ro",
      "commentName6": "旅***行",
      "commentName7": "周**明",
      "commentText1": "Pengalamannya sangat baik, kadar kejayaan muat turun luar talian sangat tinggi dan main balik dalam talian lancar.",
      "commentText2": "PikPak ialah perisian muat turun magnetik terbaik yang pernah digunakan, selain itu ia juga mudah untuk dipindahkan dan fail selamat.",
      "commentText3": "Perisian ini hebat, kelajuan muat turun luar talian sangat pantas, kapasiti cakera awan yang besar juga sangat bagus! Kerja bagus devs! .",
      "commentText4": "Antara muka yang ringkas, fungsi berkuasa dan aplikasi boleh memuat turun video Telegram dengan cepat.",
      "commentText5": "Dapat disyorkan oleh rakan-rakan. Kelajuan muat turun luar talian sangat pantas dan kuncinya ialah sumber tidak akan gagal, jauh lebih kuat daripada ****. Banyak video HD boleh disimpan serta-merta, dimainkan terus, semua jenis resolusi sangat lancar. Terdapat tempoh percubaan percuma, dan harga langganan semasa dalam apl adalah dalam diskaun promosi, ruang storan 10T sangat menjimatkan kos. Jadi saya sudah membawa rancangan tahunan.",
      "commentText6": "Saya menggunakan APP ini dengan sikap mencubanya, dan hasilnya tidak dijangka. Akhirnya menemui aplikasi yang cemerlang dalam muat turun torrent, juga aplikasinya menyediakan muat turun kelajuan penuh, main semula juga sangat lancar.",
      "commentText7": "Perisian ini terlalu berkuasa. Ia menyelesaikan kegusaran mengumpul video yang dikehendaki, dan mempunyai ruang tanpa had kapasiti yang besar. Pasti mendapat sokongan saya.",
      "communityComment": "Dinamik Sosial",
      "continueInvite": "Bonus tambahan untuk setiap jemputan",
      "copyThat": "Pautan jemputan telah disalin dan ditampal!",
      "day": "Hari",
      "earnFromYourInvited": "Setiap pembelian rakan jemputan anda akan memberi anda ganjaran",
      "firstInviteAward": "Akan ada bonus untuk menjemput rakan buat kali pertama",
      "fiveDay": "5 hari",
      "freeVip": "Premium Percuma",
      "friend": "kawan",
      "friendAward": "Rakan mendapat 5 hari lagi Premium percuma",
      "friendBuyVip": "Rakan membeli Premium",
      "friendInstall": "Rakan anda memasang PikPak dan log masuk",
      "group": "Kumpulan",
      "hadInvited": "Kawan-kawan dijemput",
      "hotToInvite": "Bagaimana untuk menjemput rakan?",
      "introduceApp": "Syorkan apl yang hebat kepada anda",
      "invitationPageTitle": "Program ganjaran rujukan",
      "inviteAward": "Rujukan untuk Premium Percuma",
      "inviteAwardTitle": "Jemputan Berjaya",
      "invitedPageTitle": "Rakan-rakan menjemput anda untuk menggunakan PikPak",
      "invitedUser": "Pengguna Jemputan",
      "inviteNow": "Jemput Sekarang",
      "inviteSuccessful": "Berjaya menjemput",
      "lookVipDataInfo": "Lihat Sejarah Jemputan",
      "myInvitation": "Jemputan Saya",
      "ok": "Okey",
      "people": "Orang ramai",
      "times": "Masa",
      "quickInstall": "Pasang sekarang!",
      "quickInstallIos": "Selepas pemasangan, masukkan kod pertukaran <span style='color: #306EFF; font-weight: 600;'>{0}</span> pada halaman Premium untuk menerimanya",
      "rules1": "Setiap kali pengguna jemputan anda berjaya membeli PikPak Premium dalam tempoh satu tahun selepas pendaftaran, anda akan mendapat 30% daripada bilangan hari yang anda beli. Contohnya, jika pengguna yang anda jemput membeli Premium satu bulan empat kali dalam tempoh satu tahun, anda akan mendapat 36 hari Premium, dan jika pengguna yang anda jemput membeli Premium tahunan, anda akan mendapat 110 hari Premium.",
      "rules10": "Acara ini hanya tersedia untuk pengguna PikPak, dan tafsiran akhir peraturan akan dibuat oleh pegawai PikPak.",
      "rules2": "Anda akan mendapat 5 hari Premium jika anda menjemput pengguna baharu yang pertama untuk memasang Apl, dan anda akan mendapat 2 hari lagi Premium oleh setiap pengguna seterusnya yang anda jemput dengan jayanya.",
      "rules3": "Pengguna Apl yang anda jemput akan mendapat 5 hari tambahan Premium selepas pendaftaran.",
      "rules4": "Pengguna baharu yang memuat turun Apl PikPak melalui pautan eksklusif anda dan berjaya melog masuk dalam Apl akan dianggap sebagai jemputan yang sah. Selain menghantar pautan terus, anda juga boleh memuat turun pakej APK eksklusif melalui pautan anda dan menghantarnya kepada pengguna baharu untuk dipasang.",
      "rules13": "Pengguna baharu boleh memasang Apl PikPak melalui sebarang kaedah dengan menggunakan kod jemputan. Selepas log masuk, pengguna baharu perlu menyerahkan kod jemputan eksklusif daripada pejemput di halaman ini (atau dalam kotak Tebus Premium), agar pengguna baharu akan dianggap sebagai jemputan yang sah selepas pengesahan berjaya. Nota: Pengguna baharu harus memasukkan kod jemputan dan mengesahkan dalam masa 12 jam selepas memasang. Melebihi had masa akan mengakibatkan kegagalan pengesahan.",
      "rules5": "Dalam tempoh satu tahun (365 hari) dari tarikh pendaftaran, pengguna baharu yang membeli premium melalui langganan premium rasmi, pembelian dalam apl, kod premium atau pengalaman kod premium dianggap sebagai premium yang dibeli dengan jayanya, tetapi mendapat premium percuma melalui percuma. kod penebusan, hadiah masa premium, dsb. tidak dianggap sebagai premium yang dibeli.",
      "rules6": "Bonus untuk jemputan untuk memasang Apl adalah terhad kepada 50 jemputan pertama yang sah, selepas itu jemputan tidak lagi akan mendapat bonus ini, tetapi tiada had kepada bilangan orang dan masa bonus pembelian premium, dan ia tidak dihadkan.",
      "rules7": "Selepas jemputan atau pembelian berjaya, sistem akan secara automatik memberikan hari Premium kepada jemputan. Terdapat kelewatan dalam keadaan khas.",
      "rules8": "Peranti atau akaun yang sama hanya akan dikira sebagai 1 pengguna baharu yang dijemput. Pegawai PikPak akan menapis data yang tidak normal.\",",
      "rules9": "Sekiranya berlaku pelanggaran peraturan (termasuk, tetapi tidak terhad kepada, spam dan pembotolan berniat jahat), pegawai PikPak mempunyai hak untuk membatalkan kelayakan acara peserta dan membatalkan semua ganjaran. Jika anda mempunyai sebarang bantahan, anda boleh membuat rayuan melalui kumpulan Telegram rasmi.",
      "rules11": "Bagi ahli langganan, setiap potongan yang berjaya selepas tamat tempoh percubaan dianggap sebagai pengaktifan yang sah.",
      "rules12": "Jika pengguna yang dijemput dibayar balik selepas pembayaran berjaya, hari bonus yang sepadan boleh ditarik balik.",
      "rulesDescribe": "Peraturan",
      "rulesTitle1": "Peraturan Ganjaran",
      "rulesTitle2": "Peraturan terperinci penghakiman",
      "rulesTitle3": "Penjelasan Lain",
      "secondSaveAndPressPlay": "Simpanan segera, main balik 1 klik",
      "shareToFriend": "Kongsi pautan dengan rakan anda",
      "supportTenT": "Kapasiti storan sehingga 10T",
      "takeYouTenDayVip": "Kini memberi anda 5 hari Premium secara percuma",
      "techYouShare": "Anda kini boleh menampal pautan kongsi dengan tiga kali ganda kadar kejayaan.",
      "tenAndInfinite": "Storan awan 10TB + pemindahan awan tanpa had",
      "tenDayVip": "Premium 5 hari",
      "vip": "Premium",
      "vipDay": "Hari Premium",
      "vipDays": "Hari-hari Premium",
      "vipDivided": "Beli Premium",
      "yourFriend": "Kawan awak",
      "noSuccessInvited": "Tiada sejarah jemputan buat masa ini",
      "ikV4HbwO1qOt4nPo0QiCa": "Pautan untuk menjemput rakan",
      "dvwW3MMztFrPm1RhXOw": "Kaedah lain - Jemput rakan mengikut Kod Jemputan",
      "sgZ1opIxw0KhKoJsSsXx1": "Masukkan Kod Jemputan",
      "u_3DrnZw0z7CQo9xT7WDo": "Kongsi dengan kawan-kawan",
      "8xCu7TNslf2gBjIouq3az": "Syorkan apl PikPak yang hebat, pasang sekarang dan dapatkan Premium percuma selama 5 hari: {0}",
      "ny_8N0IxY2SvAk66kOawb": "Masukkan Kod Jemputan untuk menebus Premium",
      "ryRwlaLw0D2JxMpecnw0h": "Masukkan kod daripada jemputan",
      "now08ek267KShO7vBUpOv": "Tebus sekarang",
      "sPh3hjWiKhDoScsF8Krp": "Tahniah atas {0} hari Premium anda!",
      "8XAhTbae4We_4dzOt75nZ": "Kod jemputan disahkan berjaya",
      "aWlkrn8DckQmySqf6nNux": "okey",
      "zm6rO5J8c4pR0qR0FoIyN": "Hubungan jemputan diwujudkan",
      "hB7uwO3Acv3A6kCdWigzJ": "Kod Jemputan terikat pada masa ini: {0}",
      "3AJqq9tNfua7ObBOgoKt": "Penebusan gagal, sila cuba lagi kemudian",
      "jueAOc8UkZu9_3C4a1uL": "Jemput rakan mengikut Kod Jemputan",
      "78GfAohy4Ms7xV5AaCqXw": "Rakan memasang PikPak dan selesai log masuk",
      "z9IPdSzpJxOmt6g1EbGrm": "Rakan masukkan Kod Jemputan",
      "z1Slg9a1Hvn7lrZ0ChLNg": "Kod Jemputan Saya",
      "oHlo9H83unnDaAnWFp0B": "Salin Kod Jemputan",
      "jwniqgQ0ndGisnxEzft6s": "Syorkan apl hebat PikPak, selepas pemasangan masukkan Kod Jemputan {0} dan dapatkan Premium percuma selama 5 hari, muat turun di sini: {1}",
      "if1saWFaNUj7WZl8F4Ya": "Tawaran masa terhad pada Hari Kekasih",
      "lhPoztU7Mogu2jDhlmVz": "7 hari Premium percuma untuk pengguna baharu",
      "gbIzzbslRXfW0bZbpp71L": "Diskaun 25% pada harga Premium - paling rendah pernah",
      "mTNofZNzPUeAwncWpNPvL": "Jemput untuk menikmati faedah berganda",
      "bu1O6BrObADowmjocv3bC": "Rakan boleh mendapat Premium percuma selama 5 hari",
      "yMUxffrxJ6SXtLCoZh73S": "Versi apl semasa terlalu rendah",
      "kG5XWhDkOsLkBiAcW7svK": "Pergi ke laman web rasmi untuk menaik taraf kepada versi terkini",
      "bi3D6kOp71Rbp0sMn_9U9": "Salin pautan",
      "more-method-to-invite": "Jemput rakan dalam pelbagai cara",
      "g4HnOjI94Mv8zQy8IqrGe": "Pasang Jemputan Pautan",
      "3Je8aKdiynlFeQa_0ghh6": "Jemput Sekarang",
      "bFm47Z76KfqeSznR1x1Nm": "Jemput dengan berkongsi fail",
      "wLl4vetKaCvzRdGlfGqv": "Untuk berkongsi fail",
      "stDuM9Vxbjk33cf8KcGq0": "Jemput dengan Kod Jemputan",
      "4kS5Bpf_2Q5aixlBzqn0Z": "Kod Jemputan Saya",
      "wXUwHoCjXqksk75d2XPp": "Salin Kod Jemputan",
      "u10KVi1oM1ofcDg6z5iEg": "Kongsikan kepada rakan-rakan",
      "49ATeBXcmc21mJrg1GaFq": "Buat & Kongsi & Hantar pautan kepada rakan.",
      "h6M5kFr2SxJlBy0usxCzT": "Rakan simpan fail dan pasang PikPak",
      "yjz7Yev6UufY8tyVxTj1E": "Versi apl terlalu rendah. Sila pergi ke laman web rasmi untuk menaik taraf kepada versi terkini. Alamat akses telah disalin ke papan keratan.",
      "jlXz6iCidsDMf5wK6PnJ": "Versi apl terlalu rendah. Sila pergi ke \\\"Tetapan - Perihal PikPak \\\" untuk menaik taraf kepada versi terkini",
      "k_6MjGjxI9BrmYaJMeiYa": "Disalin ke papan keratan",
      "rules14": "Jemputan dianggap sah apabila fail dikongsi dan pengguna baharu membuat akaun dan menyimpan fail yang telah anda kongsi. Anda kemudiannya akan menerima Bonus Belian. Jika pengguna baharu memasang Apl PikPak dalam apa jua cara dalam masa 12 jam selepas membuat akaun, anda akan menerima Ganjaran Jemputan Untuk Memasang.",
      "appleDisclaimer": "Aktiviti ini tiada kaitan dengan Apple inc.",
      "iosInviteTips": "Bagi rakan anda yang menggunakan iPhone atau iPad, mereka perlu memasukkan kod jemputan selepas memasang PikPak.",
    }
  },
  'ko': {
    invitation: {
      "after": "회원 인센티브",
      "awardRecord": "초대 기록",
      "awardTime": "시간",
      "awardType": "이벤트",
      "awardVipCount": "획득 프리미엄 일수",
      "collectMorePlatform": "클라우드 다운로드",
      "commentName1": "52***ku",
      "commentName2": "박**혜",
      "commentName3": "IKi***u",
      "commentName4": "m***te",
      "commentName5": "fh**ro",
      "commentName6": "문**인",
      "commentName7": "윤**열",
      "commentText1": "환경이 매우 좋습니다. 오프라인 다운로드 성공률도 매우 뛰어나고 온라인 재생이 원활합니다.",
      "commentText2": "PikPak은 최고의 마그넷 감지 다운로드로, 저장하기에 매우 편리합니다.",
      "commentText3": "소프트웨어가 매우 훌륭합니다. 오프라인다운로드 속도도 매우 빠르며, 클라우드 욜량도 매우 크고, 아주 나이스합니다. 개발자분들 화이팅!",
      "commentText4": "심플한 인터페이스와 강력한 텔레그램 비디오 플래시 및 메모리를 자랑합니다.",
      "commentText5": "자원 그룹의 거물들이 추천하는 오프라인 다운로드입니다. 속도가 매우 빠르며, 핵심은 리소스가 *** 제품에 비해 실패하지 않는다는 것입니다. 무료 체험 기간도 있고, 현재 앱 내에서의 구독 가격은 할인행사 중이라서, 10TB 저장공간을 가성비 있게 구독 중입니다.",
      "commentText6": "그냥 한 번 써보자는 마음으로 사용했는데, 예상 밖이었다. 마그넷으로 자동 다운을 할 수 있고, 그 속도도 풀스피드라서 재생하는 데에도 거침없다.",
      "commentText7": "너무 좋습니다. 이 앱은 너무 강력해서 동영상을 보관하는 번거로움도 해결햇고, 속도 제한 없는 초대형 용량을 지원합니다.",
      "communityComment": "커뮤니티",
      "continueInvite": "친구를 초대하여 인센티브를 받으세요.",
      "copyThat": "초대링크가 복사되었습니다. 바로 붙여넣으세요!",
      "day": "일",
      "earnFromYourInvited": "초대한 친구가 결제시마다 인센티브를 드립니다.",
      "firstInviteAward": "처음으로 친구를 초대할 시 추가 인센티브를 드립니다.",
      "fiveDay": "5일",
      "freeVip": "무료 프리미엄",
      "friend": "친구",
      "friendAward": "친구는 5일의 무료 프리미엄을 더 얻을 수 있습니다.",
      "friendBuyVip": "친구가 프리미엄 구매",
      "friendInstall": "친구가 PikPak 앱을 설치후, 등록을 완료",
      "group": "그룹",
      "hadInvited": "초대한 친구",
      "hotToInvite": "어떻게 친구를 초대하나요?",
      "introduceApp": "당신께 멋진 앱을 추천할게요:",
      "invitationPageTitle": "초대 이벤트 활동",
      "inviteAward": "친구를 초대하여 무제한 프리미엄을 받으세요.",
      "inviteAwardTitle": "초대 성공",
      "invitedPageTitle": "친구가 당신에게 PikPak을 추천했습니다.",
      "invitedUser": "초대받은 친구",
      "inviteNow": "바로 추가",
      "inviteSuccessful": "초대 성공",
      "lookVipDataInfo": "초대기록 보기",
      "myInvitation": "나의 초대",
      "ok": "확인",
      "people": "인",
      "times": "회",
      "quickInstall": "바로 설치하세요!",
      "rules1": "당신이 초대한 친구가 픽팍에 가입 후, 1년 이내에 결제시, 당신은 친구가 구매한 프리미엄의 30%의 구매일수를 받을 수 있습니다. 예시: 당신이 초대한 이용자가 1년 이내에 4번의 월 프리미엄 구독시, 당신은 36일의 프리미엄 일수를 받을 수 있고, 친구가 연구독시 110일의 프리미엄을 받을 수 있습니다.",
      "rules10": "해당 이벤트는 오직 픽팍 이용자들에게만 적용되며, 최종 규칙의 해석은 픽팍의 공식 상부 그룹이 갖습니다.",
      "rules2": "첫 번째로 친구를 초대하여 앱을 깔았을 시, 당신은 5일의 무료 프리미엄을 얻을 수 있으며, 그 이후로 초대 1명당 2일의 무료 프리미엄을 얻게 됩니다.",
      "rules3": "초대 당한 친구도 앱을 깔아 등록시, 5일의 프리미엄을 얻을 수 있습니다.",
      "rules4": "신규 사용자가 귀하의 전속 링크를 통해 픽팩 앱을 다운로드하여 앱 내에서 성공적으로 로그인하면 초대가 성공했다고 간주됩니다. 링크를 직접 보내는 것 외에도 링크를 통해 전용 APK 패키지를 다운로드하여 새 사용자에게 설치할 수 있습니다.",
      "rules13": "초대코드 사용 시 신규 사용자는 PikPak App을 임의의 방법으로 설치하여 로그인한 후 이 페이지(또는 쿠폰코드 입력 페이지)에 귀하의 전용 초대코드를 입력하여 인증에 성공하면 초대에 성공했다고 간주됩니다.주의: 신규 사용자는 픽팩 앱 설치 후 12시간 이내에 초대 코드를 입력해야 하며, 인증이 기한 초과 시 인증 실패가 발생할 수 있습니다.",
      "rules5": "신규 가입자는 가입일로부터 1년(365일) 이내에 공식 회원가입, 앱 내 구매, 멤버십 코드, 체험 멤버십 코드를 통해 가입한 회원은 구매 성공회원으로 간주되지만, 무료 교환 코드, 멤버십 증정 기간 등을 통해 무료 멤버십을 받은 회원은 구매회원으로 간주되지 않습니다.",
      "rules6": "앱 설치 초대 보상은 선착순 50명까지 유효 초대가 초과되면 초청인은 더 이상 보상을 받을 수 없습니다. 하지만, 결제에 대한 인센티브는 인원수 및 횟수 제한에 상한선이 없습니다.",
      "rules7": "초대 또는 구매 완료 후, 30분 이내에 시스템 내에서 프리미엄을 발급하며, 초대인의 특수한 경우 연장될 수 있습니다.",
      "rules8": "동일 기기 또는 동일 계정은 초대된 신규 사용자가 계산될시, 픽팩 공식에서 이상 데이터를 필터링한다.",
      "rules9": "위반 행위(악의적인 스퀴즈, 사용자 행동 시뮬레이션 등을 포함하지만 이에 국한되지 않음)에 대해 PikPak 공식은 참여자의 활동 자격을 취소하고 보상 회수 등의 조치를 취할 권리가 있습니다. 위반에 이의가 있을 경우 공식 텔레그램방을 통해 제소할 수 있습니다.",
      "rules11": "구독계 회원에 대한 프리미엄 기간 종료 후 매번 성공적으로 수수료를 공제하는 것은 유효한 개통으로 간주됩니다.",
      "rules12": "초대 가입자가 결제 후 환불받으면, 해당 초대일수가 회수될 수 있습니다.",
      "rulesDescribe": "규칙 설명",
      "rulesTitle1": "1. 인센티브 규칙",
      "rulesTitle2": "2. 판단 세부 내용",
      "rulesTitle3": "3. 기타 설명",
      "secondSaveAndPressPlay": "고속저장, 원클릭 재생",
      "shareToFriend": "링크를 친구에게 공유",
      "supportTenT": "10T의 초대형 용량",
      "takeYouTenDayVip": "5일의 프리미엄을 드리겠습니다.",
      "techYouShare": "다양한 장소에 붙여넣어 성공률을 300%로 올리세요!",
      "tenAndInfinite": "10T 클라우드 공간 + 무제한 전송 횟수",
      "tenDayVip": "5일 프리미엄",
      "vip": "프리미엄",
      "vipDay": "프리미엄 일수",
      "vipDays": "의 프리미엄 일수",
      "vipDivided": "프라미엄 구매",
      "yourFriend": "나의 친구",
      "noSuccessInvited": "현재 친구 초대 기록 없음",
      "ikV4HbwO1qOt4nPo0QiCa": "친구 초대 링크",
      "dvwW3MMztFrPm1RhXOw": "다른 방법 - 추천인 코드로 친구 초대 >",
      "sgZ1opIxw0KhKoJsSsXx1": "추천인 코드 입력",
      "u_3DrnZw0z7CQo9xT7WDo": "친구에게 공유",
      "8xCu7TNslf2gBjIouq3az": "멋진 앱을 추천합니다. 지금 PikPak을 설치하여 7일 무료 프리미엄%{0} 획득하세요.",
      "ny_8N0IxY2SvAk66kOawb": "추천인 코드를 입력하여 프리미엄을 받으세요.",
      "ryRwlaLw0D2JxMpecnw0h": "추천인 코드 입력",
      "now08ek267KShO7vBUpOv": "바로 등록",
      "sPh3hjWiKhDoScsF8Krp": "{0}일의 프리미엄이 성공적으로 등록되었습니다.",
      "8XAhTbae4We_4dzOt75nZ": "추천인 코드 확인 성공",
      "aWlkrn8DckQmySqf6nNux": "확인",
      "zm6rO5J8c4pR0qR0FoIyN": "초대 관계가 설정됨",
      "hB7uwO3Acv3A6kCdWigzJ": "현재 바인딩된 초대 코드% {0}",
      "3AJqq9tNfua7ObBOgoKt": "등록에 실패했습니다. 나중에 다시 시도하십시오.",
      "jueAOc8UkZu9_3C4a1uL": "추천인 코드 친구 초대",
      "78GfAohy4Ms7xV5AaCqXw": "친구가 PikPak 앱을 설치후, 등록을 완료",
      "z9IPdSzpJxOmt6g1EbGrm": "친구가 추천인 코드 입력",
      "z1Slg9a1Hvn7lrZ0ChLNg": "내 추천인 코드",
      "oHlo9H83unnDaAnWFp0B": "추천인 코드 복사",
      "jwniqgQ0ndGisnxEzft6s": "멋진 앱을 추천합니다. PikPak 설치 후 초대코드 {0}을(를) 입력하면 5일간 무료 회원 다운로드 주소도 얻을 수 있습니다: {1}",
      "if1saWFaNUj7WZl8F4Ya": "밸런타인데이 타임세일",
      "lhPoztU7Mogu2jDhlmVz": "신규 가입자는 7일 무료 회원 이용",
      "gbIzzbslRXfW0bZbpp71L": "구매 멤버십 사상 최저 7.5% 할인",
      "mTNofZNzPUeAwncWpNPvL": "초대하여 3중 복지를 누리세요",
      "bu1O6BrObADowmjocv3bC": "초대 친구 5일 무료 프리미엄 획득",
      "yMUxffrxJ6SXtLCoZh73S": "현재 App 버전이 낮습니다.",
      "kG5XWhDkOsLkBiAcW7svK": "공식 웹사이트로 이동하여 최신 버전으로 업그레이드하기",
      "bi3D6kOp71Rbp0sMn_9U9": "링크 복사",
      "more-method-to-invite": "여러 가지 방법으로 친구를 초대",
      "g4HnOjI94Mv8zQy8IqrGe": "앱 설치 링크로 초대",
      "3Je8aKdiynlFeQa_0ghh6": "바로 초대",
      "bFm47Z76KfqeSznR1x1Nm": "파일 공유로 초대",
      "wLl4vetKaCvzRdGlfGqv": "파일 공유하기",
      "stDuM9Vxbjk33cf8KcGq0": "추천인 코드로 초대",
      "4kS5Bpf_2Q5aixlBzqn0Z": "내 추천인 코드",
      "wXUwHoCjXqksk75d2XPp": "추천인 코드 복사",
      "u10KVi1oM1ofcDg6z5iEg": "친구에게 공유",
      "49ATeBXcmc21mJrg1GaFq": "파일 링크를 친구들에게 공유",
      "h6M5kFr2SxJlBy0usxCzT": "친구가 파일을 저장하고 PikPak을 설치",
      "yjz7Yev6UufY8tyVxTj1E": "앱 버전이 너무 낮습니다. 홈페이지에서 최신 버전으로 업그레이드하십시오. 접근 주소가 클립보드에 복사되었습니다.",
      "jlXz6iCidsDMf5wK6PnJ": "앱 버전이 낮습니다 \\\"설정-픽팩에 대하여\\\"최신 버전으로 업그레이드하십시오.",
      "k_6MjGjxI9BrmYaJMeiYa": "클립보드에 복사됨",
      "rules14": "공유 파일 초대를 사용할 때 신규 사용자가 계정을 만들고 공유한 파일을 저장하는 것은 유효한 초대로 간주되며 신규 사용자의 구매 포인트를 보상받을 수 있습니다. 신규 사용자는 계정 생성 후 12시간 이내에 임의의 방법으로 픽팩 앱을 설치하면 초대 설치 보상을 받을 수 있습니다.",
      "rules15": "특수 설명: 특히 현재 기술적인 이유로 귀하가 초대한 새 이용자가 IOS 플랫폼에서 앱을 설치할 경우, 귀하와 새 이용자는 초대 설치 보상을 받을 수 없지만, 구매 보상을 받을 수 있습니다."
    }
  }
}
export default message;