const message = {
	'zh-CN': {
		payment: {
			subscribeManager: '订阅管理',
			changePlan: '更改您的方案',
			currentPlan: '当前方案',
			month: '月',
			year: '年',
			day: '日',
			yourPlan: '您的订阅将于',
			continue: '续订',
			cancelScheme: '取消方案',
			notScheme: '当前未订阅',
			payType: '支付方式',
			ensureScheme: '确认取消当前方案',
			serviceUntil: '方案即将被取消, 但您将继续享受会员服务到',
			enter: '确认',
			cancel: '取消',
			billing: '账单',
		}	
	},
	'zh-TW': {
		payment: {
			subscribeManager: '订阅管理',
			changePlan: '更改您的方案',
			currentPlan: '当前方案',
			month: '月',
			year: '年',
			day: '日',
			yourPlan: '您的订阅将于',
			continue: '续订',
			cancelScheme: '取消方案',
			notScheme: '当前未订阅',
			payType: '支付方式',
			ensureScheme: '确认取消当前方案',
			serviceUntil: '方案即将被取消, 但您将继续享受会员服务到',
			enter: '确认',
			cancel: '取消',
			billing: '账单',
		}	
	},
	'en-US': {
		payment: {
			subscribeManager:'Subscription Management',
			changePlan:'Change your plan',
			currentPlan:'Current Plan',
			month:'Month',
			year:'year',
			day: 'day',
			yourPlan:'Your subscription will',
			continue:'Renew',
			cancelScheme:'Cancel Plan',
			notScheme:'Currently not subscribed',
			payType:'Payment method',
			ensureScheme:'Confirm to cancel the current plan',
			serviceUntil:'The plan is about to be cancelled, but you will continue to enjoy the membership service until',	
			enter: 'Confirm',
			cancel: 'Cancel',
			billing: 'billing',
		}	
	},
	'id': {
		payment: {
			subscribeManager:'Subscription Management',
			changePlan:'Change your plan',
			currentPlan:'Current Plan',
			month:'Month',
			year:'year',
			day: 'day',
			yourPlan:'Your subscription will',
			continue:'Renew',
			cancelScheme:'Cancel Plan',
			notScheme:'Currently not subscribed',
			payType:'Payment method',
			ensureScheme:'Confirm to cancel the current plan',
			serviceUntil:'The plan is about to be cancelled, but you will continue to enjoy the membership service until',	
			enter: 'Confirm',
			cancel: 'Cancel',
			billing: 'billing',
		}
	},
}

export default message;