import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueI18n from 'vue-i18n';
import messages from './locale';
import { getUserInfo } from '@/utils/jsBridge';
import { stat } from '@/utils/stat';
import Toast from './components/common/toast-plugin';
import VueClipboard from 'vue-clipboard2';
import 'vant/lib/index.css';
import { Icon, Button, Loading, Overlay, Collapse, CollapseItem, Skeleton } from 'vant';

Vue.use(Icon);
Vue.use(Button);
Vue.use(Toast);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Skeleton);

Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.use(VueClipboard);
Vue.prototype.$stat = stat;

const localeMapping = {
  'zh-CN': 'zh-CN',
  'zh-TW': 'zh-TW',   
  'en': 'en-US',
  'id': 'id',
  'ar': 'ar',
  'ms': 'ms',
  'ms-MY': 'ms',
  'id-ID': 'id',
  'ko': 'ko',
  'pt': 'pt',
  'pt-PT': 'pt',
  'es': 'es',
  'es-ES': 'es',
}
const localeKeys = Object.values(localeMapping);
let locale = 'en-US';
const browserLanguage = navigator.language;
if (localeKeys.includes(browserLanguage)) {
  locale = localeMapping[browserLanguage] || 'en-US';
} else {
  localeKeys.forEach(l => {
    const r = new RegExp(l, 'i');
    if (r.test(browserLanguage)) {
      locale = localeMapping[l];
    }
  });
}

export const i18n = new VueI18n({
  locale,
  messages,
});

export function $t(args) {
  return i18n.tc.call(i18n, args);
}

try {
  getUserInfo(null, data => {
    data = typeof data === 'object' ? data : JSON.parse(data || '{}');
    const language = data.language;
    if (language) {
      i18n.locale = localeMapping[language] || 'en-US';
    }
  });
} catch(err) {
  console.error('[init language] ', err);
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');