const message = {
	'zh-CN': {
		activity: {
			"bjsDoxBxeZtpzWyMc5baN": "加入KakaoTalk 官方群組",
			"haveCdKey": "已有兑换码，兑换领取",
			"noCdKey": "加入 Pikpak官方交流群,即可领取会员兑换码。",
			"noLimit": "无限云传输次数",
			"officialGroup": "加入Telegram官方群组",
			"pageTitle": "会员限免活动",
			"pleaseEnterCdKey": "请输入会员兑换码",
			"rulesDetail": "每个兑换码仅能使用一次，无法重复使用；如有其它疑问，可以咨询群组内的管理员。",
			"rulesTitle": "规则说明：",
			"tenTSpace": "10TB超大空间",
			"tradeButton": "兑换领取",
			"tradeSuccess": "兑换成功",
			"whatsApp": "加入WhatsApp官方群组",
		}
	},
	'zh-TW': {
		activity: {
			"bjsDoxBxeZtpzWyMc5baN": "加入KakaoTalk 官方群組",
			"haveCdKey": "已有兌換碼，兌換領取",
			"noCdKey": "加入Pikpak官方交流群，即可領取會員兌換碼。",
			"noLimit": "無限雲傳輸次數",
			"officialGroup": "加入Telegram官方群組",
			"pageTitle": "會員限免活動",
			"pleaseEnterCdKey": "請輸入會員兌換碼",
			"rulesDetail": "每個兌換碼僅能使用一次，無法重複使用；如有其它疑問，可以諮詢群組內的管理員。",
			"rulesTitle": "規則說明：",
			"tenTSpace": "10TB超大空間",
			"tradeButton": "兌換領取",
			"tradeSuccess": "兌換成功",
			"whatsApp": "加入WhatsApp官方群組",
		}
	},
	'en-US': {
		activity: {
			"bjsDoxBxeZtpzWyMc5baN": "Join KakaoTalk official group",
			"haveCdKey": "I already have a gift code",
			"noCdKey": "Join the Pikpak official group, you can get the Premium gift code",
			"noLimit": "Unlimited times of cloud transfers",
			"officialGroup": "Join Telegram official group",
			"pageTitle": "Free Premium Activities",
			"pleaseEnterCdKey": "Please enter the code",
			"rulesDetail": "Each code can only be redeemed once;  if you have any questions, please ask the admin in the group.",
			"rulesTitle": "Rule description:",
			"tenTSpace": "10TB extra storage",
			"tradeButton": "Redeem",
			"tradeSuccess": "Redeem successfully",
			"whatsApp": "Join WhatsApp official group",
		}
	},
	'id': {
		activity: {
			"bjsDoxBxeZtpzWyMc5baN": "Bergabung dengan grup resmi KakaoTalk",
			"haveCdKey": "Saya sudah memiliki kode hadiah",
			"noCdKey": "Bergabunglah dengan grup resmi Pikpak, Anda bisa mendapatkan kode hadiah Premium",
			"noLimit": "Transfer cloud tanpa batas",
			"officialGroup": "Bergabunglah dengan grup resmi Telegram",
			"pageTitle": "Aktivitas Premium Gratis",
			"pleaseEnterCdKey": "Silahkan masukkan kodenya",
			"rulesDetail": "Setiap kode hanya dapat ditukarkan satu kali; jika ada pertanyaan silahkan tanyakan pada admin di grup.",
			"rulesTitle": "Deskripsi aturan:",
			"tenTSpace": "Kapasitas ruang besar 10TB",
			"tradeButton": "Menebus",
			"tradeSuccess": "Berhasil menebus",
			"whatsApp": "Bergabunglah dengan grup resmi WhatsApp",
		}
	},
	'ms': {
		activity: {
			"bjsDoxBxeZtpzWyMc5baN": "Sertai kumpulan rasmi KakaoTalk",
			"haveCdKey": "Saya sudah mempunyai kod hadiah",
			"noCdKey": "Sertai kumpulan rasmi Pikpak, anda boleh mendapatkan kod hadiah Premium",
			"noLimit": "Transfer cloud tanpa batas",
			"officialGroup": "Sertai kumpulan rasmi Telegram",
			"pageTitle": "Aktiviti Premium Percuma",
			"pleaseEnterCdKey": "Sila masukkan kod",
			"rulesDetail": "Setiap kod hanya boleh ditebus sekali; jika ada sebarang pertanyaan sila tanya admin dalam group.",
			"rulesTitle": "Perihalan peraturan:",
			"tenTSpace": "10TB storan besar",
			"tradeButton": "Tebus",
			"tradeSuccess": "Tebus berjaya",
			"whatsApp": "Sertai kumpulan rasmi WhatsApp",
		}
	},
	'ko': {
		activity: {
			"bjsDoxBxeZtpzWyMc5baN": "카카오톡 공식 오픈채팅방에 참여",
			"haveCdKey": "이미 기프트 코드가 있습니다.",
			"noCdKey": "PikPak 공식 채팅방에 참여하여, 프리미엄 쿠폰 코드를 받으세요.",
			"noLimit": "무제한 클라우드 전송",
			"officialGroup": "텔레그램 공식 채팅방에 참여",
			"pageTitle": "프리미엄 쿠폰 이벤트",
			"pleaseEnterCdKey": "프리미엄 쿠폰 코드를 입력하세요",
			"rulesDetail": "모든 프리미엄 쿠폰 코드는 최소 1번만 등록할 수 있으며, 중복 사용이 불가능합니다. 기타 문의가 있으시면, 그룹 내의 관리자에게 문의해주세요.",
			"rulesTitle": "규칙 설명:",
			"tenTSpace": "10TB 대용량 스토리지",
			"tradeButton": "등록",
			"tradeSuccess": "코드 등록 완료",
			"whatsApp": "왓츠앱 공식 채팅방에 참여",
		}
	}
}

export default message;