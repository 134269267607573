const message = {
	'zh-CN': {
		compensation: {
			title: '尊敬的用户',
			pageTitle: '支付问题说明和补偿',
			section1: '支付宝订阅无法付款的问题正在紧急处理中，目前支付宝订阅购买会员的方式已暂时关闭。',
			section2: '我们对此深表歉意，后续一定会努力改善，提供更好的体验。',
			section3: '为了补偿您通过支付宝订阅购买会员遇到的不便，我们决定为您提供专属购买特惠：',
			section4: '一次性首月会员特惠价：¥4.99；',
			section5: '一次性首年会员特惠价：¥99。',
			section6: '请取消当前订阅后领取一次性低价优惠。',
			section7: '该特惠有效期截止：2022年3月1日',
			button: '立即领取',
		}	
	},
	'en-US': {
		compensation: {
			title: 'Dear users',
			pageTitle: 'Abnormal Alipay Statement and Compensation',
			section1: 'The issue that Alipay subscription cannot be paid in PikPak is solving urgently. The method of Alipay to purchase premium has been temporarily closed. ',
			section2: 'We apologize for this issue, and we will work harder to improve and provide a better experience in the future.',
			section3: 'In order to compensate for the inconvenience of your subscription, we have decided to provide an exclusive low price:',
			section4: 'One-month fee: $0.79.',
			section5: 'One-year fee of $15.63.',
			section6: 'Please cancel your current subscription to receive the one-time low price offer.',
			section7: 'This compensation is valid until Mar. 1, 2022.',
			button: 'Get it now',
		}	
	},
}

export default message;