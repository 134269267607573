import store from '../store';
import { getUserInfo } from '@/utils/jsBridge';

export async function stat (category, action, data = {}) {
	if (
		JSON.stringify(store.state.userInfo) === '{}' &&
		/pikpak/i.test(navigator.userAgent)
	) {
		await (() => new Promise((resolve) => {
			getUserInfo(null, data => {
				data = data !== null && typeof data === 'object' ? data : JSON.parse(data || '{}');
				// 数据上报的公共字段
				const userType = data.isLogin ? data.isVip ? 2 : 1 : 0;
				const habu_data = {
					userType,
					network: '',
					deviceid: data.accountDeviceId,
					userid: data.userID || 0,
					versionCode: data.versionCode,
					versionName: data.versionName,
					phoneModel: data.mobileType,
					country: '',
					language_app: data.language || navigator.language,
				}
				store.commit('setUserInfo', habu_data);
				addExtData(habu_data);
				resolve();
			});
		}))();
	}
  const reportDatas = {
    type: 'event',
    category: category || 'page_event',
    action,
    extdata: {
			...store.state.userInfo,
			...data
		}
  };
  window.xla.push(reportDatas);
}

export function addExtData (data) {
  window.xla.push({
    type: 'globalExtData',
    data
  })
}