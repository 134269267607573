import helpText from './help';
import middleware from './middleware';
import activity from './activity';
import payment from './payment';
import invitation from './invitation';
import report from './report';
import compensation from './compensation';
import ping from './ping';
import notices from './notices';

const messageList = [
	helpText,
	middleware,
	activity,
	payment,
	invitation,
	report,
	compensation,
	ping,
	notices,
];

const languageTypeList = [
	'zh-CN',
	'zh-TW',
	'en-US',
	'id',
	'ar',
	'ms',
	'ko',
	'pt',
	'es',
];
const messages = messageList.reduce((c, n) => {
	languageTypeList.forEach(type => c[type] = {...c[type], ...n[type]});
	return c;
}, {
	'zh-CN': {},
	'zh-TW': {},
	'en-US': {},
	'id': {},
	'ar': {},
	'ms': {},
	'ko': {},
	'pt': {},
	'es': {},
});

export default messages;