import V from 'vue';
import Toast from './toast.vue';

let count = 0;
function reduce() {
	count --;
}
const ToastConstructor = V.extend(Toast);
class ToastFactory {
		main(message) {
		if (count > 0) {
			return;
		}
		count++;
		const instance = new ToastConstructor({
			data: { message, count },
			methods: { reduce }
		}).$mount().$el;
		document.body.appendChild(instance);
	}

	install(Vue) {
		if (ToastFactory.installed) { 
			ToastFactory.installed = true;
			return;
		}
		Vue.prototype.$toast = this.main;
	}
}

export default new ToastFactory(); 