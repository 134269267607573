// pikpak jsbradge api
import { v4 as uuid } from 'uuid';
import { isIOS } from './util';

const _generateUUID = () => uuid().split('-').join('');

// const _isPikPakPlatform = () => /pikpak/i.test(navigator.userAgent);

const _sendMessage = (fnName, params, callback) => {
	// TODO: ios未提供带标识的userAgent, 所以展示放开, 下个版本加上
	// if (!_isPikPakPlatform()) {
	// 	return;
	// }
	try  {
		if (isIOS) {
			const iosCallback = (error, data) => {
				callback && callback(data?.result || data || error);
			}
			window.PikPakJSBridge?.sendMessage(fnName, params, iosCallback);
			return;
		}
		const callbackFnName = `PIKPAK__${fnName}__${_generateUUID()}`;
		window[callbackFnName] = (data) => {
			callback && callback(data);
			delete window[callbackFnName];
		}
		try {
			params = JSON.stringify(params);
		} catch {
			params = {};
		}
		window.PikPakJSBridge?.sendMessage(fnName, params, callbackFnName);
	} catch(err) {
		console.error(`[sendMessage: ]`, err);
	}
}

/**
 * 获取app登录用户信息, 比metaData接口获取的更多
 * 
 */
export const getUserInfo = (params, callback) => {
	_sendMessage('ppGetUserInfo', params, callback);
}

/**
 *  params.openCamera: 0 相册, 1 相机
 *  params.maxNum: 最大选择图片数, 默认最多为3
 * */ 
export const openAlbumOrCamera = (params, callback) => {
	_sendMessage('ppPickPicture', params, callback);
}

/**
 * 关闭webview
 */
export const closeWebview = (params, callback) => {
	_sendMessage('ppFinishSelf', params, callback);
}

/**
 * 获取用户信息
 */
export const getAppMetaData = (params, callback) => {
	_sendMessage('ppGetAppMetaData', params, callback);
}

/**
 * 复制内容到粘贴板
 */
export const copyToClipboard = (params, callback) => {
	_sendMessage('ppCopyToClipBoard', params, callback);
}

/**
 * 借用客户端公参进行数据上报,
 * hubbleEventId: 事件ID
 * hubbleAttribute: 事件key 
 * hubbleExData: 附带信息
 */
export const reportByPikPak = (params, callback) => {
	_sendMessage('ppReport', params, callback);
}

/**
 * 拉起系统分享框
 * @param { msg: string; } params 
 * @param {*} callback 
 */
export const callSystemShare = (params, callback) => {
	_sendMessage('ppActionSend', params, callback);
}

/**
 * 通知客户端网络检测结果
 * @param { connectivity: 'NONE' | 'NOT_SURE' | 'NORMAL' | 'GOOD' | 'WONDERFUL' }
 * */ 
export const updateConnectivity = (params, callback) => {
	_sendMessage('ppUpdateConnectivity', params, callback);
}

/**
 * 打开网页版, 目前仅(ios实现了此JSBridge API)
 */
export const openPikPakWeb = (params, callback) => {
	_sendMessage('ppOpenPikPakWeb', params, callback);
}

/**
 * webview右上角按钮分享
 */
export const showTopBarShareIcon = (params, callback) => {
	_sendMessage('ppActionShowShare', params, callback);
}

/**
 * 目前仅用于分享裂变页调deepLink前调用, 通知ios做一些准备操作
 */
export const toFileBackCurrentPage = (params, callback) => {
	_sendMessage('ppToFileBackCurrentPage', params, callback);
}