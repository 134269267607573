const message = {
  "zh-CN": {
    notices: {
      "error-description-title": "重要 - 请升级App",
      "error-description-one":
        "非常抱歉，PikPak的支付服务提供商突然出现了问题，目前您无法在Android Apk、Web和桌面程序完成会员购买。",
      "error-description-two":
        "为了减少对您使用的影响，我们已经向您的当前账号免费充入了7天会员。另外您还可以通过官方Telegram群找到其他购买方法。",
      "error-description-three":
        "我们会尽全力尽早解决会员支付问题，不断提高产品服务质量，感谢您的支持。",
      "error-description-button": "前往官方群",
      "team-name": "PikPak团队",
      nqu0Lz8AXfIwhzkjfTx2t:
        "支付等问题已解决，请在 设置 - 关于PikPak - 检查更新 升级。",
      yrIer2T5zwR5eRzeB6Nq: "前往设置",
      "update-guide-title": "App 升级故障排除",
      "update-step": "如果遇到无法升级的情况，请按照以下步骤操作：",
      "update-step1": "1. 前往设置页面，点击“清除缓存”按钮并进行确认。",
      "update-step2": "2. 前往关于 PikPak页面，点击“发现新版本，立即更新” 。",
      "update-step3": "3. 在弹窗中点击“立即更新”按钮，并等待新版本下载完成。",
      "update-other-tip": "如果问题仍然存在：请{0}下载最新版本后安装",
      "goto-official-site": "前往官网",
      "7CwXq8XgzXgCg7PkSOs": "亲爱的 PikPak 用户：",
      dilFtvpn0PyFqax7DwO9z:
        "近期由于支付服务商的突发问题，PikPak 的支付服务受阻，导致用户无法正常购买 PikPak 会员，经过研发工程师两周的努力，现支付问题已经修复。",
      rsPSpOzTZvz5f9mTGoH2:
        "从 2021 年上线至今，PikPak 一直在不断进步，我们一直致力于提供最极致的用户体验，这离不开您的支持与陪伴。所以在未来的一周内，我们将为您提供 PikPak 会员超低价购买资格，您可点击下方的按钮前往购买，希望您能喜欢。",
      cDfNYtp6x1NxmcoVEsH3a: "PikPak 团队",
      xByxGiGmIgRoejWugEmPz: "诚挚道歉",
      epCovLaH2344wDdmyzen7: "领取低价特权",
      nyL2eXpIls3SKbeV5wWc: "官方补偿",
      compensation1:
        "近期由于支付服务商的突发问题，您之前通过 Stripe 购买的 PikPak 会员订阅方案已经被取消，尽管在一些支付渠道（如信用卡、支付宝等）中可能显示订阅状态仍在生效，但后续 PikPak 不会继续向您收取订阅费用。您之前订阅的会员的实际到期时间以 PikPak 内的展示信息为准。",
      compensation2:
        "为此给您带来的不便，我们深感歉意，所以在未来的一周内，我们将为您提供 PikPak 会员超低价购买资格，您可点击下方的按钮前往购买，希望您能喜欢。",
      "4DjJjMxsc2wYsO0X7Zb6":
        "您的 PikPak App 版本已经过时，请尽快升级。新版本 App 解决了支付等问题，并包含多项重要优化。",
      a0bnHthk6JcLafodUtzb: "您可以通过以下任意一种方法升级App：",
      bVIsA7nv5pB0ShXPaCa34:
        "1.（推荐）在 <a href='pikpakapp://mypikpak.com/xpan/setting'>设置</a> - 关于PikPak - 检查更新 升级。",
      zaMfDDd5WwCnLnHskDtK5:
        "2. 通过浏览器 <a href='pikpakapp://mypikpak.com/app/action_view?msg={0}'>直接下载新版本APK</a> 并安装。",
      qk0fcFJv6iSRsc9K75YFj:
        "3. <a href='pikpakapp://mypikpak.com/app/action_view?msg=https%3A%2F%2Fmypikpak.net%2Fmobile-app'>前往官网</a> 安装App。",
      qc6hRy8u13nuzMmoiVjBo:
        "如果您在安装App时遇到问题，请查看 <a href='pikpakapp://mypikpak.com/app/action_view?msg=https%3A%2F%2Fmypikpak.net%2Finstall-apk-troubleshooting'>这个页面</a> 。",
      pn6VR3ZYhAmlNfP5Bpm: "亲爱的 PikPak 用户，您好！",
      nk0yiYpwwTv0jcZNwLDfj: "感谢您一直以来对 PikPak 的支持和信任！",
      trDgv47AxPaO7k1RlC9Z:
        "近期，我们的服务器多次遭到恶意的 DDoS 攻击，导致我们的服务出现了不稳定的情况。您可能遇到了功能不能正常使用，付款后未及时成为会员等问题。这给您带来了极大的不便和困扰，我们深表歉意！",
      wccwJmmoGfq2NJqEj4u5c:
        "我们的技术团队已经尽最大努力处理了这一问题，并在尽可能短的时间内恢复了服务的正常运行。同时，我们也意识到，这样的攻击可能会再次发生，因此我们已经做好了应对未来可能出现的攻击的准备，以保障您的使用体验。",
      tYa3UAvyR3Zfcf7mc9OiY:
        "为了补偿您损失的会员时间，我们专门为会员用户推出了 2 天免费会员兑换码【SorryGift】，只要您当前是会员用户，即可使用该兑换码获得 2 天会员时间补偿。请在 6 天内使用该兑换码，过期失效。",
      rxbeWbrLDsocsSOk0o7I8:
        "再次感谢您对 PikPak 的理解和支持！我们将继续努力为您提供更好的服务！",
      "27TqFsjz0MwfQr6Zb1E": "会员补偿公告",
      z_9s8BdnVms0Rnwdm5Bl: "奖励受限通知",
      lWuWbVc3XlRm7sXtQpXg0:
        "遗憾的通知您，我们在仔细分析后，发现您参与引荐奖励计划而获得的邀请奖励存在一些问题。",
      hqxg7DiHdmhMk2aSUiW1I:
        "您的设备或账号与多个疑似异常、或由某个程序创建的PikPak账号相关联，此类行为违反了PikPak的用户协议和引荐奖励计划规则。因此，您所获得的邀请额外奖励的免费会员已经被回收，后续也无法再获得这种免费会员。",
      thmWBcCZbFh1F6B8gNX:
        "您可以继续获得受邀好友的购买奖励，您购买PikPak会员也不会受到影响。",
      hRwlmNGn0Ew2nel96Z8Rv:
        "请您理解，只有消除无效的邀请奖励，才能让引荐奖励计划可以持续，并继续为用户提供优质的服务。",
      "1XoDe4JhZrS80zSrXCt2":
        "如果您认为这其中有误，请收集有效邀请用户的实证，并在设置页面提交用户反馈来申请恢复。",
      qgZpdxaAgz97QimA3oI9N: "亲爱的 PikPak 粉丝：",
      i3BtgbbvyaHsNuY2NxRok:
        "从2021年上线至今，不知不觉 PikPak 的用户数已经超过 500万，这是一个值得庆祝的时刻。为了感谢各位早期订阅会员对我们的支持，我们决定向您提供粉丝专属的价格优惠，即您可以用一个超级低的价格来购买 PikPak 的会员服务，希望您可以喜欢。",
      xo14l1O8Er62jqav0ryWh:
        "PikPak 一直在不断进步，一直致力于提供最极致的用户体验，感谢在这期间，各位粉丝朋友的支持与陪伴。",
      nd2l5Z72H9yM3G7JiMnMs: "PikPak 团队所有成员",
      "9MrBvYso9NBJtk3xeOt": "送上美好的祝愿",
      iVanoxY7ta8Jwo8cFhOm: "PikPak 粉丝专属优惠",
      oPmxxLqmqyZoxpvThkQr: "无效邀请警告",
      c3TwRy4eaDZDRe1aVq:
        "遗憾的通知您，我们在仔细分析后，发现您参与引荐奖励计划而获得的邀请奖励存在一些问题。",
      "6is28jJXzGIp3do8DRm7I":
        "您的设备或账号与多个疑似异常、或由某个程序创建的PikPak账号相关联，此类行为违反了PikPak的用户协议和引荐奖励计划规则。请您停止这样的无效邀请行为，否则将无法再获得这种免费会员。",
      sHrdY2G_7y5rwCSghdLzm:
        "如果您曾经通过非官方的渠道购买过奖励会员，或者曾经购买或共享账号，也可能导致现在的状况。上述这些行为是非常危险的，可能导致账号或设备被封禁，请立即停止。",
      dNihJ0nwL4SfNs0Eo9Jmz:
        "如果您希望继续参与引荐奖励计划，请您通过计划中所列出的几种方式，邀请真实的朋友，这样您就可以继续获得邀请奖励。",
      vG2m3U7PmnZOaX6bHgt:
        "请您理解，只有消除无效的邀请奖励，才能让引荐奖励计划可以持续，并继续为用户提供优质的服务。",
      aLWys2StUquUwTrNlCk: "PikPak团队",
      keyword1: "已修复账号冲突",
      keyword2:
        "因为早期版本的问题,您的手机号在 PikPak 产生了两个不同账号。为避免出现混乱,我们已经将当前账号设置为您的手机号可登录的唯一账号。",
      keyword3: "了解详情",
      keyword4:
        "由于 PikPak 的某些早期版本没有对手机号格式进行标准化,您用同一个手机号登录 PikPak,可能会注册生成不同的账号。",
      keyword5:
        "这种情况会带来混乱,例如您如果在登录 PikPak 时稍微变更自己手机号的输入格式,可能会发现自己原来账号中的内容没有了,实际上这是因为登录到了新的账号。",
      keyword6:
        "非常抱歉给您带来了困扰,我们已经修复了这个问题,同时也对所有账号进行了检查和处理。现在您的手机号无论以任何格式输入,都只会登录到当前账号。",
      keyword7:
        "您的手机号对应的另一个账号的内容也是完全安全的。如果另一个账号有可用的会员时长,则已经自动追加到当前账号中。",
      keyword8:
        "如果您希望后续登录另一个账号而不是当前账号,请提交意见反馈,我们的用户支持团队可以为您切换账号。",
      keyword9: "如果您认为使用当前账号是合适的,则无需采取任何措施。",
      keyword10: "再次致歉,感谢您的支持和信任。",
      keyword11: "立即反馈",
      txvuGsiXQmwiQcum_9b9j:
        "目前存在一些未经 PikPak 授权的所谓 PikPak 会员商店，在一些电商平台出售几天时长的短期 PikPak 会员或账号。经过我们分析，他们出售的会员是通过程序制造大量无效邀请来获得的，这违反用户协议和引荐奖励计划规则。这种行为是非法的，PikPak 保留采取进一步法律措施的权利。",
      xn5tjTpa7Dnb3x6zQnEuI:
        "我们呼吁所有 PikPak 用户不要购买非法售卖的会员或账号，根据用户协议，这会导致严重的后果 - 包括会员被回收，甚至账号或设备被封禁。在 PikPak 官方App或网站以外出售会员的情况，如果满足如下任一特征的就是非法售卖，请您无论如何不要购买：",
      rFvjhdvkbsxAimIoRjKlu: "1. 不是用会员兑换码提供会员",
      xYycBoWmjuoeo10SIil1U: "2. 售卖时长短于1个月的会员",
      a2PqBsUNbentR6QsFy9wR: "3. 需要您提供邀请码或邀请链接来进行所谓会员充值",
      gG3kEPdGiqMlYv8F0: "4. 售卖具有短期会员的账号",
      "4qXpPhcHNikXyKOxQjGe":
        "购买会员最好的方法还是在官方App或网站内直接购买。如果您在购买时遇到问题或不方便之处，请随时通过 <a href='https://mypikpak.com/drive/feedback'>用户反馈</a> 与我们联系，我们将给予您帮助。",
      hWlVVzI96oJs0v9u_2xKx: "外部购买注意",
    },
  },
  "zh-TW": {
    notices: {
      "error-description-title": "重要 - 請升級App",
      "error-description-one":
        "非常抱歉，PikPak的支付服務提供商突然出現了問題，目前您無法在Android Apk、Web和桌面程序完成會員購買。",
      "error-description-two":
        "為了減少對您使用的影響，我們已經向您的當前賬號免費充入了7天會員。另外您還可以通過官方Telegram群找到其他購買方法。",
      "error-description-three":
        "我們會盡全力盡早解決會員支付問題，不斷提高產品服務質量，感謝您的支持。",
      "error-description-button": "前往官方群",
      "team-name": "PikPak團隊",
      nqu0Lz8AXfIwhzkjfTx2t:
        "支付等問題已解決，請在 設置 - 關於PikPak - 檢查更新 升級。",
      yrIer2T5zwR5eRzeB6Nq: "前往設置",
      "update-guide-title": "App 升級故障排除",
      "update-step": "如果遇到無法升級的情況，請按照以下步驟操作：",
      "update-step1": "1. 前往設置頁面，點擊“清除緩存”按鈕並進行確認。",
      "update-step2": "2. 前往關於 PikPak頁面，點擊“發現新版本，立即更新” 。",
      "update-step3": "3. 在彈窗中點擊“立即更新”按鈕，並等待新版本下載完成。",
      "update-other-tip": "如果問題仍然存在：請{0}下載最新版本後安裝",
      "goto-official-site": "前往官網",
      "7CwXq8XgzXgCg7PkSOs": "親愛的 PikPak 用戶：",
      dilFtvpn0PyFqax7DwO9z:
        "近期由於支付服務商的突發問題，PikPak 的支付服務受阻，導致用戶無法正常購買 PikPak 會員，經過研發工程師兩周的努力，現支付問題已經修復。",
      rsPSpOzTZvz5f9mTGoH2:
        "從 2021 年上線至今，PikPak 一直在不斷進步，我們一直致力於提供最極致的用戶體驗，這離不開您的支持與陪伴。所以在未來的一周內，我們將為您提供 PikPak 會員超低價購買資格，您可點擊下方的按鈕前往購買，希望您能喜歡。",
      cDfNYtp6x1NxmcoVEsH3a: "PikPak 團隊",
      xByxGiGmIgRoejWugEmPz: "誠摯道歉",
      epCovLaH2344wDdmyzen7: "領取低價特權",
      nyL2eXpIls3SKbeV5wWc: "官方補償",
      compensation1:
        "近期由於支付服務商的突發問題，您之前通過 Stripe 購買的 PikPak 會員訂閱方案已經被取消，盡管在一些支付渠道（如信用卡、支付寶等）中可能顯示訂閱狀態仍在生效，但後續 PikPak 不會繼續向您收取訂閱費用。您之前訂閱的會員的實際到期時間以 PikPak 內的展示信息為準。",
      compensation2:
        "為此給您帶來的不便，我們深感歉意，所以在未來的一周內，我們將為您提供 PikPak 會員超低價購買資格，您可點擊下方的按鈕前往購買，希望您能喜歡。",
      "4DjJjMxsc2wYsO0X7Zb6":
        "您的 PikPak App 版本已經過時，請盡快升級。新版本 App 解決了支付等問題，並包含多項重要優化。",
      a0bnHthk6JcLafodUtzb: "您可以通過以下任意一種方法升級App：",
      bVIsA7nv5pB0ShXPaCa34:
        "1. （推薦）在 <a href='pikpakapp://mypikpak.com/xpan/setting'>設置</a> - 關於PikPak - 檢查更新 升級。",
      zaMfDDd5WwCnLnHskDtK5:
        "2. 通過瀏覽器 <a href='pikpakapp://mypikpak.com/app/action_view?msg={0}'>直接下載新版本APK</a> 並安裝。",
      qk0fcFJv6iSRsc9K75YFj:
        "3. <a href='pikpakapp://mypikpak.com/app/action_view?msg=https%3A%2F%2Fmypikpak.net%2Fmobile-app'>前往官網</a> 安裝App。",
      qc6hRy8u13nuzMmoiVjBo:
        "如果您在安裝App時遇到問題，請查看 <a href='pikpakapp://mypikpak.com/app/action_view?msg=https%3A%2F%2Fmypikpak.net%2Finstall-apk-troubleshooting'>這個頁面</a> 。",
      pn6VR3ZYhAmlNfP5Bpm: "親愛的 PikPak 用戶，您好！",
      nk0yiYpwwTv0jcZNwLDfj: "感謝您一直以來對 PikPak 的支持和信任！",
      trDgv47AxPaO7k1RlC9Z:
        "近期，我們的伺服器多次遭到惡意的 DDoS 攻擊，導致我們的服務出現了不穩定的情況。您可能遇到了功能不能正常使用，付款後未及時成為會員等問題。這給您帶來了極大的不便和困擾，我們深表歉意！",
      wccwJmmoGfq2NJqEj4u5c:
        "我們的技術團隊已經盡最大努力處理了這一問題，並在盡可能短的時間內恢復了服務的正常運行。同時，我們也意識到，這樣的攻擊可能會再次發生，因此我們已經做好了應對未來可能出現的攻擊的準備，以保障您的使用體驗。",
      tYa3UAvyR3Zfcf7mc9OiY:
        "為了補償您損失的會員時間，我們專門為會員用戶推出了 2 天免費會員兌換碼【SorryGift】，只要您當前是會員用戶，即可使用該兌換碼獲得 2 天會員時間補償。請在 6 天內使用該兌換碼，過期失效。",
      rxbeWbrLDsocsSOk0o7I8:
        "再次感謝您對 PikPak 的理解和支持！我們將繼續努力為您提供更好的服務！",
      "27TqFsjz0MwfQr6Zb1E": "會員補償公告",
      z_9s8BdnVms0Rnwdm5Bl: "獎勵受限通知",
      lWuWbVc3XlRm7sXtQpXg0:
        "遺憾的通知您，我們在仔細分析後，發現您參與引薦獎勵計劃而獲得的邀請獎勵存在一些問題。",
      hqxg7DiHdmhMk2aSUiW1I:
        "您的設備或賬號與多個疑似異常、或由某個程序創建的PikPak賬號相關聯，此類行為違反了PikPak的用戶協議和引薦獎勵計劃規則。因此，您所獲得的邀請額外獎勵的免費會員已經被回收，後續也無法再獲得這種免費會員。",
      thmWBcCZbFh1F6B8gNX:
        "您可以繼續獲得受邀好友的購買獎勵，您購買PikPak會員也不會受到影響。",
      hRwlmNGn0Ew2nel96Z8Rv:
        "請您理解，只有消除無效的邀請獎勵，才能讓引薦獎勵計劃可以持續，並繼續為用戶提供優質的服務。",
      "1XoDe4JhZrS80zSrXCt2":
        "如果您認為這其中有誤，請收集有效邀請用戶的實證，並在設置頁面提交用戶反饋來申請恢復。",
      qgZpdxaAgz97QimA3oI9N: "親愛的 PikPak 粉絲：",
      i3BtgbbvyaHsNuY2NxRok:
        "從2021年上線至今，不知不覺 PikPak 的用戶數已經超過 500萬，這是一個值得慶祝的時刻。為了感謝各位早期訂閱會員對我們的支持，我們決定向您提供粉絲專屬的價格優惠，即您可以用一個超級低的價格來購買 PikPak 的會員服務，希望您可以喜歡。",
      xo14l1O8Er62jqav0ryWh:
        "PikPak 一直在不斷進步，一直致力於提供最極致的用戶體驗，感謝在這期間，各位粉絲朋友的支持與陪伴。",
      nd2l5Z72H9yM3G7JiMnMs: "PikPak 團隊所有成員",
      "9MrBvYso9NBJtk3xeOt": "送上美好的祝願",
      iVanoxY7ta8Jwo8cFhOm: "PikPak 粉絲專屬優惠",
      oPmxxLqmqyZoxpvThkQr: "無效邀請警告",
      c3TwRy4eaDZDRe1aVq:
        "遺憾地通知您，我們在仔細分析後，發現您參與引薦獎勵計畫而獲得的邀請獎勵存在一些問題。",
      "6is28jJXzGIp3do8DRm7I":
        "您的設備或賬號與多個疑似異常、或由某個程式創建的PikPak帳號相關聯，此類行為違反了PikPak的用戶協議和引薦獎勵計畫規則。請您停止這樣的無效邀請行為，否則將無法再獲得這種免費會員。",
      sHrdY2G_7y5rwCSghdLzm:
        "如果您曾經通過非官方的渠道購買過獎勵會員，或者曾經購買或共享帳號，也可能導致現在的狀況。上述這些行為是非常危險的，可能導致帳號或設備被封禁，請立即停止。",
      dNihJ0nwL4SfNs0Eo9Jmz:
        "如果您希望繼續參與引薦獎勵計畫，請您通過計畫中所列出的幾種方式，邀請真實的朋友，這樣您就可以繼續獲得邀請獎勵。",
      vG2m3U7PmnZOaX6bHgt:
        "請您理解，只有消除無效的邀請獎勵，才能讓引薦獎勵計畫可以持續，並繼續為用戶提供優質的服務。",
      aLWys2StUquUwTrNlCk: "PikPak團隊",
      keyword1: "已修復賬號衝突",
      keyword2:
        "因為早期版本的問題,您的手機號在PikPak 產生了兩個不同賬號。為避免出現混亂,我們已經將當前賬號設置為您的手機號可登錄的唯一賬號。",
      keyword3: "了解詳情",
      keyword4:
        "由於PikPak 的某些早期版本沒有對手機號格式進行標準化,您用同一個手機號登錄PikPak,可能會註冊生成不同的賬號。",
      keyword5:
        "這種情況會帶來混亂,例如您如果在登錄PikPak 時稍微變更自己手機號的輸入格式,可能會發現自己原來賬號中的內容沒有了,實際上這是因為登錄到了新的賬號。",
      keyword6:
        "非常抱歉給您帶來了困擾,我們已經修復了這個問題,同時也對所有賬號進行了檢查和處理。現在您的手機號無論以任何格式輸入,都只會登錄到當前賬號。",
      keyword7:
        "您的手機號對應的另一個賬號的內容也是完全安全的。如果另一個賬號有可用的會員時長,則已經自動追加到當前賬號中。",
      keyword8:
        "如果您希望後續登錄另一個賬號而不是當前賬號,請提交意見反饋,我們的用戶支持團隊可以為您切換賬號。",
      keyword9: "如果您認為使用當前賬號是合適的,則無需採取任何措施。",
      keyword10: "再次致歉,感謝您的支持和信任。",
      keyword11: "立即反饋",
      txvuGsiXQmwiQcum_9b9j:
        "目前存在一些未經 PikPak 授權的所謂 PikPak 會員商店，在一些電商平臺出售幾天時長的短期 PikPak 會員或賬號。經過我們分析，他們出售的會員是通過程序製造大量無效邀請來獲得的，這違反用戶協議和引薦獎勵計劃規則。這種行為是非法的，PikPak 保留采取進一步法律措施的權利。",
      xn5tjTpa7Dnb3x6zQnEuI:
        "我們呼籲所有 PikPak 用戶不要購買非法售賣的會員或賬號，根據用戶協議，這會導致嚴重的後果 - 包括會員被回收，甚至賬號或設備被封禁。在 PikPak 官方App或網站以外出售會員的情況，如果滿足如下任一特征的就是非法售賣，請您無論如何不要購買：",
      rFvjhdvkbsxAimIoRjKlu: "1. 不是用會員兌換碼提供會員",
      xYycBoWmjuoeo10SIil1U: "2. 售賣時長短於1個月的會員",
      a2PqBsUNbentR6QsFy9wR: "3. 需要您提供邀請碼或邀請鏈接來進行所謂會員充值",
      gG3kEPdGiqMlYv8F0: "4. 售賣具有短期會員的賬號",
      "4qXpPhcHNikXyKOxQjGe":
        "購買會員最好的方法還是在官方App或網站內直接購買。如果您在購買時遇到問題或不方便之處，請隨時通過 <a href='https://mypikpak.com/drive/feedback'>用户反馈</a> 與我們聯系，我們將給予您幫助。",
      hWlVVzI96oJs0v9u_2xKx: "外部購買註意",
    },
  },
  "en-US": {
    notices: {
      "error-description-title": "IMPORTANT - PLEASE UPGRADE APP",
      "error-description-one":
        "We are very sorry that there is a sudden problem with the payment service provider of PikPak. At present, you cannot complete the premium purchase in the Android Apk, Web and Desktop Programs.",
      "error-description-two":
        "In order to reduce the impact on your use, we have charged your current account with the free premium for 7 days. In addition, you can also find other ways to purchase through the official Telegram group.",
      "error-description-three":
        "We will try our best to solve the payment problem as soon as possible and continuously improve the quality of products and services. Thank you for your support.",
      "error-description-button": "Go to official group",
      "team-name": "PikPak Team",
      nqu0Lz8AXfIwhzkjfTx2t:
        "Payment and other issues have been resolved, please upgrade in Settings - About PikPak - Check for Updates.",
      yrIer2T5zwR5eRzeB6Nq: "Go to Settings",
      "update-guide-title": "App Upgrade Troubleshooting",
      "update-step":
        "If you are unable to upgrade, please use the following steps:",
      "update-step1":
        '1.Go to the "Setting" page, click the "Clear the Cache" and confirm.',
      "update-step2":
        '2.Go to the "About" page in PikPak, click "find a new vision, update now".',
      "update-step3":
        '3.Click the "Update now" botton in pop-up window and wait for the new version to download.',
      "update-other-tip":
        "If the update problem still exist, please {0} to download the lastest version.",
      "goto-official-site": "go to the official website",
      "7CwXq8XgzXgCg7PkSOs": "Dear PikPak User:",
      dilFtvpn0PyFqax7DwO9z:
        "Recently, due to some problems with PikPak’s payment service providers, PikPak's payment service was blocked, causing users to be unable to buy PikPak Premium normally. After two weeks of hard work by R&D engineers, the payment problem has been fixed.",
      rsPSpOzTZvz5f9mTGoH2:
        "Since its launch in 2021, PikPak has been making continuous progress. We have been committed to providing the most extreme user experience, which is inseparable from your support and companionship. So in the next 7 days, you will be eligible to buy PikPak Premium with a super low price, you can click the button below to buy it. We hope you like it.",
      cDfNYtp6x1NxmcoVEsH3a: "PikPak Team",
      xByxGiGmIgRoejWugEmPz: "Sincerely Apologize",
      epCovLaH2344wDdmyzen7: "Get privileged price",
      nyL2eXpIls3SKbeV5wWc: "Official Compensation",
      compensation1:
        "Recently, due to unexpected problems with the payment service provider, the PikPak Premium subscription plan you purchased through Stripe has been cancelled. Although some payment channels (such as credit cards, Alipay, etc.) may show that the subscription status is still in effect, subsequent PikPak will not continue to bill you for your subscription plan. The actual expiration time of your previously subscribed membership is subject to the information in PikPak.",
      compensation2:
        "We apologize for the inconvenience caused to you, so in the next 7 days, you will be eligible to buy PikPak Premium with a super low price, you can click the button below to buy it. We hope you like it.",
      "4DjJjMxsc2wYsO0X7Zb6":
        "Your PikPak App version is outdated, please upgrade as soon as possible. The new version of the App solves problems such as payment and contains a number of important optimizations.",
      a0bnHthk6JcLafodUtzb:
        "You can upgrade the App by any of the following methods:",
      bVIsA7nv5pB0ShXPaCa34:
        "1. (Recommended) Upgrade in <a href='pikpakapp://mypikpak.com/xpan/setting'>Settings</a> - About PikPak - Check for Updates.",
      zaMfDDd5WwCnLnHskDtK5:
        "2. Directly <a href='pikpakapp://mypikpak.com/app/action_view?msg={0}'>download the new version APK</a> through the browser and install it.",
      qk0fcFJv6iSRsc9K75YFj:
        "3. <a href='pikpakapp://mypikpak.com/app/action_view?msg=https%3A%2F%2Fmypikpak.net%2Fmobile-app'>Go to the official website</a> to install the app.",
      qc6hRy8u13nuzMmoiVjBo:
        "If you have trouble installing the App, please check <a href='pikpakapp://mypikpak.com/app/action_view?msg=https%3A%2F%2Fmypikpak.net%2Finstall-apk-troubleshooting'>this page</a> .",
      pn6VR3ZYhAmlNfP5Bpm: "Dear PikPak users,",
      nk0yiYpwwTv0jcZNwLDfj:
        "Thank you for your continued support and trust in PikPak! ",
      trDgv47AxPaO7k1RlC9Z:
        "Recently, our servers have been repeatedly attacked by malicious DDoS attacks, which caused our service to be unstable. You may have encountered problems such as functions not working properly, not becoming a premium user in time after payment, etc. This has caused you great inconvenience and trouble, and we sincerely apologize for that!",
      wccwJmmoGfq2NJqEj4u5c:
        "Our technical team has done their best to deal with this problem and restored the normal operation of the service in the shortest possible time. However, we also realize that such attacks may happen again, so we have prepared to deal with possible future attacks to ensure your user experience.",
      tYa3UAvyR3Zfcf7mc9OiY:
        "To compensate for your lost premium time, we have specially launched a 2-day free premium redeem code [SorryGift] for premium users. As long as you are a current premium user, you can use this redeem code to get 2 days of premium time compensation. Please use this redeem code within 6 days, otherwise it will expire.",
      rxbeWbrLDsocsSOk0o7I8:
        "Thank you again for your understanding and support of PikPak! We will continue to work hard to provide you with better service!",
      "27TqFsjz0MwfQr6Zb1E": "Premium Compensation Announcement",
      z_9s8BdnVms0Rnwdm5Bl: "Restricted Rewards Notice",
      lWuWbVc3XlRm7sXtQpXg0:
        "Regretfully inform you that after careful analysis, we found some problems with the invitation rewards you received for participating in the Referral Reward Program.",
      hqxg7DiHdmhMk2aSUiW1I:
        "Your device or account is associated with multiple suspected abnormal or program-created PikPak accounts, which violate PikPak's User Agreement and Referral Reward Program rules. Therefore, the free premium you obtained for inviting additional rewards has been withdrawn, and you will no longer be able to obtain this kind of free premium.",
      thmWBcCZbFh1F6B8gNX:
        "You can continue to receive purchase rewards from invited friends, and your purchase of PikPak premium will not be affected.",
      hRwlmNGn0Ew2nel96Z8Rv:
        "Please understand that only by eliminating invalid invitation rewards can the Referral Reward Program continue and continue to provide users with high-quality services.",
      "1XoDe4JhZrS80zSrXCt2":
        "If you believe this may be a mistake, gather evidence of validly invited users and submit user feedback on the settings page to request reinstatement.",
      qgZpdxaAgz97QimA3oI9N: "Dear PikPak Fans,",
      i3BtgbbvyaHsNuY2NxRok:
        "Since the launch in 2021, PikPak’s user base has grown to over 5 million, a moment worth celebrating. To thank our early subscribers for their support, we have decided to offer you exclusive discounts. You can now purchase PikPak premium at a super low price. We hope you will enjoy it.",
      xo14l1O8Er62jqav0ryWh:
        "PikPak is constantly improving and committed in providing the ultimate user experience. Thank you for your support during this time.",
      nd2l5Z72H9yM3G7JiMnMs: "Best Wishes,",
      "9MrBvYso9NBJtk3xeOt": "PikPak Team",
      iVanoxY7ta8Jwo8cFhOm: "PikPak Fan Exclusive Offers",
      oPmxxLqmqyZoxpvThkQr: "Invalid Invitation Warning",
      c3TwRy4eaDZDRe1aVq:
        "We regret to inform you that after careful analysis, we have identified issues with the referral rewards you received for participating in the Referral Reward Program.",
      "6is28jJXzGIp3do8DRm7I":
        "Your device or account is associated with multiple suspicious PikPak accounts, potentially created by a program. Such behavior violates PikPak's user agreement and Referral Reward Program rules. We urge you to cease such invalid invitation activities, as failure to do so will result in the discontinuation of these free memberships.",
      sHrdY2G_7y5rwCSghdLzm:
        "If you have acquired reward memberships through unofficial channels or have ever purchased or shared accounts, this may contribute to the current situation. These actions are highly risky and could lead to account or device suspension. Please cease immediately.",
      dNihJ0nwL4SfNs0Eo9Jmz:
        "If you wish to continue participating in the Referral Reward Program, kindly invite real friends using the methods outlined in the program. This way, you can continue to receive referral rewards.",
      vG2m3U7PmnZOaX6bHgt:
        "Please understand that eliminating invalid referral rewards is essential to the sustainability of the Referral Reward Program and to ensure the continued provision of high-quality service to our users.",
      aLWys2StUquUwTrNlCk: "PikPak Team",
      keyword1: "Account Conflict Fixed",
      keyword2:
        "Due to problems with earlier versions, your mobile phone number has generated two different accounts in PikPak. To avoid confusion, we have set the current account as the only account that can be logged in with your mobile phone number.",
      keyword3: "Learn more",
      keyword4:
        "Because some early versions of PikPak did not standardize the format of mobile phone numbers, you may register and generate different accounts when you log in to PikPak with the same mobile phone number.",
      keyword5:
        "This situation will cause confusion. For example, if you slightly change the format of your mobile phone number when logging in to PikPak, you may find that the content in your original account has disappeared. In fact, this is because you have logged in to a new account.",
      keyword6:
        "We are very sorry for the inconvenience caused to you. We have fixed this problem and have also checked and processed all accounts. Now your mobile phone number will only be logged into the current account no matter which format you enter.",
      keyword7:
        "The content of another account corresponding to your mobile phone number is also completely safe. If another account has available premium time, it will be automatically added to the current account.",
      keyword8:
        "If you would like to log into another account instead of your current account in the future, please submit feedback and our user support team can switch accounts for you.",
      keyword9:
        "If you think your current account is normal to use, you don't need to take any action.",
      keyword10: "We apologize again and thank you for your support and trust.",
      keyword11: "Feedback Immediately",
      txvuGsiXQmwiQcum_9b9j:
        "Currently, there are some so-called PikPak premium stores that are not authorized by PikPak. They are selling short-term PikPak premium memberships or accounts on some e-commerce platforms. Based on our analysis, these premium memberships they are selling are obtained by generating a large number of invalid invitations, which violates our user agreement and referral program rules. This behavior is illegal, and PikPak reserves the right to take further legal action.",
      xn5tjTpa7Dnb3x6zQnEuI:
        "We urge all PikPak users not to purchase premium memberships or accounts being sold illegally. According to the user agreement, this can lead to serious consequences, including the revocation of premium memberships or even the suspension of accounts or devices. If you come across premium memberships being sold outside of the official PikPak app or website that meet any of the following criteria, please do not purchase them under any circumstances:",
      rFvjhdvkbsxAimIoRjKlu:
        "1.Premium memberships are not provided through premium membership redemption codes.",
      xYycBoWmjuoeo10SIil1U:
        "2.Premium memberships are sold for a duration of less than one month.",
      a2PqBsUNbentR6QsFy9wR:
        "3.You are required to provide an invitation code or invitation link for so-called premium membership recharge.",
      gG3kEPdGiqMlYv8F0:
        "4.Accounts are being sold with short-term premium memberships.",
      "4qXpPhcHNikXyKOxQjGe":
        "The best way to purchase a premium membership is directly through the official PikPak app or website. If you encounter any issues or inconveniences during the purchase, please feel free to contact us through the <a href='https://mypikpak.com/drive/feedback'>User Feedback</a>, and we will assist you.",
      hWlVVzI96oJs0v9u_2xKx: "Notes on External Purchase",
    },
  },
  id: {
    notices: {
      "error-description-title": "PENTING - HARAP TINGKATKAN APLIKASI",
      "error-description-one":
        "Kami mohon maaf karena tiba-tiba ada masalah dengan penyedia layanan pembayaran PikPak. Saat ini, Anda tidak dapat menyelesaikan pembelian premium di Android Apk, Web, dan Program Desktop.",
      "error-description-two":
        "Untuk mengurangi dampak pada penggunaan Anda, kami telah menagih akun Anda saat ini dengan premium gratis selama 7 hari. Selain itu, Anda juga bisa menemukan cara lain untuk melakukan pembelian melalui grup Telegram resmi.",
      "error-description-three":
        "Kami akan berusaha sebaik mungkin untuk menyelesaikan masalah pembayaran secepat mungkin dan terus meningkatkan kualitas produk dan layanan. Terima kasih atas dukungan Anda.",
      "error-description-button": "Menuju kelompok resmi",
      "team-name": "PikPak",
      nqu0Lz8AXfIwhzkjfTx2t:
        "Pembayaran dan masalah lainnya telah diselesaikan, silakan tingkatkan di Pengaturan - Tentang PikPak ​​​​- Periksa Pembaruan.",
      yrIer2T5zwR5eRzeB6Nq: "Pergi ke Pengaturan",
      "update-guide-title": "Pemecahan Masalah Peningkatan Aplikasi",
      "update-step":
        "Jika Anda tidak dapat memutakhirkan, silakan gunakan langkah-langkah berikut:",
      "update-step1":
        '1.Buka halaman "Pengaturan", klik "Hapus Tembolok" dan konfirmasi.',
      "update-step2":
        '2.Buka halaman "Tentang" di PikPak, klik "temukan visi baru, perbarui sekarang".',
      "update-step3":
        '3.Klik tombol "Perbarui sekarang" di jendela pop-up dan tunggu versi baru diunduh.',
      "update-other-tip":
        "Jika masalah pembaruan masih ada, silakan {0} untuk mengunduh versi terbaru.",
      "goto-official-site": "buka situs web resmi",
      "7CwXq8XgzXgCg7PkSOs": "Pengguna PikPak yang terhormat:",
      dilFtvpn0PyFqax7DwO9z:
        "Baru-baru ini, karena beberapa masalah dengan penyedia layanan pembayaran PikPak, layanan pembayaran PikPak diblokir, menyebabkan pengguna tidak dapat membeli PikPak Premium secara normal. Setelah kerja keras selama dua minggu oleh para insinyur R&D, masalah pembayaran telah diperbaiki.",
      rsPSpOzTZvz5f9mTGoH2:
        "Sejak diluncurkan pada tahun 2021, PikPak terus membuat kemajuan. Kami telah berkomitmen untuk memberikan pengalaman pengguna yang paling ekstrem, yang tidak dapat dipisahkan dari dukungan dan persahabatan Anda. Jadi dalam 7 hari ke depan, kamu berhak membeli PikPak Premium dengan harga super murah, kamu bisa klik tombol di bawah untuk membelinya. Kami harap Anda menyukainya.",
      cDfNYtp6x1NxmcoVEsH3a: "Tim PikPak",
      xByxGiGmIgRoejWugEmPz: "permintaan maaf yang tulus",
      epCovLaH2344wDdmyzen7: "Dapatkan harga istimewa",
      nyL2eXpIls3SKbeV5wWc: "Kompensasi Resmi",
      compensation1:
        "Baru-baru ini, karena masalah tak terduga dengan penyedia layanan pembayaran, paket langganan PikPak Premium yang Anda beli melalui Stripe telah dibatalkan. Meskipun beberapa saluran pembayaran (seperti kartu kredit, Alipay, dll.) mungkin menunjukkan bahwa status langganan masih berlaku, PikPak selanjutnya tidak akan terus menagih Anda untuk paket langganan Anda. Waktu kedaluwarsa sebenarnya dari langganan Anda sebelumnya tunduk pada informasi di PikPak.",
      compensation2:
        "Kami mohon maaf atas ketidaknyamanan yang terjadi pada Anda, sehingga dalam 7 hari ke depan, Anda berhak membeli PikPak Premium dengan harga super murah, Anda dapat mengklik tombol di bawah untuk membelinya. Kami harap Anda menyukainya.",
      "4DjJjMxsc2wYsO0X7Zb6":
        "Versi Aplikasi PikPak Anda kedaluwarsa, harap perbarui sesegera mungkin. Versi baru Aplikasi memecahkan masalah seperti pembayaran dan berisi sejumlah pengoptimalan penting.",
      a0bnHthk6JcLafodUtzb:
        "Anda dapat memutakhirkan Aplikasi dengan salah satu metode berikut:",
      bVIsA7nv5pB0ShXPaCa34:
        "1. (Disarankan) Tingkatkan di <a href='pikpakapp://mypikpak.com/xpan/setting'>Pengaturan</a> - Tentang PikPak - Periksa Pembaruan.",
      zaMfDDd5WwCnLnHskDtK5:
        "2. Langsung <a href='pikpakapp://mypikpak.com/app/action_view?msg={0}'>unduh APK versi baru</a> melalui browser dan instal.",
      qk0fcFJv6iSRsc9K75YFj:
        "3. <a href='pikpakapp://mypikpak.com/app/action_view?msg=https%3A%2F%2Fmypikpak.net%2Fmobile-app'>Buka situs web resmi</a> untuk menginstal aplikasi.",
      qc6hRy8u13nuzMmoiVjBo:
        "Jika Anda kesulitan menginstal Aplikasi, silakan periksa <a href='pikpakapp://mypikpak.com/app/action_view?msg=https%3A%2F%2Fmypikpak.net%2Finstall-apk-troubleshooting'>halaman ini</a> .",
      pn6VR3ZYhAmlNfP5Bpm: "Pengguna PikPak yang terhormat,",
      nk0yiYpwwTv0jcZNwLDfj:
        "Terima kasih atas dukungan dan kepercayaan Anda terhadap PikPak!",
      trDgv47AxPaO7k1RlC9Z:
        "Baru-baru ini, server kami telah berulang kali diserang oleh serangan DDoS jahat, yang menyebabkan layanan kami tidak stabil. Anda mungkin mengalami masalah seperti fungsi yang tidak berfungsi dengan baik, tidak menjadi pengguna premium tepat waktu setelah pembayaran, dll. Ini telah menyebabkan Anda banyak ketidaknyamanan dan kesulitan, dan kami dengan tulus meminta maaf atas hal itu!",
      wccwJmmoGfq2NJqEj4u5c:
        "Tim teknis kami telah melakukan yang terbaik untuk menangani masalah ini dan memulihkan operasi normal layanan dalam waktu sesingkat mungkin. Namun, kami juga menyadari bahwa serangan seperti itu dapat terjadi lagi, jadi kami telah bersiap untuk menghadapi serangan masa depan yang mungkin terjadi untuk memastikan pengalaman pengguna Anda.",
      tYa3UAvyR3Zfcf7mc9OiY:
        "Untuk mengganti waktu premium Anda yang hilang, kami telah meluncurkan kode penukaran premium gratis 2 hari [SorryGift] khusus untuk pengguna premium. Selama Anda adalah pengguna premium saat ini, Anda dapat menggunakan kode penukaran ini untuk mendapatkan 2 hari kompensasi waktu premium. Silakan gunakan kode penukaran ini dalam waktu 6 hari, jika tidak akan kedaluwarsa.",
      rxbeWbrLDsocsSOk0o7I8:
        "Terima kasih lagi atas pengertian dan dukungan Anda terhadap PikPak! Kami akan terus bekerja keras untuk memberi Anda layanan yang lebih baik.",
      "27TqFsjz0MwfQr6Zb1E": "Pengumuman kompensasi anggota",
      z_9s8BdnVms0Rnwdm5Bl: "Pemberitahuan Hadiah Terbatas",
      lWuWbVc3XlRm7sXtQpXg0:
        "Dengan menyesal memaklumkan kepada anda bahwa setelah melakukan analisis yang cermat, kami menemukan beberapa masalah dengan hadiah undangan yang Anda terima karena berpartisipasi dalam Program Hadiah Referal.",
      hqxg7DiHdmhMk2aSUiW1I:
        "Perangkat atau akun Anda terkait dengan beberapa akun PikPak yang dicurigai abnormal atau dibuat oleh program, yang melanggar Perjanjian Pengguna dan Aturan Program Hadiah Rujukan PikPak. Oleh karena itu, premium gratis yang Anda peroleh untuk mengundang hadiah tambahan telah ditarik, dan Anda tidak lagi dapat memperoleh premium gratis semacam ini.",
      thmWBcCZbFh1F6B8gNX:
        "Anda dapat terus menerima hadiah pembelian dari teman yang diundang, dan pembelian premium PikPak Anda tidak akan terpengaruh.",
      hRwlmNGn0Ew2nel96Z8Rv:
        "Harap dipahami bahwa hanya dengan menghapus hadiah undangan yang tidak valid, Program Hadiah Rujukan dapat terus berlanjut dan terus memberikan layanan berkualitas tinggi kepada pengguna.",
      "1XoDe4JhZrS80zSrXCt2":
        "Jika Anda yakin ini adalah kesalahan, kumpulkan bukti pengguna yang diundang secara sah dan kirim masukan pengguna di halaman setelan untuk meminta pengaktifan kembali.",
      qgZpdxaAgz97QimA3oI9N: "Kepada Penggemar PikPak,",
      i3BtgbbvyaHsNuY2NxRok:
        "Sejak peluncuran pada tahun 2021, basis pengguna PikPak telah berkembang menjadi lebih dari 5 juta, suatu momen yang patut dirayakan. Untuk mengucapkan terima kasih kepada pelanggan awal kami atas dukungannya, kami telah memutuskan untuk menawarkan diskon eksklusif kepada Anda. Anda sekarang dapat membeli keanggotaan PikPak dengan harga yang sangat rendah. Kami berharap Anda akan menikmatinya.",
      xo14l1O8Er62jqav0ryWh:
        "PikPak terus berkembang dan berkomitmen untuk memberikan pengalaman pengguna yang terbaik. Terima kasih atas dukungan Anda selama ini.",
      nd2l5Z72H9yM3G7JiMnMs: "Salam Hormat,",
      "9MrBvYso9NBJtk3xeOt": "Tim PikPak",
      iVanoxY7ta8Jwo8cFhOm: "PikPak Penawaran Eksklusif Penggemar",
      oPmxxLqmqyZoxpvThkQr: "Peringatan Undangan Tidak Valid",
      c3TwRy4eaDZDRe1aVq:
        "Kami menyesal untuk memberi tahu Anda bahwa setelah analisis yang cermat, kami telah mengidentifikasi masalah dengan hadiah rujukan yang Anda terima karena berpartisipasi dalam Program Hadiah Rujukan.",
      "6is28jJXzGIp3do8DRm7I":
        "Perangkat atau akun Anda terkait dengan beberapa akun PikPak yang mencurigakan, yang berpotensi dibuat oleh program. Perilaku seperti ini melanggar perjanjian pengguna PikPak dan aturan Program Hadiah Rujukan. Kami mendesak Anda untuk menghentikan kegiatan undangan tidak valid tersebut, karena jika tidak dilakukan, hal ini akan mengakibatkan penghentian keanggotaan gratis ini.",
      sHrdY2G_7y5rwCSghdLzm:
        "Jika Anda telah memperoleh keanggotaan hadiah melalui saluran tidak resmi atau pernah membeli atau berbagi akun, hal ini mungkin berkontribusi pada situasi saat ini. Tindakan-tindakan ini sangat berisiko dan bisa mengakibatkan penangguhan akun atau perangkat. Harap hentikan segera.",
      dNihJ0nwL4SfNs0Eo9Jmz:
        "Jika Anda ingin terus berpartisipasi dalam Program Hadiah Rujukan, undanglah teman-teman nyata menggunakan metode yang diuraikan dalam program. Dengan cara ini, Anda dapat terus menerima hadiah rujukan.",
      vG2m3U7PmnZOaX6bHgt:
        "Harap pahami bahwa menghilangkan hadiah rujukan tidak valid sangat penting untuk keberlanjutan Program Hadiah Rujukan dan untuk memastikan penyediaan layanan berkualitas tinggi bagi para pengguna kami.",
      aLWys2StUquUwTrNlCk: "Tim PikPak",
      keyword1: "Konflik akun telah diperbaiki",
      keyword2:
        "Karena masalah dengan versi sebelumnya, nomor ponsel Anda telah menghasilkan dua akun berbeda di PikPak. Untuk menghindari kebingungan, kami telah menetapkan akun saat ini sebagai satu-satunya akun yang dapat login dengan nomor ponsel Anda.",
      keyword3: "Belajarlah lagi",
      keyword4:
        "Karena beberapa versi awal PikPak tidak menstandarkan format nomor ponsel, Anda dapat mendaftar dan membuat akun berbeda saat masuk ke PikPak dengan nomor ponsel yang sama.",
      keyword5:
        "Situasi ini akan menimbulkan kebingungan. Misalnya, jika Anda sedikit mengubah format input nomor ponsel Anda saat masuk ke PikPak, Anda mungkin menemukan bahwa konten di akun asli Anda hilang. Faktanya, ini karena Anda telah login ke akun baru.",
      keyword6:
        "Kami mohon maaf sebesar-besarnya atas ketidaknyamanan yang Anda alami. Kami telah memperbaiki masalah ini dan juga telah memeriksa dan memproses semua akun. Sekarang nomor ponsel Anda hanya akan masuk ke akun saat ini, apa pun format yang Anda masukkan.",
      keyword7:
        "Konten akun lain yang sesuai dengan nomor ponsel Anda juga sepenuhnya aman. Jika akun lain memiliki waktu keanggotaan yang tersedia, maka secara otomatis akan ditambahkan ke akun saat ini.",
      keyword8:
        "Jika Anda ingin masuk ke akun lain selain akun Anda saat ini di masa mendatang, kirimkan masukan dan tim dukungan pengguna kami dapat mengganti akun untuk Anda.",
      keyword9:
        "Jika Anda merasa pantas menggunakan akun Anda saat ini, Anda tidak perlu melakukan tindakan apa pun.",
      keyword10:
        "Sekali lagi kami mohon maaf dan terima kasih atas dukungan dan kepercayaan Anda.",
      keyword11: "Umpan balik segera",
      txvuGsiXQmwiQcum_9b9j:
        "Saat ini, ada beberapa toko premium PikPak yang disebut-sebut yang tidak diotorisasi oleh PikPak. Mereka menjual keanggotaan premium PikPak jangka pendek atau akun di beberapa platform e-commerce. Berdasarkan analisis kami, keanggotaan premium yang mereka jual diperoleh dengan menghasilkan sejumlah besar undangan yang tidak valid, yang melanggar perjanjian pengguna kami dan aturan program penghargaan referensi. Perilaku ini ilegal, dan PikPak berhak untuk mengambil tindakan hukum lebih lanjut.",
      xn5tjTpa7Dnb3x6zQnEuI:
        "Kami mendesak semua pengguna PikPak untuk tidak membeli keanggotaan premium atau akun yang dijual secara ilegal. Sesuai dengan perjanjian pengguna, hal ini dapat mengakibatkan konsekuensi serius, termasuk pencabutan keanggotaan premium atau bahkan penangguhan akun atau perangkat. Jika Anda menemui keanggotaan premium yang dijual di luar aplikasi resmi PikPak atau situs web resmi yang memenuhi salah satu kriteria berikut, harap jangan membelinya dalam keadaan apa pun:",
      rFvjhdvkbsxAimIoRjKlu:
        "1.Keanggotaan premium tidak disediakan melalui kode penebusan keanggotaan premium.",
      xYycBoWmjuoeo10SIil1U:
        "2.Keanggotaan premium dijual dengan durasi kurang dari satu bulan.",
      a2PqBsUNbentR6QsFy9wR:
        "3.Anda diminta untuk menyediakan kode undangan atau tautan undangan untuk pengisian ulang keanggotaan premium yang disebut-sebut.",
      gG3kEPdGiqMlYv8F0:
        "4.Akun dijual dengan keanggotaan premium jangka pendek.",
      "4qXpPhcHNikXyKOxQjGe":
        "Cara terbaik untuk membeli keanggotaan premium adalah langsung melalui aplikasi resmi PikPak atau situs web resmi. Jika Anda mengalami masalah atau ketidaknyamanan selama pembelian, jangan ragu untuk menghubungi kami melalui <a href='https://mypikpak.com/drive/feedback'>Umpan Balik Pengguna</a>, dan kami akan membantu Anda.",
      hWlVVzI96oJs0v9u_2xKx: "Catatan tentang Pembelian Eksternal",
    },
  },
  ms: {
    notices: {
      "error-description-title": "PENTING - SILA NAIK TARAF APP",
      "error-description-one":
        "Kami amat kesal kerana terdapat masalah secara tiba-tiba dengan penyedia perkhidmatan pembayaran PikPak. Pada masa ini, anda tidak boleh melengkapkan pembelian premium dalam Apk Android, Web dan Program Desktop.",
      "error-description-two":
        "Untuk mengurangkan kesan ke atas penggunaan anda, kami telah mengecaj akaun semasa anda dengan premium percuma selama 7 hari. Selain itu, anda juga boleh mencari cara lain untuk membeli melalui kumpulan Telegram rasmi.",
      "error-description-three":
        "Kami akan cuba yang terbaik untuk menyelesaikan masalah pembayaran secepat mungkin dan terus meningkatkan kualiti produk dan perkhidmatan. Terima kasih atas sokongan anda.",
      "error-description-button": "Pergi ke kumpulan rasmi",
      "team-name": "Pasukan PikPak",
      nqu0Lz8AXfIwhzkjfTx2t:
        "Pembayaran dan isu lain telah diselesaikan, sila naik taraf dalam Tetapan - Perihal PikPak ​​- Semak Kemaskini.",
      yrIer2T5zwR5eRzeB6Nq: "Pergi ke Tetapan",
      "update-guide-title": "Menyelesaikan Masalah Peningkatan Apl",
      "update-step":
        "Jika anda tidak dapat menaik taraf, sila gunakan langkah berikut:",
      "update-step1":
        '1. Pergi ke halaman "Tetapan", klik "Kosongkan Cache" dan sahkan.',
      "update-step2":
        '2. Pergi ke halaman "Perihal" dalam PikPak, klik "cari visi baharu, kemas kini sekarang"',
      "update-step3":
        '3.Klik butang "Kemas kini sekarang" dalam tetingkap timbul dan tunggu versi baharu dimuat turun.',
      "update-other-tip":
        "Jika masalah kemas kini masih wujud, sila {0} untuk memuat turun versi terkini.",
      "goto-official-site": "pergi ke tapak web rasmi",
      "7CwXq8XgzXgCg7PkSOs": "Pengguna PikPak yang dihormati:",
      dilFtvpn0PyFqax7DwO9z:
        "Baru-baru ini, disebabkan beberapa masalah dengan penyedia perkhidmatan pembayaran PikPak, perkhidmatan pembayaran PikPak telah disekat, menyebabkan pengguna tidak dapat membeli PikPak Premium seperti biasa. Selepas dua minggu bekerja keras oleh jurutera R&D, masalah pembayaran telah diselesaikan.",
      rsPSpOzTZvz5f9mTGoH2:
        "Sejak dilancarkan pada 2021, PikPak telah membuat kemajuan yang berterusan. Kami telah komited untuk menyediakan pengalaman pengguna yang paling ekstrem, yang tidak dapat dipisahkan daripada sokongan dan persahabatan anda. Jadi dalam 7 hari akan datang, anda akan layak untuk membeli PikPak Premium dengan harga yang sangat rendah, anda boleh klik butang di bawah untuk membelinya. Kami harap anda menyukainya.",
      cDfNYtp6x1NxmcoVEsH3a: "Pasukan PikPak",
      xByxGiGmIgRoejWugEmPz: "Mohon Maaf",
      epCovLaH2344wDdmyzen7: "Dapatkan harga istimewa",
      nyL2eXpIls3SKbeV5wWc: "Pampasan Rasmi",
      compensation1:
        "Baru-baru ini, disebabkan masalah yang tidak dijangka dengan penyedia perkhidmatan pembayaran, pelan langganan PikPak Premium yang anda beli melalui Stripe telah dibatalkan. Walaupun sesetengah saluran pembayaran (seperti kad kredit, Alipay, dsb.) mungkin menunjukkan bahawa status langganan masih berkuat kuasa, PikPak berikutnya tidak akan terus mengebil anda untuk pelan langganan anda. Masa tamat sebenar keahlian anda yang telah dilanggan sebelum ini adalah tertakluk kepada maklumat dalam PikPak.",
      compensation2:
        "Kami memohon maaf atas kesulitan yang berlaku kepada anda, jadi dalam masa 7 hari akan datang, anda akan layak untuk membeli PikPak Premium dengan harga yang sangat rendah, anda boleh klik butang di bawah untuk membelinya. Kami harap anda menyukainya.",
      "4DjJjMxsc2wYsO0X7Zb6":
        "Versi Apl PikPak anda sudah lapuk, sila tingkatkan secepat mungkin. Versi baharu Apl menyelesaikan masalah seperti pembayaran dan mengandungi beberapa pengoptimuman penting.",
      a0bnHthk6JcLafodUtzb:
        "Anda boleh menaik taraf Apl melalui mana-mana kaedah berikut:",
      bVIsA7nv5pB0ShXPaCa34:
        "1. (Disyorkan) Naik taraf dalam <a href='pikpakapp://mypikpak.com/xpan/setting'>Tetapan</a> - Perihal PikPak - Semak Kemas Kini.",
      zaMfDDd5WwCnLnHskDtK5:
        "2. Terus <a href='pikpakapp://mypikpak.com/app/action_view?msg={0}'>muat turun APK versi baharu</a> melalui penyemak imbas dan pasangnya.",
      qk0fcFJv6iSRsc9K75YFj:
        "3. <a href='pikpakapp://mypikpak.com/app/action_view?msg=https%3A%2F%2Fmypikpak.net%2Fmobile-app'>Pergi ke tapak web rasmi</a> untuk memasang apl.",
      qc6hRy8u13nuzMmoiVjBo:
        "Jika anda menghadapi masalah memasang Apl, sila semak <a href='pikpakapp://mypikpak.com/app/action_view?msg=https%3A%2F%2Fmypikpak.net%2Finstall-apk-troubleshooting'>halaman ini</a> .",
      pn6VR3ZYhAmlNfP5Bpm: "Pengguna PikPak yang dihormati,",
      nk0yiYpwwTv0jcZNwLDfj:
        "Terima kasih atas sokongan dan kepercayaan anda terhadap PikPak!",
      trDgv47AxPaO7k1RlC9Z:
        "Baru-baru ini, pelayan kami telah diserang berulang kali oleh serangan DDoS jahat, yang menyebabkan perkhidmatan kami tidak stabil. Anda mungkin menghadapi masalah seperti fungsi yang tidak berfungsi dengan betul, tidak menjadi pengguna premium pada masa selepas pembayaran, dll. Ini telah menyebabkan anda banyak kesulitan dan kesusahan, dan kami memohon maaf dengan ikhlas atas perkara itu!",
      wccwJmmoGfq2NJqEj4u5c:
        "Pasukan teknikal kami telah melakukan yang terbaik untuk menangani masalah ini dan memulihkan operasi normal perkhidmatan dalam masa yang sesingkat mungkin. Namun, kami juga sedar bahawa serangan seperti itu boleh berlaku lagi, jadi kami telah bersedia untuk menghadapi serangan masa depan yang mungkin berlaku untuk memastikan pengalaman pengguna anda.",
      tYa3UAvyR3Zfcf7mc9OiY:
        "Untuk menggantikan masa premium anda yang hilang, kami telah melancarkan kod penukaran premium percuma 2 hari [SorryGift] khas untuk pengguna premium. Selagi anda adalah pengguna premium semasa, anda boleh menggunakan kod penukaran ini untuk mendapatkan 2 hari pampasan masa premium. Sila gunakan kod penukaran ini dalam tempoh 6 hari, jika tidak ia akan luput.",
      rxbeWbrLDsocsSOk0o7I8:
        "Terima kasih sekali lagi atas kefahaman dan sokongan anda terhadap PikPak! Kami akan terus bekerja keras untuk memberi anda perkhidmatan yang lebih baik.",
      "27TqFsjz0MwfQr6Zb1E": "Pengumuman pampasan ahli",
      z_9s8BdnVms0Rnwdm5Bl: "Notis Ganjaran Terhad",
      lWuWbVc3XlRm7sXtQpXg0:
        "Dengan kesalnya memaklumkan kepada anda bahawa selepas analisis yang teliti, kami mendapati beberapa masalah dengan ganjaran jemputan yang anda terima kerana menyertai Program Ganjaran Rujukan.",
      hqxg7DiHdmhMk2aSUiW1I:
        "Peranti atau akaun anda dikaitkan dengan beberapa akaun PikPak yang disyaki tidak normal atau dicipta oleh program, yang melanggar Perjanjian Pengguna dan Peraturan Program Ganjaran Rujukan PikPak. Oleh itu, premium percuma yang anda perolehi untuk menjemput ganjaran tambahan telah ditarik balik, dan anda tidak lagi akan dapat memperoleh premium percuma jenis ini.",
      thmWBcCZbFh1F6B8gNX:
        "Anda boleh terus menerima ganjaran pembelian daripada rakan yang dijemput, dan pembelian premium PikPak anda tidak akan terjejas.",
      hRwlmNGn0Ew2nel96Z8Rv:
        "Sila faham bahawa hanya dengan menghapuskan ganjaran jemputan yang tidak sah, Program Ganjaran Rujukan boleh diteruskan dan terus menyediakan pengguna dengan perkhidmatan berkualiti tinggi.",
      "1XoDe4JhZrS80zSrXCt2":
        "Jika anda percaya ini mungkin satu kesilapan, kumpulkan bukti pengguna yang dijemput secara sah dan serahkan maklum balas pengguna pada halaman tetapan untuk meminta pengembalian semula.",
      qgZpdxaAgz97QimA3oI9N: "Kepada Peminat PikPak,",
      i3BtgbbvyaHsNuY2NxRok:
        "Sejak pelancaran pada tahun 2021, pangkalan pengguna PikPak telah berkembang menjadi lebih daripada 5 juta, satu saat yang patut dirayakan. Untuk mengucapkan terima kasih kepada pelanggan awal kami atas sokongan mereka, kami telah memutuskan untuk menawarkan diskaun eksklusif kepada anda. Anda kini boleh membeli keahlian PikPak pada harga yang sangat rendah. Kami berharap anda akan menikmatinya.",
      xo14l1O8Er62jqav0ryWh:
        "PikPak sentiasa berkembang dan komited dalam memberikan pengalaman pengguna yang terbaik. Terima kasih atas sokongan anda sepanjang masa ini.",
      nd2l5Z72H9yM3G7JiMnMs: "Salam Hormat,",
      "9MrBvYso9NBJtk3xeOt": "Pasukan PikPak",
      iVanoxY7ta8Jwo8cFhOm: "PikPak Tawaran Eksklusif Peminat",
      oPmxxLqmqyZoxpvThkQr: "Amaran Jemputan Tidak Sah",
      c3TwRy4eaDZDRe1aVq:
        "Kami menyesal memberitahu anda bahawa selepas analisis yang teliti, kami telah mengenal pasti isu dengan ganjaran rujukan yang anda terima kerana mengambil bahagian dalam Program Ganjaran Rujukan.",
      "6is28jJXzGIp3do8DRm7I":
        "Peranti atau akaun anda dikaitkan dengan beberapa akaun PikPak yang mencurigakan, yang berpotensi dicipta oleh program. ingkah laku sedemikian melanggar perjanjian pengguna PikPak dan peraturan Program Ganjaran Rujukan. Kami menggesa anda untuk menghentikan aktiviti-aktiviti jemputan tidak sah sedemikian, kerana kegagalan berbuat demikian akan mengakibatkan penghentian keahlian percuma ini.",
      sHrdY2G_7y5rwCSghdLzm:
        "Jika anda telah memperoleh keahlian ganjaran melalui saluran tidak rasmi atau pernah membeli atau berkongsi akaun, ini mungkin menyumbang kepada situasi semasa. Tindakan-tindakan ini amat berisiko dan boleh menyebabkan penyekatan akaun atau peranti. Sila hentikan dengan serta-merta.",
      dNihJ0nwL4SfNs0Eo9Jmz:
        "Sekiranya anda ingin terus mengambil bahagian dalam Program Ganjaran Rujukan, sila jemput rakan sebenar menggunakan kaedah-kaedah yang dinyatakan dalam program. Ini akan membolehkan anda terus menerima ganjaran rujukan.",
      vG2m3U7PmnZOaX6bHgt:
        "Sila fahami bahawa menghapuskan ganjaran rujukan tidak sah adalah penting untuk kelestarian Program Ganjaran Rujukan dan untuk memastikan penyediaan perkhidmatan berkualiti tinggi terus berlanjutan kepada pengguna kami.",
      aLWys2StUquUwTrNlCk: "Pasukan PikPak",
      keyword1: "Konflik akaun dibetulkan",
      keyword2:
        "Disebabkan masalah dengan versi terdahulu, nombor telefon mudah alih anda telah menghasilkan dua akaun berbeza dalam PikPak. Untuk mengelakkan kekeliruan, kami telah menetapkan akaun semasa sebagai satu-satunya akaun yang boleh dilog masuk dengan nombor telefon mudah alih anda.",
      keyword3: "Ketahui lebih lanjut",
      keyword4:
        "Oleh kerana beberapa versi awal PikPak tidak menyeragamkan format nombor telefon mudah alih, anda boleh mendaftar dan menjana akaun yang berbeza apabila anda log masuk ke PikPak dengan nombor telefon mudah alih yang sama.",
      keyword5:
        "Keadaan ini akan menimbulkan kekeliruan. Sebagai contoh, jika anda menukar sedikit format input nombor telefon mudah alih anda semasa log masuk ke PikPak, anda mungkin mendapati kandungan dalam akaun asal anda telah hilang. Sebenarnya, ini kerana anda telah log masuk ke akaun baharu.",
      keyword6:
        "Kami amat memohon maaf atas kesulitan yang berlaku kepada anda. Kami telah membetulkan masalah ini dan juga telah menyemak dan memproses semua akaun. Kini nombor telefon mudah alih anda hanya akan dilog masuk ke akaun semasa tidak kira format yang anda masukkan.",
      keyword7:
        "Kandungan akaun lain yang sepadan dengan nombor telefon mudah alih anda juga selamat sepenuhnya. Jika akaun lain mempunyai masa keahlian yang tersedia, ia akan ditambahkan secara automatik pada akaun semasa.",
      keyword8:
        "Jika anda ingin log masuk ke akaun lain dan bukannya akaun semasa anda pada masa hadapan, sila serahkan maklum balas dan pasukan sokongan pengguna kami boleh menukar akaun untuk anda.",
      keyword9:
        "Jika anda fikir ia sesuai untuk menggunakan akaun semasa anda, anda tidak perlu mengambil sebarang tindakan.",
      keyword10:
        "Kami memohon maaf sekali lagi dan terima kasih atas sokongan dan kepercayaan anda.",
      keyword11: "Maklum balas segera",
      txvuGsiXQmwiQcum_9b9j:
        'Pada masa ini, terdapat beberapa kedai premium PikPak yang disebut "premium" yang tidak diberi kuasa oleh PikPak. Mereka menjual keahlian premium PikPak jangka pendek atau akaun di beberapa platform e-dagang. Berdasarkan analisis kami, keahlian premium yang mereka jual diperoleh dengan menghasilkan sejumlah besar undangan yang tidak sah, yang melanggar perjanjian pengguna kami dan peraturan program ganjaran rujukan kami. Perilaku ini adalah haram, dan PikPak berhak untuk mengambil tindakan undang-undang lanjut.',
      xn5tjTpa7Dnb3x6zQnEuI:
        "Kami menyeru semua pengguna PikPak agar tidak membeli keahlian premium atau akaun yang dijual secara haram. Mengikut perjanjian pengguna, ini boleh mengakibatkan konsekuensi serius, termasuk pencabutan keahlian premium atau bahkan penangguhan akaun atau peranti. Sekiranya anda menjumpai keahlian premium yang dijual di luar aplikasi rasmi PikPak atau laman web rasmi yang memenuhi mana-mana kriteria berikut, sila jangan membelinya dalam apa jua keadaan:",
      rFvjhdvkbsxAimIoRjKlu:
        "1. Keahlian premium tidak disediakan melalui kod penebusan keahlian premium.",
      xYycBoWmjuoeo10SIil1U:
        "2.Keahlian premium dijual untuk tempoh kurang dari sebulan.",
      a2PqBsUNbentR6QsFy9wR:
        '3.Anda dikehendaki menyediakan kod jemputan atau pautan jemputan untuk pengisian semula keahlian premium yang disebut "premium."',
      gG3kEPdGiqMlYv8F0:
        "4.Akaun dijual dengan keahlian premium jangka pendek.",
      "4qXpPhcHNikXyKOxQjGe":
        "Cara terbaik untuk membeli keahlian premium adalah terus melalui aplikasi rasmi PikPak atau laman web rasmi. Jika anda menghadapi masalah atau kesulitan semasa pembelian, sila hubungi kami melalui <a href='https://mypikpak.com/drive/feedback'>Maklum Balas Pengguna</a>, dan kami akan membantu anda.",
      hWlVVzI96oJs0v9u_2xKx: "Nota tentang Pembelian Luaran",
    },
  },
  ar: {
    notices: {
      "error-description-title": "هام - يرجى ترقية التطبيق",
      "error-description-one":
        "أنا آسف للغاية لأن مقدمي خدمات الدفع في Pikpak واجهوا مشكلة مفاجئة، ولا يمكنك في الوقت الحاضر إكمال شراء الأعضاء في برنامج Android APK و Web و سطح المكتب.",
      "error-description-two":
        "وللتقليل من التأثير على استخدامك، تم ملء حسابك الحالي بالأعضاء لمدة 7 أيام مجانا. بالإضافة إلى ذلك يمكنك العثور على طرق شراء أخرى من خلال مجموعة Telegram الرسمية.",
      "error-description-three":
        "وسنبذل قصارى جهدنا لحل مشكلة دفع الأعضاء في أقرب وقت ممكن ومواصلة تحسين نوعية الخدمات، ونشكركم على دعمكم.",
      "error-description-button": "إلى المجموعة الرسمية",
      "team-name": "فريق بيكباك",
      nqu0Lz8AXfIwhzkjfTx2t:
        "نظرًا لأنه لا يزال يتم حل مشكلة دفع الأقساط ، فقد قررنا منحك 5 أيام أخرى من القسط مجانًا.",
      yrIer2T5zwR5eRzeB6Nq: "اذهب للاعدادات",
      "update-guide-title": "استكشاف أخطاء ترقية التطبيق وإصلاحها",
      "update-step":
        "إذا كنت غير قادر على الترقية ، يرجى اتباع الخطوات التالية:",
      "update-step1":
        '1. انتقل إلى صفحة "الإعداد" ، وانقر فوق "مسح ذاكرة التخزين المؤقت" وقم بالتأكيد.',
      "update-step2":
        '2. انتقل إلى صفحة "حول" في PikPak ​​، انقر فوق "اعثر على رؤية جديدة ، قم بالتحديث الآن"',
      "update-step3":
        '3. انقر فوق الزر "تحديث الآن" في النافذة المنبثقة وانتظر تنزيل الإصدار الجديد.',
      "update-other-tip":
        "إذا استمرت مشكلة التحديث ، فيرجى {0} لتنزيل أحدث إصدار.",
      "goto-official-site": "الانتقال إلى الموقع الرسمي",
      "7CwXq8XgzXgCg7PkSOs": "عزيزي مستخدم PikPak:",
      dilFtvpn0PyFqax7DwO9z:
        "في الآونة الأخيرة ، نظرًا لبعض المشاكل مع مزودي خدمة الدفع في PikPak ، تم حظر خدمة الدفع الخاصة بـ PikPak ، مما تسبب في عدم قدرة المستخدمين على شراء PikPak Premium بشكل طبيعي. بعد أسبوعين من العمل الشاق من قبل مهندسي البحث والتطوير ، تم إصلاح مشكلة الدفع.",
      rsPSpOzTZvz5f9mTGoH2:
        "منذ إطلاقه في عام 2021 ، أحرز PikPak تقدمًا مستمرًا. لقد التزمنا بتوفير أقصى درجات تجربة المستخدم ، والتي لا يمكن فصلها عن دعمك ورفقتك. لذلك في الأيام السبعة المقبلة ، ستكون مؤهلاً لشراء PikPak Premium بسعر منخفض للغاية ، يمكنك النقر فوق الزر أدناه لشرائه. نأمل أن تنال إعجابكم.",
      cDfNYtp6x1NxmcoVEsH3a: "فريق PikPak",
      xByxGiGmIgRoejWugEmPz: "اعتذر بصدق",
      epCovLaH2344wDdmyzen7: "احصل على سعر مميز",
      nyL2eXpIls3SKbeV5wWc: "التعويض الرسمي",
      compensation1:
        "في الآونة الأخيرة ، بسبب مشاكل غير متوقعة مع مزود خدمة الدفع ، تم إلغاء خطة اشتراك PikPak Premium التي اشتريتها من خلال Stripe. على الرغم من أن بعض قنوات الدفع (مثل بطاقات الائتمان و Alipay وما إلى ذلك) قد تُظهر أن حالة الاشتراك لا تزال سارية ، فإن PikPak اللاحقة لن تستمر في إصدار فاتورة لك مقابل خطة اشتراكك. يخضع وقت انتهاء الصلاحية الفعلي لعضويتك التي اشتركت فيها سابقًا للمعلومات الموجودة في PikPak.",
      compensation2:
        "نعتذر عن الإزعاج الذي سببته لك ، لذلك في الأيام السبعة المقبلة ، ستكون مؤهلاً لشراء PikPak Premium بسعر منخفض للغاية ، يمكنك النقر فوق الزر أدناه لشرائه. نأمل أن تنال إعجابكم.",
      "4DjJjMxsc2wYsO0X7Zb6":
        "لاحظنا أن إصدار تطبيق PikPak الخاص بك قديم، يرجى الترقية على الفور. يحل التطبيق الجديد مشكلات الدفع وغيرها من المشكلات، ويشمل العديد من التحسينات الهامة.",
      a0bnHthk6JcLafodUtzb: "يمكنك ترقية التطبيق بأي من الطرق التالية:",
      bVIsA7nv5pB0ShXPaCa34:
        "(موصى به) ترقية التطبيق من خلال 'الإعدادات' - 'حول PikPak' - 'فحص التحديثات' على <a href='pikpakapp://mypikpak.com/xpan/setting'>الرابط</a>.",
      zaMfDDd5WwCnLnHskDtK5:
        "تحميل وتثبيت نسخة جديدة من التطبيق مباشرة من الرابط <a href='pikpakapp://mypikpak.com/app/action_view?msg={0}'>هنا</a> باستخدام المتصفح.",
      qk0fcFJv6iSRsc9K75YFj:
        "تحميل التطبيق من <a href='pikpakapp://mypikpak.com/app/action_view?msg=https%3A%2F%2Fmypikpak.net%2Fmobile-app'>الموقع الرسمي</a>.",
      qc6hRy8u13nuzMmoiVjBo:
        "إذا واجهتك أي مشكلات أثناء تثبيت التطبيق، يرجى الاطلاع على <a href='pikpakapp://mypikpak.com/app/action_view?msg=https%3A%2F%2Fmypikpak.net%2Finstall-apk-troubleshooting'>هذه الصفحة</a>.",
      pn6VR3ZYhAmlNfP5Bpm: "عزيزي مستخدمي PikPak ،",
      nk0yiYpwwTv0jcZNwLDfj: "شكرا لكم على دعمكم المستمر وثقتكم في PikPak!",
      trDgv47AxPaO7k1RlC9Z:
        "في الآونة الأخيرة ، تعرضت خوادمنا لهجمات DDoS خبيثة متكررة ، مما تسبب في عدم استقرار خدمتنا. قد تكون قد واجهت مشاكل مثل عدم عمل الوظائف بشكل صحيح ، أو عدم تحويلك إلى مستخدم متميز في الوقت المناسب بعد الدفع ، إلخ. هذا تسبب لك في إزعاج ومشاكل كبيرة ، ونحن نعتذر بصدق عن ذلك!",
      wccwJmmoGfq2NJqEj4u5c:
        "فريقنا التقني بذل قصارى جهده للتعامل مع هذه المشكلة واستعادة العمل الطبيعي للخدمة في أقصر وقت ممكن. ومع ذلك ، ندرك أيضًا أن هذه الهجمات قد تحدث مرة أخرى ، لذلك نحن مستعدون للتعامل مع هجمات محتملة في المستقبل لضمان تجربة المستخدم الخاصة بك.",
      tYa3UAvyR3Zfcf7mc9OiY:
        "لتعويضك عن وقتك المتميز المفقود ، أطلقنا بشكل خاص رمز استرداد مجاني لمدة يومين [SorryGift] للمستخدمين المتميزين. طالما أنك مستخدم متميز حالي ، يمكنك استخدام هذا الرمز للحصول على تعويض لمدة يومين. يرجى استخدام هذا الرمز خلال 6 أيام ، وإلا سينتهي صلاحيته.",
      rxbeWbrLDsocsSOk0o7I8:
        "شكرًا لك مرة أخرى على فهمك ودعمك لـ PikPak! سنواصل العمل بجد لتزويدك بخدمة أفضل!",
      "27TqFsjz0MwfQr6Zb1E": "إعلان التعويض المميز",
      z_9s8BdnVms0Rnwdm5Bl: "إشعار المكافآت المقيدة",
      lWuWbVc3XlRm7sXtQpXg0:
        "نعلمك للأسف أنه بعد التحليل الدقيق ، وجدنا بعض المشاكل في مكافآت الدعوة التي تلقيتها للمشاركة في برنامج مكافآت الإحالة.",
      hqxg7DiHdmhMk2aSUiW1I:
        "جهازك أو حسابك مرتبط بعدة حسابات PikPak مشبوهة غير طبيعية أو تم إنشاؤها بواسطة برنامج ، مما ينتهك اتفاقية مستخدم PikPak وقواعد برنامج مكافأة الإحالة. لذلك ، تم سحب العضوية المميزة المجانية التي حصلت عليها لدعوة مكافآت إضافية ، ولن تتمكن من الحصول على هذا النوع من العضوية المميزة المجانية.",
      thmWBcCZbFh1F6B8gNX:
        "يمكنك الاستمرار في تلقي مكافآت الشراء من الأصدقاء المدعوين ، ولن تتأثر عملية شرائك لـ PikPak premium.",
      hRwlmNGn0Ew2nel96Z8Rv:
        "يرجى تفهم أنه فقط من خلال التخلص من مكافآت الدعوات غير الصالحة ، يمكن لبرنامج مكافآت الإحالة الاستمرار والاستمرار في تزويد المستخدمين بخدمات عالية الجودة.",
      "1XoDe4JhZrS80zSrXCt2":
        "إذا كنت تعتقد أن هذا قد يكون خطأً ، فاجمع أدلة على المستخدمين المدعوين بشكل صحيح وأرسل تعليقات المستخدم على صفحة الإعدادات لطلب إعادة الوضع إلى سابق عهده.",
      qgZpdxaAgz97QimA3oI9N: "عزيزي معجبو PikPak ،",
      i3BtgbbvyaHsNuY2NxRok:
        "منذ إطلاق PikPak في عام 2021 ، نمت قاعدة مستخدمي PikPak إلى أكثر من 5 ملايين ، وهي لحظة تستحق الاحتفال. لشكر مشتركينا الأوائل على دعمهم ، قررنا أن نقدم لك خصومات حصرية. يمكنك الآن شراء عضوية PikPak بسعر منخفض للغاية. نأمل أن تستمتع به.",
      xo14l1O8Er62jqav0ryWh:
        "إن PikPak يتحسن باستمرار ويلتزم بتوفير تجربة المستخدم النهائية. شكرا لدعمكم خلال هذا الوقت.",
      nd2l5Z72H9yM3G7JiMnMs: "أطيب التمنيات،",
      "9MrBvYso9NBJtk3xeOt": "فريق PikPak",
      iVanoxY7ta8Jwo8cFhOm: "عروض PikPak Fan الحصرية",
      oPmxxLqmqyZoxpvThkQr: "تحذير دعوة غير صالحة",
      c3TwRy4eaDZDRe1aVq:
        "نأسف لإبلاغكم بأنه بعد تحليل دقيق، تم التعرف على مشاكل في مكافآت الإحالة التي تلقيتها للمشاركة في برنامج مكافأة الإحالة.",
      "6is28jJXzGIp3do8DRm7I":
        "جهازك أو حسابك مرتبط بعدة حسابات PikPak مشبوهة ، ربما تم إنشاؤها بواسطة برنامج. هذا السلوك ينتهك اتفاقية مستخدم PikPak وقواعد برنامج مكافأة الإحالة. نحثك على وقف مثل هذه الأنشطة الدعوية غير الصالحة ، حيث أن عدم القيام بذلك سيؤدي إلى إيقاف هذه العضويات المجانية.",
      sHrdY2G_7y5rwCSghdLzm:
        "إذا كنت قد اكتسبت عضويات مكافأة من خلال قنوات غير رسمية أو قد قمت بشراء أو مشاركة حسابات في وقت سابق، فقد يساهم ذلك في الوضع الحالي. هذه الأفعال محفوفة بالمخاطر وقد تؤدي إلى تعليق الحساب أو الجهاز. الرجاء التوقف على الفور.",
      dNihJ0nwL4SfNs0Eo9Jmz:
        "إذا كنت ترغب في مواصلة المشاركة في برنامج مكافأة الإحالة، يرجى دعوة أصدقائك الحقيقيين باستخدام الطرق المبينة في البرنامج. بهذه الطريقة، يمكنك متابعة استقبال مكافآت الإحالة.",
      vG2m3U7PmnZOaX6bHgt:
        "يرجى فهم أن القضاء على مكافآت الإحالة غير الصالحة أمر أساسي لاستدامة برنامج مكافأة الإحالة ولضمان تقديم خدمة عالية الجودة مستمرة لمستخدمينا.",
      aLWys2StUquUwTrNlCk: "فريق PikPak",
      keyword1: "تم إصلاح تعارض الحساب",
      keyword2:
        "بسبب مشاكل في الإصدارات السابقة، أنشأ رقم هاتفك المحمول حسابين مختلفين في PikPak. لتجنب الالتباس، قمنا بتعيين الحساب الحالي باعتباره الحساب الوحيد الذي يمكن تسجيل الدخول إليه باستخدام رقم هاتفك المحمول.",
      keyword3: "يتعلم أكثر",
      keyword4:
        "نظرًا لأن بعض الإصدارات القديمة من PikPak لم توحد تنسيق رقم الهاتف المحمول، فيمكنك التسجيل وإنشاء حسابات مختلفة عند تسجيل الدخول إلى PikPak بنفس رقم الهاتف المحمول.",
      keyword5:
        "هذا الوضع سوف يسبب الارتباك. على سبيل المثال، إذا قمت بتغيير تنسيق إدخال رقم هاتفك المحمول قليلاً عند تسجيل الدخول إلى PikPak، فقد تجد أن المحتوى الموجود في حسابك الأصلي قد اختفى. في الواقع، هذا لأنك قمت بتسجيل الدخول إلى حساب جديد.",
      keyword6:
        "نعتذر مرة أخرى وشكرا لدعمكم وثقتكم. لقد أصلحنا هذه المشكلة وقمنا أيضًا بفحص جميع الحسابات ومعالجتها. الآن سيتم تسجيل دخول رقم هاتفك المحمول إلى الحساب الحالي فقط بغض النظر عن التنسيق الذي تدخله.",
      keyword7:
        "كما أن محتوى الحساب الآخر المطابق لرقم هاتفك المحمول آمن تمامًا أيضًا. إذا كان لدى حساب آخر وقت عضوية متاح، فسيتم إضافته تلقائيًا إلى الحساب الحالي.",
      keyword8:
        "إذا كنت ترغب في تسجيل الدخول إلى حساب آخر بدلاً من حسابك الحالي في المستقبل، فيرجى إرسال التعليقات وسيتمكن فريق دعم المستخدم لدينا من تبديل الحسابات نيابةً عنك.",
      keyword9:
        "إذا كنت تعتقد أنه من المناسب استخدام حسابك الجاري ، فلن تحتاج إلى اتخاذ أي إجراء.",
      keyword10: "نعتذر مرة أخرى وشكرا لدعمكم وثقتكم.",
      keyword11: "ردود الفعل على الفور",
      txvuGsiXQmwiQcum_9b9j:
        'حاليًا، هناك بعض متاجر PikPak المميزة التي تسمى بالـ "بريميوم" والتي ليست مصرحًا بها من قبل PikPak. هم يقومون ببيع عضويات PikPak البريميوم لفترة قصيرة أو حسابات على بعض منصات التجارة الإلكترونية. استنادًا إلى تحليلنا، فإن هذه العضويات البريميوم التي يتم بيعها تم الحصول عليها من خلال إنشاء عدد كبير من الدعوات غير الصالحة، مما ينتهك اتفاقية المستخدم لدينا وقواعد برنامج المكافآت للإحالة. هذا السلوك غير قانوني، وتحتفظ PikPak بحق اتخاذ إجراءات قانونية إضافية.',
      xn5tjTpa7Dnb3x6zQnEuI:
        "نحث جميع مستخدمي PikPak على عدم شراء العضويات البريميوم أو الحسابات التي يتم بيعها بشكل غير قانوني. ووفقًا لاتفاقية المستخدم، يمكن أن يؤدي ذلك إلى عواقب خطيرة، بما في ذلك إلغاء العضويات البريميوم أو حتى تعليق الحسابات أو الأجهزة. إذا واجهت عضويات بريميوم يتم بيعها خارج تطبيق PikPak الرسمي أو الموقع الرسمي وتستوفي أيًا من المعايير التالية، فالرجاء عدم شرائها تحت أي ظرف:",
      rFvjhdvkbsxAimIoRjKlu:
        "العضويات البريميوم غير متوفرة عبر رموز استرداد العضويات البريميوم.",
      xYycBoWmjuoeo10SIil1U: "تباع العضويات البريميوم لمدة أقل من شهر واحد.",
      a2PqBsUNbentR6QsFy9wR:
        "يُطلب منك تقديم رمز دعوة أو رابط دعوة لإعادة شحن العضوية البريميوم المُسماة.",
      gG3kEPdGiqMlYv8F0: "تُباع الحسابات مع عضويات بريميوم قصيرة الأجل.",
      "4qXpPhcHNikXyKOxQjGe":
        "أفضل طريقة لشراء عضوية بريميوم هي مباشرة من خلال تطبيق PikPak الرسمي أو الموقع الرسمي. إذا واجهت أي مشكلة أو إزعاج أثناء الشراء، فلا تتردد في الاتصال بنا من خلال <a href='https://mypikpak.com/drive/feedback'>ملاحظات العملاء</a> وسنقدم لك المساعدة.",
      hWlVVzI96oJs0v9u_2xKx: "ملاحظات حول الشراء الخارجي",
    },
  },
  ko: {
    notices: {
      "error-description-title": "중요 - 앱을 업그레이드해주세요.",
      "error-description-one":
        " 회원님, 죄송합니다. 팍팍 결제 서비스 제공 업체에 갑작스러운 문제가 발생했습니다. 현재 Android Apk, 웹 및 클라이언트에서는 프리미엄을 구매할 수 없습니다.",
      "error-description-two":
        " 회원님께 이용 불편점므로 현재 사용하고 있는 팍팍 계정에 7일 무료 프리미엄을 증정해 드리겠습니다. 게다가 저희 팍팍 공식 텔레그램 그룹을 통해 구매하는 다른 방법도 찾을 수 있습니다",
      "error-description-three":
        " 저희는 결제 문제를 조속히 해결하여, 여러분께 더 좋은 서비스를 제공하여 노력하겠습니다. 많은 지원 부탁드립니다.",
      "error-description-button": "공식  채팅방에 이동",
      "team-name": "픽팍 드림",
      nqu0Lz8AXfIwhzkjfTx2t:
        "결제 및 기타 문제가 해결되었습니다. 설정 - PikPak 정보 - 업데이트 확인에서 업그레이드하세요.",
      yrIer2T5zwR5eRzeB6Nq: "설정으로 이동",
      "update-guide-title": "앱 업그레이드 문제 해결",
      "update-step":
        "업그레이드할 수 없는 상황이 발생한 경우 다음 단계를 따라 진행해주세요:",
      "update-step1":
        '1.설정 페이지로 이동하여 "캐시 삭제" 버튼을 클릭하고 확인을 눌러주세요.',
      "update-step2":
        '2.픽팍 정보 페이지로 이동하여 "새 버전 발견, 지금 업데이트" 버튼을 클릭하세요.',
      "update-step3":
        '3.팝업 창에서 "바로 업데이트" 버튼을 클릭하고, 새 버전이 다운로드 될 때까지 기다려주세요.',
      "update-other-tip":
        "문제가 여전히 해결되지 않는다면: {0} 최신 버전을 다운로드한 후 설치하세요.",
      "goto-official-site": "홈 페이지에서",
      "7CwXq8XgzXgCg7PkSOs": "존경하는 픽팍 회원 여러분:",
      dilFtvpn0PyFqax7DwO9z:
        "최근 일부 예상치 못한 상황으로 인해 픽팍의 결제 서비스가 중단되어 사용자들이 픽팍 프리미엄을 정상적으로 구매할 수 없었습니다. 두 주간의 연구 개발 노력 끝에 결제 문제를 해결하였습니다.",
      rsPSpOzTZvz5f9mTGoH2:
        "2021년 출시 이후, 픽팍은 항상 발전하고 있으며 최고의 사용자 경험을 제공하기 위해 노력하고 있습니다. 그 노력은 여러분의 사랑과 응원 덕분에 이루어질 수 있습니다 . 그래서 앞으로 일주일간은 픽팍 회원을 빅특가로 구매하실 수 있습니다. 구매하시려면 아래 버튼을 클릭해 주세요. 즐거운 사용되시길 바랍니다. ",
      cDfNYtp6x1NxmcoVEsH3a: "픽팍 팀 드림.",
      xByxGiGmIgRoejWugEmPz: "진심으로 사과드립니다.",
      epCovLaH2344wDdmyzen7: "특권 가격을 얻으십시오",
      nyL2eXpIls3SKbeV5wWc: "공식 빅특가",
      compensation1:
        "최근에 지불 서비스 공급자의 급작스러운 문제로 인해 이전에 Stripe를 통해 결제한 픽팍 프리미엄이 취소되었습니다. 신용카드, 알리페이 등 일부 결제 채널에서는 여전히 결제 상태가 유효하게 나타날 수 있지만, 이후로 팍팍은 구독 요금을 청구하지 않을 것입니다. 이전에 결제한 프리미엄 실제 만료일은 픽팍 내의 표시 정보를 기준으로 합니다.",
      compensation2:
        "이에 대해 불편을 드려 대단히 죄송합니다. 이번 주 내에는 팍팍 프리미엄을 초저가로 구매할 수 있습니다. 아래 버튼을 클릭하여 구매 페이지로 이동하시기 바랍니다. 마음에 드시길 바랍니다",
      "4DjJjMxsc2wYsO0X7Zb6":
        "귀하의 픽팍 앱 버전은 구식입니다, 어서 업그레이드하십시오.새로운 버전의 앱은 결제 등의 문제를 해결하고 여러 가지 중요한 최적화를 포함합니다.",
      a0bnHthk6JcLafodUtzb:
        "다음 중 하나의 방법으로 앱을 업그레이드할 수 있습니다.",
      bVIsA7nv5pB0ShXPaCa34:
        "1. (권장) <a href='pikpakapp://mypikpak.com/xpan/setting'>에서 </a> - PikPak에 대하여 - 업데이트 및 업그레이드를 확인합니다.",
      zaMfDDd5WwCnLnHskDtK5:
        "2. 브라우저를 통해 <a href='pikpakapp://mypikpak.com/app/action_view?msg={0}'> 새로운 버전의 APK</a>를 직접 다운로드하여 설치합니다.",
      qk0fcFJv6iSRsc9K75YFj:
        "3. <a href='pikpakapp://mypikpak.com/app/action_view?msg=https%3A%2F%2Fmypikpak.net%2Fmobile-app'>홈페이지 </a>로 이동하여 앱 설치.",
      qc6hRy8u13nuzMmoiVjBo:
        "만약 당신이 앱 설치 중에 문제가 있다면, <a href='pikpakapp://mypikpak.com/app/action_view?msg=https%3A%2F%2Fmypikpak.net%2Finstall-apk-troubleshooting'>이 페이지를 확인하십시오</a>.",
      pn6VR3ZYhAmlNfP5Bpm: "존경하는 픽팍 사용자님, 안녕하세요!",
      nk0yiYpwwTv0jcZNwLDfj: "항상 픽팍에 대한 지지와 신뢰에 감사드립니다!",
      trDgv47AxPaO7k1RlC9Z:
        "최근 우리 서버가 악의적인 DDoS 공격을 여러 차례 받아 서비스가 불안정한 상황이 발생했습니다. 기능을 정상적으로 사용할 수 없거나 결제 후 즉시 회원이 되지 못하는 등의 문제에 직면했을 수 있습니다. 이로 인해 큰 불편과 고통을 겪으셨을 것이라 생각되며, 이에 대해 깊은 사과의 말씀을 드립니다!",
      wccwJmmoGfq2NJqEj4u5c:
        "우리 기술 팀은 최선을 다해 이 문제를 처리했으며, 가능한 한 빠른 시간 내에 서비스의 정상 운영을 회복했습니다. 동시에, 이러한 공격이 다시 발생할 가능성이 있음을 인식하고 있으므로, 앞으로 발생할 수 있는 공격에 대비할 준비를 마쳤습니다. 사용자님의 사용 체험을 보장하기 위해 최선을 다하겠습니다.",
      tYa3UAvyR3Zfcf7mc9OiY:
        "프리미엄 시간 손실을 보상하기 위해,사용자를 위해 2일 무료 회원 교환 코드【SorryGift】를 특별히 제공합니다. 현재 픽팍의 사용자라면 해당 프리미엄 교환 코드를 사용하여 2일 프리미엄 시간 보상을 받을 수 있습니다. 6일 내에 해당 프리미엄교환 코드를 사용하시기 바랍니다. 기간 만료 시 무효화됩니다.",
      rxbeWbrLDsocsSOk0o7I8:
        "픽팍에 대한 이해와 지지에 다시 한번 감사드립니다! 우리는 더 나은 서비스를 제공하기 위해 계속 노력하겠습니다!",
      "27TqFsjz0MwfQr6Zb1E": "프리미엄 보상 공고",
      z_9s8BdnVms0Rnwdm5Bl: "인센티브 제한 알림",
      lWuWbVc3XlRm7sXtQpXg0:
        "안타깝게 저희는 세심한 분석 후, 귀하가 참여한 추천인 인센티브 이벤트를 통해 받은 초대 인센티브에 몇 가지 문제가 있다는 것을 발견했습니다.",
      hqxg7DiHdmhMk2aSUiW1I:
        "귀하의 장치나 계정은여러 의심스러운 또는 특정 프로그램에 의해 생성된 픽팍 계정과 관련이 있습니다. 이러한 행위는 픽팍의 사용자 계약 및 추천 보상 계획 규칙을 위반합니다. 따라서 귀하가 받은 초대 추가 인센티브 무료 프리미엄이 회수되었으며, 향후 이러한 무료 프리미엄을 다시 받을 수 없습니다.",
      thmWBcCZbFh1F6B8gNX:
        "귀하는 초대받은 친구의 구매 인센티브를 계속받을 수 있으며, 픽팍 프리미엄 구매도 영향을받지 않습니다.",
      hRwlmNGn0Ew2nel96Z8Rv:
        "이해해 주시기 바랍니다. 유효하지 않은 초대 인센티브를 제거하는 것만이 추천 인센티브 계획이 지속될 수 있으며, 사용자에게 우수한 서비스를 계속 제공할 수 있습니다.",
      "1XoDe4JhZrS80zSrXCt2":
        "이 중에 오류가 있다고 생각되면, 유효한 초대 사용자의 실증을 수집하고 설정 페이지에서 사용자 피드백을 제출하여 복구를 요청하십시오.",
      qgZpdxaAgz97QimA3oI9N: "사랑스러운 픽팍 팬 여러분,",
      i3BtgbbvyaHsNuY2NxRok:
        "2021년 출시 이후 지금까지 픽팍의 사용자은 500만 명 이상으로 성장했으며 이는 축하할 만한 순간입니다. 초기 구독자들의 지원에 대한 감사의 의미로 전용 팬 할인을 제공하기로 결정했습니다. 이제 매우 저렴한 가격으로 픽팍 프리미엄을 구매할 수 있습니다. 여러분이 혜택을 즐길 수 있기를 바랍니다.",
      xo14l1O8Er62jqav0ryWh:
        "픽팍은 사용자에게 만족스러운 체험을제공하기 위해 끊임없이 개선하고 노력하고 있습니다.여러분의 지원과 따뜻한 배려 감사드립니다.",
      nd2l5Z72H9yM3G7JiMnMs: "PikPak 팀",
      "9MrBvYso9NBJtk3xeOt": "최고의 소원",
      iVanoxY7ta8Jwo8cFhOm: "픽팍 팬들이 전용 혜택",
      oPmxxLqmqyZoxpvThkQr: "유효하지 않은 초대 경고",
      c3TwRy4eaDZDRe1aVq:
        "안타깝게 저희는 세심한 분석 후, 귀하가 참여한 추천인 인센티브 이벤트를 통해 받은 초대 인센티브에 몇 가지 문제가 있다는 것을 발견했습니다.",
      "6is28jJXzGIp3do8DRm7I":
        "귀하의 장치나 계정은 여러 의심스러운 또는 특정 프로그램에 의해 생성된 픽팍 계정과 관련이 있습니다. 이러한 행위는 픽팍의 사용자 계약 및 추천 보상 계획 규칙을 위반합니다.따라서 귀하께서는 이러한 유효하지 않은 초대 행위를 중단해 주시기 바랍니다. 그렇지 않으면 더 이상 이런 무료 프리미엄을 받을 수 없게 됩니다.",
      sHrdY2G_7y5rwCSghdLzm:
        "귀하께서는 공식적이지 않은 채널을 통해 프리미엄 인센티브를 구매하셨거나, 또는 계정을 구매하거나 공유하셨던 적이 있다면, 지금의 상황이 발생할 수 있습니다. 위와 같은 행위는 매우 위험한 것으로, 계정이나 장치가 차단될 수 있으므로 즉시 중단해 주시기 바랍니다.",
      dNihJ0nwL4SfNs0Eo9Jmz:
        "귀하께서는 추천인 인센티브 이벤트에 계속 참여하고 싶으시다면, 이벤트에서 제시한 몇 가지 방법을 통해 실제 친구를 초대해 주시기 바랍니다. 그러면 귀하께서는 계속해서 초대 인센티브를 받으실 수 있습니다.",
      vG2m3U7PmnZOaX6bHgt:
        "귀하께서는 유효하지 않은 초대 인센티브를 제거함으로써, 추천인 인센티브 이벤트 지속될 수 있고, 사용자들에게 우수한 서비스를 제공할 수 있음을 이해해 주시기 바랍니다.",
      aLWys2StUquUwTrNlCk: "픽팍 팀",
      keyword1: "계정 충돌 수정되었습니다.",
      keyword2:
        "이전 버전의 문제로 픽팍에서 여러분의 휴대전화 번호로 두 개의 다른 계정이 생성되었습니다. 혼란을 피하기 위해 현재 계정을 여러분의 휴대전화 번호로만 로그인할 수 있는 유일한 계정으로 설정했습니다.",
      keyword3: "자세히 알아보기",
      keyword4:
        "픽팍의 일부 초기 버전에서 휴대전화 번호 형식을 표준화하지 않았기 때문에, 동일한 휴대전화 번호로 픽팍에 로그인하면 다른 계정이 등록될 수 있습니다.",
      keyword5:
        "이러한 상황은 혼란을 초래할 수 있습니다. 예를 들어, 픽팍에 로그인할 때 휴대전화 번호 입력 형식을 약간 변경하면 기존 계정의 내용이 사라진 것처럼 보일 수 있습니다. 실제로는 새 계정으로 로그인한 것입니다.",
      keyword6:
        "이로 인해 불편을 끼쳐드려 죄송합니다. 이 문제를 해결하고 모든 계정을 점검하고 처리했습니다. 이제 여러분은 어떤 형식으로든 휴대전화 번호를 입력하더라도 현재 계정에만 로그인됩니다.",
      keyword7:
        "여러분의 휴대전화 번호에 해당하는 다른 계정의 내용도 완전히 안전합니다. 다른 계정에 사용 가능한 프리미엄 멤버십 기간이 있다면 해당 기간이 현재 계정에 자동으로 추가되었습니다.",
      keyword8:
        "현재 계정이 아닌 다른 계정으로 로그인하려면 피드백을 제출하십시오. 저희 고객 지원 팀이 여러분을 도와 계정을 전환해 드릴 것입니다.",
      keyword9:
        "현재 계정 사용이 적절하다고 생각하신다면 추가 조치가 필요하지 않습니다.",
      keyword10:
        "다시 한 번 불편을 끼쳐드려 죄송하며 여러분의 지원과 신뢰에 감사드립니다.",
      keyword11: "바로 피드백 제출",
      txvuGsiXQmwiQcum_9b9j:
        "현재 픽팍의 승인을 받지 않은 소위 픽팍 회원 상점이 몇몇 온라인 홈쇼핑 사이트에서 몇 일 길이의 단기 픽팍 프리미엄이나 계정을 판매하고 있습니다. 우리의 분석에 따르면, 그들이 판매하는 프리미엄은 프로그램을 통해 대량의 무효 초대를 만들어 얻은 것입니다. 이것은 사용자 약관과 리퍼럴 인센티브 프로그램 규칙을 위반합니다.이러한 행위는 불법적이며, 픽팍은 추가 법적 조치를 취할 권리를 보유합니다.",
      xn5tjTpa7Dnb3x6zQnEuI:
        "우리는 모든 픽팍 사용자들이 불법 판매된 프리미엄이나 계정을 구매하지 않기를 촉구합니다. 사용자 약관에 따르면, 이는 심각한 결과를 초래할 수 있습니다 - 프리미엄이 회수되거나, 심지어 계정이나 기기가 차단될 수 있습니다. 픽팍 공식 앱이나 웹사이트 외부에서 프리미엄을 판매하는 경우, 다음의 어느 하나의 특징을 만족하면 불법 판매로 인정할 것입니다. 어떠한 경우에도 구매하지 마십시오:",
      rFvjhdvkbsxAimIoRjKlu:
        "1. 프리미엄 코드를 사용하지 않고 프리미엄을 제공하는 것",
      xYycBoWmjuoeo10SIil1U: "2. 판매하는 프리미엄 기간이 1개월 미만",
      a2PqBsUNbentR6QsFy9wR:
        "3. 프리밍럼 충전을 위해 당신의 초대 코드나 초대 링크를 제공하도록 요구하는 것",
      gG3kEPdGiqMlYv8F0: "4. 단기 프리미엄이 있는 계정을 판매하는 것",
      "4qXpPhcHNikXyKOxQjGe":
        "프리미엄을 구매하는 가장 좋은 방법은 공식 앱이나 웹사이트 내에서 직접 구매하는 것입니다.구매 시 문제나 불편함이 있으시면 언제든지 <a href='https://mypikpak.com/drive/feedback'>[사용자 피드백]</a> 을 통해 저희에게 연락해 주시기 바랍니다. 저희는 당신에게 도움을 드리겠습니다.",
      hWlVVzI96oJs0v9u_2xKx: "외부 구매 주의사항",
    },
  },
  pt: {
    // 葡萄牙语
    notices: {
      nqu0Lz8AXfIwhzkjfTx2t: "IMPORTANTE - ATUALIZE O APLICATIVO",
      "update-guide-title": "Solução de problemas de atualização de aplicativo",
      "update-step":
        "Se você não conseguir atualizar, use as seguintes etapas:",
      "update-step1":
        '1. Vá para a página "Configurações", clique em "Limpar o Cache" e confirme.',
      "update-step2":
        '2.Vá para a página "Sobre" no PikPak, clique em "encontre uma nova visão, atualize agora".',
      "update-step3":
        '3.Clique no botão "Atualizar agora" na janela pop-up e aguarde o download da nova versão.',
      "update-other-tip":
        "Se o problema de atualização persistir, acesse {0} baixar a versão mais recente.",
      "goto-official-site": "o site oficial para",
      compensation1:
        "Recentemente, devido a problemas inesperados com o provedor de serviços de pagamento, o plano de assinatura PikPak Premium adquirido por meio do Stripe foi cancelado. Embora alguns canais de pagamento (como cartões de crédito, Alipay, etc.) possam mostrar que o status da assinatura ainda está em vigor, o PikPak subsequente não continuará cobrando de você pelo seu plano de assinatura. O tempo de expiração real de sua assinatura anteriormente inscrita está sujeito às informações do PikPak.",
      compensation2:
        "Pedimos desculpas pelo transtorno causado a você, então nos próximos 7 dias, você poderá comprar o PikPak Premium com um preço super baixo, você pode clicar no botão abaixo para comprá-lo. Esperamos que você goste.",
      "4DjJjMxsc2wYsO0X7Zb6":
        "A versão do aplicativo PikPak está desatualizada, atualize o mais rápido possível. A nova versão do App resolve problemas como pagamento e contém uma série de otimizações importantes.",
      a0bnHthk6JcLafodUtzb:
        "Você pode atualizar o aplicativo por qualquer um dos seguintes métodos:",
      bVIsA7nv5pB0ShXPaCa34:
        "1. (Recomendado) Atualização em <a href='pikpakapp://mypikpak.com/xpan/setting'>Configurações</a> - Sobre o PikPak - Verificar atualizações.",
      zaMfDDd5WwCnLnHskDtK5:
        "2. <a href='pikpakapp://mypikpak.com/app/action_view?msg={0}'>Faça o download direto da nova versão do APK</a> através do navegador e instale-o.",
      qk0fcFJv6iSRsc9K75YFj:
        "3. <a href='pikpakapp://mypikpak.com/app/action_view?msg=https%3A%2F%2Fmypikpak.net%2Fmobile-app'>Acesse o site oficial</a> para instalar o aplicativo.",
      qc6hRy8u13nuzMmoiVjBo:
        "Se você tiver problemas para instalar o aplicativo, verifique <a href='pikpakapp://mypikpak.com/app/action_view?msg=https%3A%2F%2Fmypikpak.net%2Finstall-apk-troubleshooting'>esta página</a> .",
      pn6VR3ZYhAmlNfP5Bpm: "Caro PikPak usuários,",
      nk0yiYpwwTv0jcZNwLDfj:
        "Obrigado pelo seu apoio contínuo e confiança em PikPak!",
      trDgv47AxPaO7k1RlC9Z:
        "Recentemente, os nossos servidores têm sido repetidamente atacados por ataques DDoS maliciosos, que causaram a nossa instabilidade do serviço. Você pode ter encontrado problemas como funções não funcionando corretamente, não se tornando um usuário premium em tempo após o pagamento, etc. Isso causou-lhe grande inconveniência e problemas, e pedimos sinceras desculpas por isso!",
      wccwJmmoGfq2NJqEj4u5c:
        "A nossa equipe técnica fez o seu melhor para lidar com este problema e restaurou o funcionamento normal do serviço no menor tempo possível. No entanto, também percebemos que tais ataques podem acontecer novamente, por isso nos preparamos para lidar com possíveis ataques futuros para garantir a sua experiência de usuário.",
      tYa3UAvyR3Zfcf7mc9OiY:
        "Para compensar o seu tempo premium perdido, lançamos especialmente um código de resgate gratuito premium de 2 dias [SorryGift] para usuários premium. Contanto que você seja um usuário premium atual, você pode usar este código de resgate para obter 2 dias de compensação de tempo premium. Por favor, use este código de resgate dentro de 6 dias, caso contrário ele expirará.",
      rxbeWbrLDsocsSOk0o7I8:
        "Obrigado novamente pela sua compreensão e apoio ao PikPak! Continuaremos a trabalhar duro para lhe fornecer um melhor serviço!",
      "27TqFsjz0MwfQr6Zb1E": "Anúncio de Compensação Premium",
      z_9s8BdnVms0Rnwdm5Bl: "Aviso de Recompensas Restritas",
      lWuWbVc3XlRm7sXtQpXg0:
        "Lamentamos informar que, após uma análise cuidadosa, encontramos alguns problemas com as recompensas por convite que você recebeu por participar do Programa de Recompensas por Indicação.",
      hqxg7DiHdmhMk2aSUiW1I:
        "Seu dispositivo ou conta está associado a várias contas PikPak suspeitas de serem anormais ou criadas por um programa, que violam o Contrato de Usuário e as Regras do Programa de Recompensa por Indicação da PikPak. Portanto, o prêmio gratuito obtido por convidar recompensas adicionais foi retirado e você não poderá mais obter esse tipo de prêmio gratuito.",
      thmWBcCZbFh1F6B8gNX:
        "Você pode continuar recebendo recompensas de compra de amigos convidados, e sua compra do PikPak premium não será afetada.",
      hRwlmNGn0Ew2nel96Z8Rv:
        "Por favor, entenda que apenas eliminando recompensas por convites inválidos o Programa de Recompensas por Indicação pode continuar e continuar a fornecer aos usuários serviços de alta qualidade.",
      "1XoDe4JhZrS80zSrXCt2":
        "Se você acredita que isso pode ser um engano, reúna evidências de usuários convidados de forma válida e envie comentários do usuário na página de configurações para solicitar o restabelecimento.",
      qgZpdxaAgz97QimA3oI9N: "Queridos fãs do PikPak,",
      i3BtgbbvyaHsNuY2NxRok:
        "Desde o lançamento em 2021, a base de usuários do PikPak cresceu para mais de 5 milhões, um momento que vale a pena comemorar. Para agradecer aos nossos primeiros assinantes pelo apoio, decidimos oferecer descontos exclusivos. Agora você pode adquirir a assinatura do PikPak por um preço super baixo. Esperamos que você aproveite.",
      xo14l1O8Er62jqav0ryWh:
        "O PikPak está constantemente melhorando e comprometido em fornecer a melhor experiência ao usuário. Obrigado por seu apoio durante este tempo.",
      nd2l5Z72H9yM3G7JiMnMs: "Muitas felicidades,",
      "9MrBvYso9NBJtk3xeOt": "Equipe PikPak",
      iVanoxY7ta8Jwo8cFhOm: "Ofertas Exclusivas Para fãs do PikPak",
      oPmxxLqmqyZoxpvThkQr: "Aviso de Convite Inválido",
      c3TwRy4eaDZDRe1aVq:
        "Lamentamos informar que, após uma análise cuidadosa, identificamos problemas com as recompensas de indicação que você recebeu por participar do Programa de Recompensa por Indicação.",
      "6is28jJXzGIp3do8DRm7I":
        "Seu dispositivo ou conta está associado a várias contas PikPak suspeitas, potencialmente criadas por um programa. Esse comportamento viola o acordo de usuário e as regras do Programa de Recompensa por Indicação da PikPak. Pedimos que você cesse essas atividades de convite inválido, pois a falta de ação resultará na interrupção dessas assinaturas gratuitas.",
      sHrdY2G_7y5rwCSghdLzm:
        "Se você adquiriu assinaturas de recompensa por meio de canais não oficiais ou já comprou ou compartilhou contas, isso pode contribuir para a situação atual. Essas ações são altamente arriscadas e podem levar à suspensão da conta ou do dispositivo. Por favor, pare imediatamente.",
      dNihJ0nwL4SfNs0Eo9Jmz:
        "Se desejar continuar participando do Programa de Recompensa por Indicação, convidamos a convidar amigos reais usando os métodos detalhados no programa. Dessa forma, você poderá continuar recebendo recompensas por indicação.",
      vG2m3U7PmnZOaX6bHgt:
        "Entenda que a eliminação das recompensas de indicação inválidas é essencial para a sustentabilidade do Programa de Recompensa por Indicação e para garantir a prestação contínua de um serviço de alta qualidade aos nossos usuários.",
      aLWys2StUquUwTrNlCk: "Equipe PikPak",
      keyword1: "Conflito de conta corrigido",
      keyword2:
        "Devido a problemas com versões anteriores, o número do seu celular gerou duas contas diferentes no PikPak. Para evitar confusão, definimos a conta corrente como a única conta que pode ser logada com o seu número de celular.",
      keyword3: "Saber mais",
      keyword4:
        "Como algumas versões anteriores do PikPak não padronizavam o formato do número do celular, você pode registrar e gerar contas diferentes ao fazer login no PikPak com o mesmo número de celular.",
      keyword5:
        "Esta situação causará confusão. Por exemplo, se você alterar ligeiramente o formato de entrada do seu número de celular ao fazer login no PikPak, poderá descobrir que o conteúdo da sua conta original desapareceu. Na verdade, isso ocorre porque você fez login em uma nova conta.",
      keyword6:
        "Sentimos muito pelo transtorno causado a você. Corrigimos esse problema e também verificamos e processamos todas as contas. Agora o seu número de celular só estará conectado à conta atual, independentemente do formato que você inserir.",
      keyword7:
        "O conteúdo de outra conta correspondente ao seu número de celular também é totalmente seguro. Se outra conta tiver tempo de adesão disponível, ele será automaticamente adicionado à conta atual.",
      keyword8:
        "Se você quiser fazer login em outra conta em vez de sua conta atual no futuro, envie comentários e nossa equipe de suporte ao usuário poderá trocar de conta para você.",
      keyword9:
        "Se você acha apropriado usar sua conta corrente, não precisa realizar nenhuma ação.",
      keyword10:
        "Pedimos desculpas novamente e agradecemos seu apoio e confiança.",
      keyword11: "Feedback imediatamente",
      txvuGsiXQmwiQcum_9b9j:
        "Atualmente, existem algumas lojas chamadas de premium da PikPak que não são autorizadas pela PikPak. Elas estão vendendo associações premium de curto prazo da PikPak ou contas em algumas plataformas de comércio eletrônico. Com base em nossa análise, essas associações premium que estão sendo vendidas são obtidas por meio da geração de um grande número de convites inválidos, o que viola nosso acordo de usuário e as regras do programa de recompensas por indicação. Esse comportamento é ilegal, e a PikPak reserva-se o direito de tomar medidas legais adicionais.",
      xn5tjTpa7Dnb3x6zQnEuI:
        "Instamos todos os usuários da PikPak a não adquirirem associações premium ou contas que estejam sendo vendidas ilegalmente. De acordo com o acordo do usuário, isso pode resultar em consequências graves, incluindo a revogação de associações premium ou até mesmo a suspensão de contas ou dispositivos. Se você se deparar com associações premium sendo vendidas fora do aplicativo oficial da PikPak ou do site oficial que atendam a qualquer um dos seguintes critérios, por favor, não as adquira em nenhuma circunstância:",
      rFvjhdvkbsxAimIoRjKlu:
        "1.Associações premium não são fornecidas por meio de códigos de resgate de associação premium.",
      xYycBoWmjuoeo10SIil1U:
        "2.Associações premium são vendidas por menos de um mês.",
      a2PqBsUNbentR6QsFy9wR:
        "3.Você é obrigado a fornecer um código de convite ou link de convite para recarregar a associação premium autodenominada.",
      gG3kEPdGiqMlYv8F0:
        "4.Contas estão sendo vendidas com associações premium de curto prazo.",
      "4qXpPhcHNikXyKOxQjGe":
        "A melhor maneira de adquirir uma associação premium é diretamente pelo aplicativo oficial da PikPak ou pelo site oficial. Se você encontrar algum problema ou inconveniente durante a compra, sinta-se à vontade para nos contatar por meio do <a href='https://mypikpak.com/drive/feedback'>Feedback do Usuário</a>, e nós o ajudaremos.",
      hWlVVzI96oJs0v9u_2xKx: "Notas sobre Compra Externa",
    },
  },
  es: {
    // 西班牙语
    notices: {
      nqu0Lz8AXfIwhzkjfTx2t: "IMPORTANTE: ACTUALIZA LA APLICACIÓN",
      "update-guide-title":
        "Solución de problemas de actualización de aplicaciones",
      "update-step": "Si no puede actualizar, utilice los siguientes pasos:",
      "update-step1":
        '1. Vaya a la página "Configuración", haga clic en "Borrar caché" y confirme.',
      "update-step2":
        '2. Vaya a la página "Acerca de" en PikPak, haga clic en "encontrar una nueva visión, actualizar ahora".',
      "update-step3":
        '3.Haga clic en el botón "Actualizar ahora" en la ventana emergente y espere a que se descargue la nueva versión.',
      "update-other-tip":
        "Si el problema de actualización persiste, vaya al {0} descargar la última versión.",
      "goto-official-site": "sitio web oficial para",
      compensation1:
        "Recientemente, debido a problemas inesperados con el proveedor de servicios de pago, se canceló el plan de suscripción PikPak Premium que compró a través de Stripe. Aunque algunos canales de pago (como tarjetas de crédito, Alipay, etc.) pueden mostrar que el estado de la suscripción aún está vigente, los PikPak posteriores no seguirán facturando su plan de suscripción. El tiempo de vencimiento real de su membresía previamente suscrita está sujeto a la información en PikPak.",
      compensation2:
        "Pedimos disculpas por las molestias que le hayamos causado, por lo que en los próximos 7 días será elegible para comprar PikPak Premium a un precio súper bajo, puede hacer clic en el botón a continuación para comprarlo. Esperamos que os guste.",
      "4DjJjMxsc2wYsO0X7Zb6":
        "La versión de su aplicación PikPak está desactualizada, actualice lo antes posible. La nueva versión de la aplicación resuelve problemas como el pago y contiene una serie de optimizaciones importantes.",
      a0bnHthk6JcLafodUtzb:
        "Puede actualizar la aplicación mediante cualquiera de los siguientes métodos:",
      bVIsA7nv5pB0ShXPaCa34:
        "1. (Recomendado) Actualizar en <a href='pikpakapp://mypikpak.com/xpan/setting'>Configuración</a> - Acerca de PikPak - Buscar actualizaciones.",
      zaMfDDd5WwCnLnHskDtK5:
        "2. <a href='pikpakapp://mypikpak.com/app/action_view?msg={0}'>Descargue directamente la nueva versión del APK</a> a través del navegador e instálela.",
      qk0fcFJv6iSRsc9K75YFj:
        "3. <a href='pikpakapp://mypikpak.com/app/action_view?msg=https%3A%2F%2Fmypikpak.net%2Fmobile-app'>Vaya al sitio web oficial</a> para instalar la aplicación.",
      qc6hRy8u13nuzMmoiVjBo:
        "Si tiene problemas para instalar la aplicación, consulte <a href='pikpakapp://mypikpak.com/app/action_view?msg=https%3A%2F%2Fmypikpak.net%2Finstall-apk-troubleshooting'>esta página</a> .",
      pn6VR3ZYhAmlNfP5Bpm: "Estimados usuarios de PikPak,",
      nk0yiYpwwTv0jcZNwLDfj:
        "Gracias por su continuo apoyo y confianza en PikPak!",
      trDgv47AxPaO7k1RlC9Z:
        "Recientemente, nuestros servidores han sido atacados repetidamente por ataques maliciosos de DDoS, lo que ha causado que nuestro servicio sea inestable. Es posible que haya encontrado problemas como funciones que no funcionan correctamente, no convertirse en usuario premium a tiempo después del pago, etc. Esto le ha causado una gran inconveniencia y problemas, y le pedimos disculpas sinceramente por ello!",
      wccwJmmoGfq2NJqEj4u5c:
        "Nuestro equipo técnico ha hecho todo lo posible para resolver este problema y restaurar el funcionamiento normal del servicio en el menor tiempo posible. Sin embargo, también nos damos cuenta de que tales ataques pueden volver a ocurrir, así que nos hemos preparado para hacer frente a posibles ataques futuros para garantizar su experiencia de usuario.",
      tYa3UAvyR3Zfcf7mc9OiY:
        "Para compensar su tiempo premium perdido, hemos lanzado especialmente un código de canje gratuito de 2 días premium [SorryGift] para los usuarios premium. Siempre y cuando sea un usuario premium actual, puede utilizar este código de canje para obtener 2 días de compensación de tiempo premium. Por favor, utilice este código de canje dentro de los 6 días, de lo contrario caducará.",
      rxbeWbrLDsocsSOk0o7I8:
        "Gracias de nuevo por su comprensión y apoyo a PikPak! Seguiremos trabajando duro para ofrecerle un mejor servicio!",
      "27TqFsjz0MwfQr6Zb1E": "Anuncio de compensación para miembros",
      z_9s8BdnVms0Rnwdm5Bl: "Aviso de recompensas restringidas",
      lWuWbVc3XlRm7sXtQpXg0:
        "Lamentablemente informarle que después de un análisis cuidadoso, encontramos algunos problemas con las recompensas de invitación que recibió por participar en el Programa de recompensas por recomendación.",
      hqxg7DiHdmhMk2aSUiW1I:
        "Su dispositivo o cuenta está asociado con varias cuentas PikPak sospechosas de ser anormales o creadas por un programa, que violan el Acuerdo de Usuario y las Reglas del Programa de Recompensa por Referencia de PikPak. Por lo tanto, la prima gratuita que obtuvo por invitar a recompensas adicionales se ha retirado y ya no podrá obtener este tipo de prima gratuita.",
      thmWBcCZbFh1F6B8gNX:
        "Puede continuar recibiendo recompensas de compra de amigos invitados, y su compra de PikPak premium no se verá afectada.",
      hRwlmNGn0Ew2nel96Z8Rv:
        "Comprenda que solo al eliminar las recompensas de invitaciones no válidas, el Programa de recompensas por recomendación puede continuar y continuar brindando a los usuarios servicios de alta calidad.",
      "1XoDe4JhZrS80zSrXCt2":
        "Si cree que esto puede ser un error, recopile pruebas de usuarios invitados válidamente y envíe comentarios de los usuarios en la página de configuración para solicitar el restablecimiento.",
      qgZpdxaAgz97QimA3oI9N: "Estimados fans de PikPak,",
      i3BtgbbvyaHsNuY2NxRok:
        "Desde su lanzamiento en 2021, la base de usuarios de PikPak ha crecido a más de 5 millones, un momento que vale la pena celebrar. Para agradecer a nuestros primeros suscriptores por su apoyo, hemos decidido ofrecerle descuentos exclusivos. Ahora puede comprar la membresía de PikPak a un precio súper bajo. Esperamos que lo disfrutes.",
      xo14l1O8Er62jqav0ryWh:
        "PikPak mejora constantemente y se compromete a brindar la mejor experiencia de usuario. Gracias por su apoyo durante este tiempo.",
      nd2l5Z72H9yM3G7JiMnMs: "Los mejores deseos,",
      "9MrBvYso9NBJtk3xeOt": "Equipo PikPak",
      iVanoxY7ta8Jwo8cFhOm: "Ofertas Exclusivas Para Fans de PikPak",
      "oPmxxLqmqyZoxpvThkQr-4EISNDdO0t6dZnC":
        "Advertencia de Invitación Inválida",
      c3TwRy4eaDZDRe1aVq:
        "Lamentamos informarles que, después de un análisis cuidadoso, hemos identificado problemas con las recompensas de referencia que recibió por participar en el Programa de Recompensas por Referidos.",
      "6is28jJXzGIp3do8DRm7I":
        "Su dispositivo o cuenta está asociado con varias cuentas PikPak sospechosas, potencialmente creadas por un programa. Este comportamiento viola el acuerdo de usuario y las reglas del Programa de Recompensas por Referidos de PikPak. Le instamos a que cese estas actividades de invitación no válidas, ya que no hacerlo resultará en la discontinuación de estas membresías gratuitas.",
      sHrdY2G_7y5rwCSghdLzm:
        "Si ha adquirido membresías de recompensa por medio de canales no oficiales o alguna vez ha comprado o compartido cuentas, esto podría contribuir a la situación actual. Estas acciones son muy arriesgadas y podrían resultar en la suspensión de la cuenta o el dispositivo. Por favor, cese de inmediato.",
      dNihJ0nwL4SfNs0Eo9Jmz:
        "Si desea continuar participando en el Programa de Recompensas por Referidos, le invitamos a invitar a amigos reales utilizando los métodos detallados en el programa. De esta manera, podrá seguir recibiendo recompensas por referencia.",
      vG2m3U7PmnZOaX6bHgt:
        "Por favor, comprenda que eliminar las recompensas por referencia no válidas es esencial para la sostenibilidad del Programa de Recompensas por Referidos y para garantizar la provisión continua de un servicio de alta calidad a nuestros usuarios.",
      aLWys2StUquUwTrNlCk: "Equipo PikPak",
      keyword1: "Conflicto de cuenta solucionado",
      keyword2:
        "Debido a problemas con versiones anteriores, tu número de teléfono móvil ha generado dos cuentas diferentes en PikPak. Para evitar confusiones, hemos configurado la cuenta actual como la única cuenta en la que se puede iniciar sesión con su número de teléfono móvil.",
      keyword3: "Aprende más",
      keyword4:
        "Debido a que algunas de las primeras versiones de PikPak no estandarizaron el formato del número de teléfono móvil, puede registrarse y generar cuentas diferentes cuando inicie sesión en PikPak con el mismo número de teléfono móvil.",
      keyword5:
        "Esta situación causará confusión. Por ejemplo, si cambia ligeramente el formato de entrada de su número de teléfono móvil al iniciar sesión en PikPak, es posible que el contenido de su cuenta original desaparezca. De hecho, esto se debe a que ha iniciado sesión en una cuenta nueva.",
      keyword6:
        "Lamentamos mucho las molestias ocasionadas. Hemos solucionado este problema y también hemos verificado y procesado todas las cuentas. Ahora su número de teléfono móvil solo iniciará sesión en la cuenta actual sin importar el formato que ingrese.",
      keyword7:
        "El contenido de otra cuenta correspondiente a tu número de teléfono móvil también es completamente seguro. Si otra cuenta tiene tiempo de membresía disponible, se agregará automáticamente a la cuenta actual.",
      keyword8:
        "Si desea iniciar sesión en otra cuenta en lugar de su cuenta actual en el futuro, envíe sus comentarios y nuestro equipo de atención al usuario podrá cambiar de cuenta por usted.",
      keyword9:
        "Si cree que es apropiado utilizar su cuenta actual, no necesita realizar ninguna acción.",
      keyword10:
        "Nos disculpamos nuevamente y le agradecemos su apoyo y confianza.",
      keyword11: "Comentarios inmediatamente",
      txvuGsiXQmwiQcum_9b9j:
        "Actualmente, existen algunas tiendas premium de PikPak que se autodenominan premium y que no están autorizadas por PikPak. Están vendiendo membresías premium de PikPak a corto plazo o cuentas en algunas plataformas de comercio electrónico. Según nuestro análisis, estas membresías premium que están vendiendo se obtienen generando una gran cantidad de invitaciones inválidas, lo que viola nuestro acuerdo de usuario y las reglas del programa de recompensas por referencias. Este comportamiento es ilegal, y PikPak se reserva el derecho de tomar medidas legales adicionales.",
      xn5tjTpa7Dnb3x6zQnEuI:
        "Instamos a todos los usuarios de PikPak a no comprar membresías premium o cuentas que se vendan de manera ilegal. De acuerdo con el acuerdo de usuario, esto puede llevar a consecuencias graves, incluida la revocación de membresías premium o incluso la suspensión de cuentas o dispositivos. Si se encuentra con membresías premium que se venden fuera de la aplicación oficial de PikPak o el sitio web oficial que cumplan con alguno de los siguientes criterios, por favor, no las compre bajo ninguna circunstancia:",
      rFvjhdvkbsxAimIoRjKlu:
        "1.Las membresías premium no se proporcionan a través de códigos de canje de membresía premium.",
      xYycBoWmjuoeo10SIil1U:
        "2.Las membresías premium se venden por un período de menos de un mes.",
      a2PqBsUNbentR6QsFy9wR:
        "3.Se le pide que proporcione un código de invitación o un enlace de invitación para la recarga de membresía premium autodenominada.",
      gG3kEPdGiqMlYv8F0:
        "4.Se venden cuentas con membresías premium a corto plazo.",
      "4qXpPhcHNikXyKOxQjGe":
        "La mejor manera de comprar una membresía premium es directamente a través de la aplicación oficial de PikPak o el sitio web oficial. Si encuentra algún problema o inconveniente durante la compra, no dude en ponerse en contacto con nosotros a través de la función de <a href='https://mypikpak.com/drive/feedback'>comentarios de los clientes</a> y le ayudaremos.",
      hWlVVzI96oJs0v9u_2xKx: "Notas sobre Compras Externas",
    },
  },
};

export default message;
