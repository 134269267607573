import axios from 'axios';
import config from '../config';
import { getUserInfo } from '../utils/jsBridge';
import { getUserClientInfo } from '../utils/util';

let authorization = '';
let deviceId = '';
// 向客户端获取用户信息
getUserInfo(null, data => {
	data = data !== null && typeof data === 'object' ? data : JSON.parse(data || '{}');
	authorization = `Bearer ${data?.accessToken}`;
	deviceId = data.uuid;
});

axios.defaults.baseURL = config.api.pikpak;

// 兑换码兑换会员
export const tradeCdKey = async (data, headers) => {
	let requestHeaders = { Authorization: authorization, 'x-device-id': deviceId };
	if (headers) {
		requestHeaders = { ...requestHeaders, ...headers };
	}
	return axios.post('/vip/v1/order/activation-code', data, {
		headers: requestHeaders,
	});
}

// 获取资源封面图
export const getThumbnail = async (data, header) => {
	if (data.url) {
		data.url = encodeURIComponent(data.url);
	}
	const query = Object.entries(data)
		.filter(v => ['url', 'hash']
		.includes(v[0]))
		.map(v => `${v[0]}=${v[1]}`)
		.join('&');
	const getURL = `/user/v1/thumbnail?${query}`;
	return axios.get(getURL, { header });
}

// 是否限制当前IP访问
export const isForbiddenIP = async () => {
	return axios.get('/drive/v1/privilege/area_accessible', {
		headers: {
			'X-Client-ID': 'YUMx5nI8ZU8Ap8pm',
		}
	});
}

// 查询会员订阅状态
export const querySubscribeStatus = async (token) => {
	return axios.get('/vip/v1/stripe/subscription', {
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
}

// 取消订阅
export const cancelSubscription = async (subscriptionId, token) => {
	return axios.post('/vip/v1/stripe/cancelSubscription', {
		subscription_id: subscriptionId
	}, {
		headers: {
			Authorization: `Bearer ${token}`
		}	
	});
}

// 生成邀请码
export const getInviteCode = async ({ deviceId, token, language }) => {
	return axios.get('/vip/v1/activity/inviteCode', {
		headers: {
			"Authorization": `Bearer ${token}`,	
			"Accept-Language": language,
			"x-device-id": deviceId,
		}
	});
}

// 获取当用户的邀请详情
export const queryUserInvitationInfo = async ({ token, language }) => {
	return axios.get('/vip/v1/activity/inviteInfo', {
		headers: {
			"Authorization": `Bearer ${token}`,
			"Accept-Language": language,
		}
	});
}

// 分享裂变: 获取邀请者信息
export const getInviterInfo = async ({ inviteCode, language }) => {
	return axios.get(`/vip/v1/activity/inviter?code=${inviteCode}`, {
		headers: {
			"Accept-Language": language,
		}
	});
}

// 分享裂变: 获取成功邀请的好友列表
export const getInvitedList = async ({ token, language, begin, limit = 30, }) => {
	let url = `/vip/v1/activity/inviteList`;
	if (begin) {
		url += `?begin=${begin}&limit=${limit}`;
	}
	return axios.get(url, {
		headers: {
			"Authorization": `Bearer ${token}`,
			"Accept-Language": language,
		}
	})	
}

// 分享裂变: 获取CHA下载包
export const getChaApkDownloadLink = async (code) => {
	return axios.get(`/package/v1/apk/url/${code}`);
}

// 用户举报链接
export const reportLink = async (link, type, userInfo) => {
	return axios.post('https://api-feedback.mypikpak.com/report', {
		reportType: type,
		resource: link,
		userInfo,
	}, {
		'content-type': 'application/json',
	});
}

// 邀请码兑换会员
export const orderInviteCode = async ({code, deviceId, token, language}) => {
	return axios.post('/vip/v1/order/activation-code', {
		activation_code: code,
		page: 'invite',
	}, {
		headers: {
			Authorization: `Bearer ${token}`,
			'x-device-id': deviceId,
			'Accept-Language': language || navigator.language,
		}	
	});
};

// 查询当前用户是否已经绑定邀请码
export const queryInviterCode = async (userId, language) => {
	return axios.get(`/vip/v1/activity/checkInvite?userid=${userId}`, {
		headers: {
			'Accept-Language': language || navigator.language,
		}
	});
}

/**
 * 获取ip地址及用户网络所处地区
 * data: {
 * 	ip // 用户ip
 *  stable // 是否为国内ip
 * }
 *  */ 
export const getNetworkInfo = async () => {
	return axios.get(`https://access.mypikpak.com/drive/v1/privilege/area_connectivity?time=${Date.now()}`, {
		headers: {
			'Connection': 'close',
		}
	});
}

// 拉取bot订阅列表
export const getSubscribeChannelList = async () => {
	const { accessToken: token, accountDeviceId: deviceId } = await getUserClientInfo();
	return axios.get(`/contentSubscription/v1/subscribe`, {
		headers: {
			Authorization: `Bearer ${token}`,	
			"X-Device-Id": deviceId,
		}
	});
}

// 订阅频道
export const addChannelDescribe = async (params) => {
	const { accessToken: token, accountDeviceId: deviceId } = await getUserClientInfo();
	return axios.post(`/contentSubscription/v1/subscribe`, params, {
		headers: {
			Authorization: `Bearer ${token}`,
			"X-Device-Id": deviceId,
		}
	});	
}

// 取消订阅频道
export const cancelChannelDescribe = async (params) => {
	const { accessToken: token, accountDeviceId: deviceId } = await getUserClientInfo();
	return axios.delete(`/contentSubscription/v1/subscribe`, {
		headers: {
			Authorization: `Bearer ${token}`,
			"X-Device-Id": deviceId,
		},
		data: params,
	});
}

export const getVconsoleGlobalConfig = () => {
	return axios.post(`${config.api.config}/config/v1/debug_options`, {
		client: 'web'
	})
}

export const getUpdateLink = (params) => {
	return axios.post(`${config.api.config}/config/v1/checkClientVersion`, params);
}