const message = {
	'zh-CN': {
		middleware: {
			googlePlay: '已安装，直接打开 >',
			collectionPlatform: '收藏多平台内容',
			supportSpace: '支持10T超大空间',
			shortVideoMode: '畅享短视频模式',
			savingTips: '文件保存中，请打开PikPak App查看。',
			qrCode: '将相机对准 QR Code 查看',
			authorized: '已安装，',
			sendFile: '转发PikPak Bot保存',	
			notYetInstalled: '未安装Play商店',
			downloadAPK: '下载安装包',
			dzoKFoFFoi9vPqzRKcpj8: '请安装PikPak App完成订阅',
			cuCfaTt2kCa6pWzCbkTvG: '频道内容更新后,实时推送',
			cRBWqWeQvGxcRrdR9Wy1: '订阅中',
			gIoZb0qKmePkmsxvzZb7d: '已失效',
			yoIiwee5R7Wx5WYvCidQq: '当前没有订阅',
			bOhEMhpoy5Jx7RwV8782v: 'Telegram频道订阅',
			r11_3mtlafj3N4iV1Wox: '取消成功',
			mRmMgaDspmpJkpBo7mdl8: '订阅成功',
		}
	},
	'zh-TW': {
		middleware: {
			googlePlay: '已安裝，直接打開 >',
			collectionPlatform: '收藏多平台内容',
			supportSpace: '支持10T超大空間',
			shortVideoMode: '暢享短視頻模式',
			savingTips: '文件保存中，請打開PikPak App查看。',
			qrCode: '將相機對準 QR Code 查看',
			authorized: '已安裝，',
			sendFile: '轉發 PikPak Bot保存',	
			notYetInstalled: '未安裝Play商店',
			downloadAPK: '下載安裝包',
			dzoKFoFFoi9vPqzRKcpj8: '請安裝PikPak App完成訂閱',
			cuCfaTt2kCa6pWzCbkTvG: '頻道內容更新後,實時推送',
			cRBWqWeQvGxcRrdR9Wy1: '訂閱中',
			gIoZb0qKmePkmsxvzZb7d: '已失效',
			yoIiwee5R7Wx5WYvCidQq: '當前沒有訂閱',
			bOhEMhpoy5Jx7RwV8782v: 'Telegram頻道訂閱',
			r11_3mtlafj3N4iV1Wox: '取消成功',
			mRmMgaDspmpJkpBo7mdl8: '訂閱成功'
		}
	},
	'en-US': {
		middleware: {
			googlePlay: 'Installed, open directly >',
			collectionPlatform: 'Collect multi-platform files',
			supportSpace: 'Support 10T large storage',
			shortVideoMode: 'Enjoy short video mode',
			savingTips: 'Saving files, please open PikPak app to view.',
			qrCode: 'Point your camera at the QR code to view',
			authorized: 'Installed, ',
			sendFile: 'forward PikPak Bot to save',
			notYetInstalled: 'No Play store',
			downloadAPK: 'Download PikPak',
			dzoKFoFFoi9vPqzRKcpj8: 'Please install PikPak App to complete the subscription',
			cuCfaTt2kCa6pWzCbkTvG: 'After the channel content is updated, it will push to PikPak in real time',
			cRBWqWeQvGxcRrdR9Wy1: 'subscribing',
			gIoZb0qKmePkmsxvzZb7d: 'expired',
			yoIiwee5R7Wx5WYvCidQq: 'Currently not subscribed',
			bOhEMhpoy5Jx7RwV8782v: 'Telegram channel subscription',
			r11_3mtlafj3N4iV1Wox: 'Cancel successfully',
			mRmMgaDspmpJkpBo7mdl8: 'Subscribe successfully'
		}
	},
	'id': {
		middleware: {
			googlePlay: 'Dipasang, buka langsung >',
			collectionPlatform: 'Koleksi konten multi-platform',
			supportSpace: 'Mendukung penyimpanan besar 10T',
			shortVideoMode: 'Nikmati mode video pendek',
			savingTips: 'Menyimpan file, silakan buka aplikasi PikPak untuk melihat.',
			qrCode: 'Arahkan kamera Anda ke kode QR untuk melihat',
			authorized: 'Terinstal, ',
			sendFile: 'teruskan PikPak Bot untuk menyimpan',
			notYetInstalled: 'Tidak ada toko Play',
			downloadAPK: 'Unduh PikPak',
			dzoKFoFFoi9vPqzRKcpj8: 'Silakan instal Aplikasi PikPak untuk menyelesaikan langganan',
			cuCfaTt2kCa6pWzCbkTvG: 'Setelah konten saluran diperbarui, itu akan mendorong ke PikPak secara real time',
			cRBWqWeQvGxcRrdR9Wy1: 'berlangganan',
			gIoZb0qKmePkmsxvzZb7d: 'kedaluwarsa',
			yoIiwee5R7Wx5WYvCidQq: 'Saat ini tidak berlangganan',
			bOhEMhpoy5Jx7RwV8782v: 'Langganan saluran Telegram',
			r11_3mtlafj3N4iV1Wox: 'Batalkan berhasil',
			mRmMgaDspmpJkpBo7mdl8: 'Berlangganan berhasil'
		}
	},
	'ko': {
		middleware: {
			googlePlay: '설치됨, 바로 열기>',
			collectionPlatform: '많은 플렛폼의 컨텐츠 소장',
			supportSpace: '10TB 초대형 용량 지원',
			shortVideoMode: '쇼츠 모드를 즐기기',
			savingTips: '파일 저장 중, PikPak App을 열어서 확인해주세요.',
			qrCode: '카메라로 QR 코드를 스캔하여 확인',
			authorized: '설치됨',
			sendFile: 'PikPak Bot에 전송하여 저장',
			notYetInstalled: 'Play Store 미설치',
			downloadAPK: 'APK 다운로드',
			dzoKFoFFoi9vPqzRKcpj8: 'PikPak App을 설치하여 구독하세요.',
			cuCfaTt2kCa6pWzCbkTvG: '동영상 콘텐츠 업데이트 후, 실시간 푸시',
			cRBWqWeQvGxcRrdR9Wy1: '구독 중',
			gIoZb0qKmePkmsxvzZb7d: '효력을 잃음',
			yoIiwee5R7Wx5WYvCidQq: '현재 구독하지 않았습니다.',
			bOhEMhpoy5Jx7RwV8782v: 'Telegram 영상 구독',
			r11_3mtlafj3N4iV1Wox: '취소 성공',
			mRmMgaDspmpJkpBo7mdl8: '구독 성공',
		}
	}
}

export default message;