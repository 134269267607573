/**
 * @auth 赖传峰
 * @date 2023/9/26
 * @desc 针对 Web Deep Link 拦截 http://wiki.xunlei.cn/pages/viewpage.action?pageId=120401201
 */
import qs from 'qs'


const showDialogActions = ['create_task', 'show_storage_dialog', 'show_mobile_sidebar', 'move_files', 'open_redeem_box', 'open_feedback', 'login_required', 'show_traffic_dialog', 'show_invite_dialog', 'show_beginner_dialog', 'show_activity_dialog', 'show_popup_window']
const urlInstance = deepLink => {
  try {
    return new URL(deepLink)
  } catch (err) {
    console.log('new URL error:', deepLink, err)
    return undefined
  }
}

const webDeepLinkSearch = (deepLink) => {
  const url = urlInstance(deepLink)
  if (!url) return false

  const { action } = qs.parse(url.search.slice(1))
  const actions = Array.isArray(action) ? action : [action]
  return url.pathname.startsWith('/drive')
    && showDialogActions.some(el => actions.some(_el => _el && _el.startsWith(el)))
}

export default async ev => {
  // 在云盘的 dialog 中使用 iframe 打开，一些链接需要被拦截，然后在云盘中响应
  const targetOrigin = new URL(window.location.href).searchParams.get('origin')
  if (window.parent !== window && targetOrigin) {
    const deepLinkAnchor = ev.composedPath().find(el => el instanceof HTMLAnchorElement && webDeepLinkSearch(el.href)) 
    if (deepLinkAnchor) {
      ev.preventDefault()
      const url = urlInstance(deepLinkAnchor.href)
      if (!url) return

      const parsedQuery = qs.parse(url.search.slice(1))
      if (parsedQuery.target === '_blank') return window.open(url)

      window.parent?.postMessage({ action: url.searchParams.get('action') }, targetOrigin)
    }
    const feedbackDeepLinkAnchor = ev.composedPath()
      .find(el => el instanceof HTMLAnchorElement && el.getAttribute('href')?.includes('/drive/feedback'))
    if (feedbackDeepLinkAnchor) {
      ev.preventDefault()
      window.parent?.postMessage({ action: 'open_feedback' }, targetOrigin)
    }
    return
  }
}
