/**
 * @auth liliangkai
 * @date 2023/2/15
 * @description 移动端支持加载VConsole功能
 */

import { getVconsoleGlobalConfig } from '../api'
import { isAndroid, isIOS, isIPad, getUserClientInfo } from './util'

const closeBtnStyle = `
width: 100%; 
height: 32px; 
color: #333; 
background: #fff;
position: absolute; 
top: 130%; 
left: 0px; 
border-radius: 5px; 
box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 3px; 
text-align: center; 
line-height: 32px;
`

let vconsole
let config = { loaded: false, enable: false, uids: [] }

function isMobile() {
  return isAndroid || isIOS || isIPad
}

// 从全局配置获取vconsole相关配置
async function initConfig() {
  try {
    const { data } = await getVconsoleGlobalConfig()
    config = {
      ...config,
      ...(data.value?.values?.debug_options?.allow_vconsole || {}),
      loaded: true,
    }
  } catch (err) {
    console.error('[getVconsoleGlobalConfig failure]: ', err)
  }
}

async function getUserId() {
  try {
    const { userID = '' } = await getUserClientInfo()
    return userID
  } catch(err) {
    console.error('[getUserId failure]: ', err)
    return ''
  }
}

/**
 * 
 * @param {String} name 
 * @param {String} src 
 * @param {Function} onload 
 * @returns 
 */
function createScript(
  name,
  src,
  onload,
) {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`script[data-name="${name}"]`)) {
      resolve()
      return
    }
    const script = document.createElement('script')
    script.setAttribute('data-name', name)
    script.onload = function (ev) {
      onload?.(ev)
      resolve()
    }
    script.onerror = function (error) {
      console.error('[load script failure]: ', error);
      document.body.removeChild(script)
      reject(error)
    }
    script.src = src
    document.body.append(script)
  })
}

function initVConsole() {
  const VConsole = window.VConsole
  vconsole = new VConsole()
  vconsole.setSwitchPosition(20, 50)

  const closeBtn = document.createElement('div')
  closeBtn.innerText = 'close'
  closeBtn.style.cssText = closeBtnStyle
  document.querySelector('#__vconsole .vc-switch')?.appendChild(closeBtn)
  closeBtn.addEventListener('click', (event) => {
    event.stopPropagation()
    vconsole.destroy()
    vconsole = null
  })
}

async function loadVConsole() {
  if (vconsole) return

  if (window.VConsole) {
    initVConsole()
    return
  }

  if (!config.loaded) {
    await initConfig()
  }
  let enable = config.enable
  if(!enable && config.uids && config.uids.length>0) {
    const userId = await getUserId()
    if (userId && config.uids.findIndex(id => id === userId) !== -1) {
      enable = true
    }
  }
  if(!enable) return

  const origin = process.env.NODE_ENV === 'development' ? 'https://dev.mypikpak.com' : 'https://mypikpak.com'
  await createScript('VConsole', `${origin}/scripts/vconsole.min.js`, initVConsole)
}

/**
 * 
 * @param {() => Promise<void>} handle 
 * @returns 
 */
function createClickHandle(handle) {
  let count = 0
  let last = Date.now()
  let processing = false
  return () => {
    const now = Date.now()
    // 点击间隔小于200ms，次数累加1
    count = now - last > 200 ? 1 : count + 1
    last = now

    if (count < 5 || processing) return
    if(typeof handle !== 'function') return

    // 连续点击>=5，触发事件
    processing = true
    handle().finally(() => {
      processing = false
    })
  }
}

export default () => {
  if (!isMobile()) return
  const handleClick = createClickHandle(loadVConsole)
  document.addEventListener('click', handleClick)
  window.addEventListener('beforeunload', () => {
    document.removeEventListener('click', handleClick)
    if (!vconsole) return
    vconsole.destroy()
    vconsole = null
  })
}
