const message = {
	'zh-CN': {
		report: {
			reportPageTitle: "举报有害链接",
			reportLink: "举报链接",
			reportCause: "举报原因",
			enterAuditLink: "请输入需要提交给PikPak审核的链接",
			reportType_porn: "这是色情、性剥削、亵渎性内容链接",
			reportType_political: "这是恐怖主义、极端主义、政治内容链接",
			reportType_violence: "这是暴力、血腥、死亡、虐待内容链接",
			submit: "提交",
			reportSuccess: "举报成功",
			reportFailed: "举报失败，请稍后重试",
			invalidLink: "当前链接无效",
		}
	},
	'zh-TW': {
		report: {
			reportPageTitle: "舉報有害鏈接",
			reportLink: "舉報鏈接",
			reportCause: "舉報原因",
			enterAuditLink: "請輸入需要提交給PikPak審核的鏈接",
			reportType_porn: "這是色情、性剝削、褻瀆性內容鏈接",
			reportType_political: "這是恐怖主義、極端主義、政治內容鏈接",
			reportType_violence: "這是暴力、血腥、死亡、虐待內容鏈接",
			submit: "提交",
			reportSuccess: "舉報成功",
			reportFailed: "舉報失敗，請稍後重試",
			invalidLink: "當前鏈接無效",
		}
	},
	'en-US': {
		report: {
			reportPageTitle: "Report malicious link",
			reportLink: "Report link",
			reportCause: "Reason for report",
			enterAuditLink: "Please input link to be submitted to PikPak for review:",
			reportType_porn: "This link contains pornographic, sexually exploitative or profane content.",
			reportType_political: "This link contains terrorist, extremist, or political content.",
			reportType_violence: "This link depicts violence, gore, death, or abuse.",
			submit: "Submit",
			reportSuccess: "Reported successfully.",
			reportFailed: "Report failed. Please try again later.",
			invalidLink: "The current link is invalid",
		}
	},
	'id': {
		report: {
			reportPageTitle: "Laporkan pautan hasad",
			reportLink: "Pautan laporan",
			reportCause: "Sebab laporan",
			enterAuditLink: "Sila input pautan untuk diserahkan kepada PikPak untuk penilaian:",
			reportType_porn: "Tautan ini berisi konten pornografi, eksploitatif seksual, atau tidak senonoh.",
			reportType_political: "Pautan ini mengandungi kandungan pengganas, ekstremis, atau politik.",
			reportType_violence: "Pautan ini memaparkan keganasan, kematian, atau penderaan.",
			submit: "Serahkan",
			reportSuccess: "Berjaya dilaporkan.",
			reportFailed: "Laporan gagal. Sila cuba lagi kemudian.",
			invalidLink: "Tautan saat ini tidak valid",
		}
	},
	'ar': {
		report: {
			reportPageTitle: "الإبلاغ عن ارتباط ضار",
			reportLink: "الإبلاغ عن رابط",
			reportCause: "سبب الإبلاغ",
			enterAuditLink: "يُرجى إدخال الرابط لإرساله إلى PikPak لمراجعته:",
			reportType_porn: "يحتوي هذا الرابط على محتوى إباحي أو استغلال جنسي أو محتوى بذيء.",
			reportType_political: "يحتوي هذا الرابط على محتوى إرهابي أو عنيف أو متطرف أو سياسي.",
			reportType_violence: "يقدم هذا الرابط صورًا عن العنف أو الدماء أو الموت أو الإساءة.",
			submit: "إرسال",
			reportSuccess: "تم الإبلاغ بنجاح.",
			reportFailed: "فشل الإبلاغ. يُرجى إعادة المحاولة لاحقًا.",
			invalidLink: "الرابط الحالي غير صالح",
		}
	},
	'ms': {
		report: {
			reportPageTitle: "Laporkan pautan hasad",
			reportLink: "Pautan laporan",
			reportCause: "Sebab laporan",
			enterAuditLink: "Sila input pautan untuk diserahkan kepada PikPak untuk penilaian:",
			reportType_porn: "Pautan ini mengandungi kandungan lucah, eksploitasi seksual atau profan.",
			reportType_political: "Pautan ini mengandungi kandungan pengganas, ekstremis, atau politik.",
			reportType_violence: "Pautan ini memaparkan keganasan, kematian, atau penderaan.",
			submit: "Serahkan",
			reportSuccess: "Berjaya dilaporkan.",
			reportFailed: "Laporan gagal. Sila cuba lagi kemudian.",
			invalidLink: "Tautan saat ini tidak valid",
		}
	}
}

export default message;