const api = {
	pikpak: 'https://api-drive.mypikpak.com',
	config: 'https://config.mypikpak.com',
}

if (process.env.NODE_ENV === 'development') {
	api.pikpak = 'https://dev-api-drive.mypikpak.com';
	// api.pikpak = 'https://api-drive.mypikpak.com';
	api.config = 'https://dev-config.mypikpak.com'
}

export default {
	api,
}