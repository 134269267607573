import { copyToClipboard, getUserInfo } from './jsBridge';

export function isPC() {
  var userAgentInfo = navigator.userAgent;
  var Agents = new Array(
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod',
  );
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}

// 判断是否为ios
export const isIOS = !!window.navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

// 判断是否为iPad
export const isIPad = (navigator.userAgent.match(/(iPad)/) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1));

// 判断是否为android设备
export const isAndroid = window.navigator.userAgent.indexOf('Android') > -1 || window.navigator.userAgent.indexOf('Adr') > -1;

// 因为使用了px2rem, 所以pc访问有点问题需要使用resize限制大小
export function resize() {
  document.querySelector('html').style.fontSize = window.innerWidth >= 650 ? '65px' : '10vw';
  window.addEventListener('resize', () => {
    document.querySelector('html').style.fontSize = window.innerWidth >= 650 ? '65px' : '10vw';
  });
}

// 节流函数, 默认时间500ms执行一次
export function throttle(fn, time = 500) {
  let now = Date.now();
  return (...args) => {
    if (Date.now() - now < time) {
      return;
    }
    fn(...args);
    now = Date.now();
  }
}

// 复制文本内容到粘贴板
export function copyLinkToClipboard(text) {
  return new Promise((resolve, reject) => {
    try {
      copyToClipboard({ text }, (result) => {
        resolve(/true/i.test(result));
      });
    } catch (err) {
      console.error('[copyLinkToClipboard: ]', err);
      reject(false);
    }
  })
}

// 获取android的accessToken
export const getUserClientInfo = () => new Promise(resolve => {
  getUserInfo(null, async (data) => {
    try {
      return resolve(JSON.parse(data));
    } catch(err) {
      return resolve('');
    }
  });
});

// ios smart app banner 支持
export const enableIosSmartAppBanner = (url) => {
  // insert smart app banner
  const meta = document.createElement('meta');
  meta.name = 'apple-itunes-app';
  let content = 'app-id=1616861537';
  if (url) {
    content = content + `, app-argument=${url}`;
  }
  meta.content = content;
  document.head.appendChild(meta);
}

/**
 * 判断是否为PikPak客户端
 */
export const isPikPakClient = (ua) => /PikPak/i.test(ua || window.navigator.userAgent)