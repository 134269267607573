const message = {
	'zh-CN': {
		help: {
			addNow: '立即加入',
			copyLinkDescribe1: '在第三方平台看到喜欢的资源, 可以复制资源链接, 打开PikPak客户端便能自动识别开始传输.',
			copyLinkStep1: '复制文件链接',
			copyLinkStep2: '打开PikPak自动识别, 点击添加',
			forwardInOtherPlatform: '转发其它Apps的文件',
			forwardInTelegram: 'Telegram内的转发',
			officeGroupDescribe1: '加入官方群组获取更多教程哦。',
			officeGroupName: 'PikPak官方群组',
			officialGroupIntroduce: '官方群组推荐',
			pageTitle: '使用帮助',
			step1: '步骤1',
			step2: '步骤2',
			step3: '步骤3',
			systemShareDescribe1: '或者你也可以通过系统分享来收藏文件。',
			systemShareStep1: '打开系统分享, 找到Save to PikPak。',
			systemShareStep2: '点击后会调起PikPak，并添加文件',
			telegramBotDescribe1: '你可以直接把Telegram里的文件转发给Bot, 我们会自动为你保存到PikPak.',
			telegramBotDescribe2: '同时也支持你把其他平台的资源转发给bot。',
			telegramBotDescribe3: '支持收藏转发Telegram内的文件到Bot，也支持把其它平台的分享转发给Bot。\n(支持Twitter、TikTok、Facebook)',
			telegramBotStep1: '点击“打开 Telegram Bot”',
			telegramBotStep2: '发送喜欢的文件给Bot',
			telegramBotStep3: '查看Bot的保存提示',
			telegramBotTitle1: '步骤1',
			telegramBotTitle2: '步骤2',
			topDescribe1: 'PikPak是一个收藏文件的百宝箱, 可以把喜欢的任何文件保存云盘, 进行随时消费.',
			topDescribe2: '下面教你一些收藏文件的小技能。',
			copyLink: '复制链接',
			systemShare: '系统分享',
			telegramBot: 'Telegram Bot',
			'uhvko8xJ5E0QiP6z69yyf': '本地上传',
			'9sK2ydm60NjgkUzJm8i2d': '点击本地上传',
			'zgdvyQprMoKuuXvWkNq6O': '选择要保存的照片或文件',
			'mMvj85iuOMNi29BjT0Yg': '点击确认上传按钮',
			'pP4OtIiSRh5GFjbCbu74': 'Web云盘',
			'hImPkZSjqIgvHaYImtVss': '访问PikPak网页版体验更多资源传输方式。',
			'zskyHf6wTdGS4BznCbjv': '立即打开',
			pikpakWebsite: 'PikPak 网页版',
		}
	},
	'zh-TW': {
		help: {
			addNow: '立即加入',
			copyLinkDescribe1: '在第三方平台看到喜歡的資源, 可以復制資源鏈接, 打開PikPak客戶端便能自動識別開始傳輸.',
			copyLinkStep1: '複製文件鏈接',
			copyLinkStep2: '打開PikPak自動識別，點擊添加',
			forwardInOtherPlatform: '轉發其它Apps的文件',
			forwardInTelegram: 'Telegram內的轉發',
			officeGroupDescribe1: '加入官方群組獲取更多教程哦。',
			officeGroupName: 'PikPak官方群組',
			officialGroupIntroduce: '官方群組推薦',
			pageTitle: '使用幫助',
			step1: '步驟1',
			step2: '步驟2',
			step3: '步驟3',
			systemShareDescribe1: '或者你也可以通過系統分享來收藏文件。',
			systemShareStep1: '打開系統分享，找到Save to PikPak。',
			systemShareStep2: '點擊後會調起PikPak，並添加文件',
			telegramBotDescribe1: '你可以直接把Telegram裏的文件轉發給Bot，我們會自動為你保存到PikPak。',
			telegramBotDescribe2: '同時也支持你把其他平臺的資源轉發給bot。',
			telegramBotDescribe3: '支持收藏轉發Telegram內的文件到Bot，也支持把其它平臺的分享轉發給Bot。\n（支持Twitter、TikTok、Facebook）',
			telegramBotStep1: '點擊“打開Telegram Bot”',
			telegramBotStep2: '發送喜歡的文件給Bot',
			telegramBotStep3: '查看Bot的保存提示',
			telegramBotTitle1: '步驟1',
			telegramBotTitle2: '步驟2',
			topDescribe1: 'PikPak是一個收藏文件的百寶箱，可以把喜歡的任何文件保存雲盤，進行隨時消費。',
			topDescribe2: '下麵教你一些收藏文件的小技能。',
			copyLink: '複製連結',
			systemShare: '系統分享',
			telegramBot: 'Telegram Bot',
			"uhvko8xJ5E0QiP6z69yyf":"本地上傳",
			"9sK2ydm60NjgkUzJm8i2d":"點擊本地上傳",
			"zgdvyQprMoKuuXvWkNq6O":"選擇要保存的照片或文件",
			"mMvj85iuOMNi29BjT0Yg":"點擊確認上傳按鈕",
			"pP4OtIiSRh5GFjbCbu74":"Web雲盤",
			"hImPkZSjqIgvHaYImtVss":"訪問PikPak網頁版體驗更多資源傳輸方式。",
			"zskyHf6wTdGS4BznCbjv":"立即打開",
			pikpakWebsite: 'PikPak 網頁版',
		}
	},
	'en-US': {
		help: {
			addNow: 'Join now',
			copyLinkDescribe1: 'When you see the resource you like on the third-party platform, you can copy the resource link and open the PikPak client to automatically recognize and start the transmission.',
			copyLinkStep1: 'Copy file link',
			copyLinkStep2: 'Open PikPak automatic recognition, click Add',
			forwardInOtherPlatform: 'Forward to Telegram from other Apps',
			forwardInTelegram: 'Forwarding within Telegram',
			officeGroupDescribe1: 'Join the official group for more tutorials.',
			officeGroupName: 'PikPak Office Group',
			officialGroupIntroduce: 'Official group recommendation',
			pageTitle: 'Help Center',
			step1: 'Step1',
			step2: 'Step2',
			step3: 'Step3',
			systemShareDescribe1: 'Or you can also collect files through system share. ',
			systemShareStep1: 'Open system share, find Save to PikPak',
			systemShareStep2: 'After clicking, the file will be added',
			telegramBotDescribe1: 'You can directly forward the files in Telegram to Bot, and we will automatically save them to PikPak. ',
			telegramBotDescribe2: 'Bot also supports to forward resources from other platforms. ',
			telegramBotDescribe3: 'Supports collection and forwarding of files in Telegram to Bot, and also supports sharing and forwarding of other platforms to Bot.\n (supports Twitter, TikTok, Facebook)',
			telegramBotStep1: 'Click \'Open Telegram Bot\'',
			telegramBotStep2: 'Send favorite files to the bot',
			telegramBotStep3: 'View Bot save tips',
			telegramBotTitle1: 'Step1',
			telegramBotTitle2: 'Step2',
			topDescribe1: 'PikPak is a treasure box for collecting files, you can save any file you like in the cloud disk for consumption at any time.',
			topDescribe2: 'Here are some tips for collecting files.',
			copyLink: 'Copy Link',
			systemShare: 'System Share',
			telegramBot: 'Telegram Bot',
			"uhvko8xJ5E0QiP6z69yyf":"Local Upload",
			"9sK2ydm60NjgkUzJm8i2d":"Click to upload locally",
			"zgdvyQprMoKuuXvWkNq6O":"Choose a photo or file to save",
			"mMvj85iuOMNi29BjT0Yg":"Click the upload button to confirm",
			"pP4OtIiSRh5GFjbCbu74":"Web Drive",
			"hImPkZSjqIgvHaYImtVss":"Visit PikPak web version to experience more resource transfer methods.",
			"zskyHf6wTdGS4BznCbjv":"Open now",
			pikpakWebsite: 'PikPak Web Drive',
		}
	},
	'id': {
		help: {
			addNow: 'Bergabunglah sekarang.',
			copyLinkDescribe1: 'Ketika Anda melihat sumber daya yang Anda sukai di platform pihak ketiga, Anda dapat menyalin link sumber daya, membuka klien Pik Pak, secara otomatis mengidentifikasi dan memulai transmisi.',
			copyLinkStep1: 'Salin pautan fail',
			copyLinkStep2: 'Buka Pengenalan Otomatis Pik Pak, klik untuk menambah',
			forwardInOtherPlatform: 'Meneruskan dari Apps lain ke Telegram',
			forwardInTelegram: 'Perpanjangan dalam telegram',
			officeGroupDescribe1: 'Bergabunglah dengan tim resmi dan pelajari lebih banyak tutorial.',
			officeGroupName: 'Grup Kantor Pik Pak',
			officialGroupIntroduce: 'Rekomendasi Komunita Resmi',
			pageTitle: 'Pusat Bantuan',
			step1: 'Langkah 1',
			step2: 'Langkah kedua.',
			step3: 'Langkah 3',
			systemShareDescribe1: 'Atau, Anda juga bisa mengumpulkan berkas dengan berbagi sistem.',
			systemShareStep1: 'Buka Perkongsian Sistem dan Cari Save to PikPak',
			systemShareStep2: 'Setelah klik, berkas akan ditambahkan',
			telegramBotDescribe1: 'Anda dapat meneruskan file di Telegram langsung ke Bot, dan kami secara otomatis akan menyimpannya ke Pik Pak.',
			telegramBotDescribe2: 'BOT juga mendukung untuk meneruskan sumber daya dari platform lain.',
			telegramBotDescribe3: 'Mendukung pengumpulan dan meneruskan file dalam Telegram ke Bot, juga mendukung berbagi dan meneruskan platform lain ke Bot.\n (mendukung Twitter, Tik Tok, Facebook)',
			telegramBotStep1: 'Klik \'Buka Robot Telegram.\'',
			telegramBotStep2: 'Hantar fail koleksi ke robot',
			telegramBotStep3: 'Skatīt bot saglabājumu',
			telegramBotTitle1: 'Langkah 1',
			telegramBotTitle2: 'Langkah kedua.',
			topDescribe1: 'Pik Pak adalah kotak harta karun untuk mengumpulkan berkas, dan Anda dapat menyimpan file yang Anda sukai setiap saat untuk dikonsumsi dalam piringan awan.',
			topDescribe2: 'Berikut ini beberapa tips untuk mengumpulkan dokumen.',
			copyLink: 'Salin pautan',
			systemShare: 'Perkongsian Sistem',
			telegramBot: 'Mesej Boot',
			"uhvko8xJ5E0QiP6z69yyf":"Unggah Lokal",
			"9sK2ydm60NjgkUzJm8i2d":"Click to upload locally",
			"zgdvyQprMoKuuXvWkNq6O":"Klik untuk mengunggah secara lokal",
			"mMvj85iuOMNi29BjT0Yg":"Klik tombol unggah untuk mengonfirmasi",
			"pP4OtIiSRh5GFjbCbu74":"Drive Web",
			"hImPkZSjqIgvHaYImtVss":"Kunjungi versi web PikPak untuk mengalami lebih banyak metode transfer sumber daya.",
			"zskyHf6wTdGS4BznCbjv":"Buka sekarang",
			pikpakWebsite: 'Drive Web PikPak',
		}
	}
}

export default message;