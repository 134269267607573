import Vue from 'vue'
import VueRouter from 'vue-router'
import Forbidden from '../views/Forbidden.vue'
import CdKey from '../views/activity/CdKey.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/403', 
  },
  {
    path: '/403',
    name: 'Forbidden',
    component: Forbidden,
  },
  // 用户补偿页面
  {
    path: '/compensation',
    name: 'Compensation',
    component: () => import('../views/Compensation.vue'),
  },
  // 网速测试
  {
    path: '/ping',
    name: 'Ping',
    component: () => import('../views/Ping.vue'),
  },
  {
    path: '/toapp',
    name: 'ToApp',
    component: () => import('../views/ToApp.vue'),
  },
  {
    path: '/policy/user-agreement',
    name: 'Policy',
    component: () => import('../views/policy/UserAgreement.vue'),
  },
  {
    path: '/policy/privacy-policy',
    name: 'Agreement',
    component: () => import('../views/policy/PrivacyPolicy.vue'),
  },
  {
    path: '/help',
    name: 'HowToGetFile',
    component: () => import('../views/help/HowToGetFile.vue'),
  },
  {
    path: '/activity/cdKey',
    name: 'CdKey',
    component: CdKey,
  },
  {
    path: '/activity/invitation',
    name: 'Invitation',
    component: () => import('../views/activity/invitation.vue'),
  },
  {
    path: '/activity/invited',
    name: 'Invited',
    component: () => import('../views/activity/invited.vue'),
  },
  {
    path: '/payment/status',
    name: 'PaymentStatus',
    component: () => import('../views/payment/PaymentStatus.vue'),
  },
  {
    path: '/report',
    name: 'Report',
    component: () => import('../views/report/index.vue'),
  },
  {
    path: '/bot-sub',
    name: 'BotSub',
    component: () => import('../views/BotSubscription.vue'),
  },
  {
    path: '/payment-error',
    name: 'PaymentError',
    component: () => import('../views/notices/PaymentError.vue'),
  },
  {
    path: '/update-guide',
    name: 'UpdateGuide',
    component: () => import('../views/notices/UpdateGuide.vue'),
  },
  {
    path: '/official-compensation',
    name: 'OfficialCompensation',
    component: () => import('../views/notices/OfficialCompensation.vue'),
  },
  {
    path: '/official-compensation2',
    name: 'OfficialCompensation2',
    component: () => import('../views/notices/OfficialCompensation2.vue'),
  },
  {
    path: '/official-compensation3',
    name: 'OfficialCompensation3',
    component: () => import('../views/notices/OfficialCompensation3.vue'),
  },
  {
    path: '/service-compensation',
    name: 'ServiceCompensation',
    component: () => import('../views/notices/ServiceCompensation.vue'),
  },
  {
    path: '/premium-deduct',
    name: 'PremiumDeduct',
    component: () => import('../views/notices/PremiumDeduct.vue'),
  },
  {
    path: '/ko-anniversary',
    name: 'KoAnniversary',
    component: () => import('../views/activity/KoAnniversary.vue'),
  },
  {
    path: '/reward-warn',
    name: 'RewardWarn',
    component: () => import('../views/notices/RewardWarn.vue'),
  },
  {
    path: '/purchase-attention',
    name: 'PurchaseAttention',
    component: () => import('../views/notices/PurchaseAttention.vue'),
  },
  {
    path: '/account-conflict',
    name: 'AccountConflict',
    component: () => import('../views/notices/AccountConflict.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
