const message = {
  'zh-CN': {
    ping: {
      "zUOuajK9Vrj7wRdNtyr": "检测中",
      "c662JNupxRu5DtUHwAw0": "本地IP地址:",
      "1r9dIqr2zIviPoOq2Aztc": "服务状态(新加坡):",
      "tAdhSqcWdWeXzzSobsaZq": "服务连接耗时(新加坡):",
      "5_1K79Hqnu9TKruUjoNn": "长期连接稳定性:",
      "vkcGxcTjpTxToo35QMcdt": "PikPak作为国际化产品，当前主服务中心位于新加坡。",
      "wwzBWipPz3nkVivzStLf": "由于全球网络环境的复杂性，以及部分国家地区存在的网络审查和防火墙制度，可能存在服务连接不稳定的情况。",
      "zHemiM0V6bl0gEOcwbfXc": "我们会不断优化服务性能，并逐步扩充服务中心，但无法仅靠我们自己解决所有网络连接问题。",
      "xVfFbaIBtklnflZxR7e8": "如果出现网络服务连通问题，您可以借助以上诊断信息，尝试调整您的网络环境。",
      "vEkphatLOtyJjGhNDyPw": "反馈网络连接问题 >",
      "gnFxDuY5Zh2Cdm8Kb7cXn": "不展示",
      "fGsuBJTa5Iw3berR8JHv": "不确定",
      "1qGde72PyCdLgTt7GFi5R": "普通",
      "ce4RtjiBqWjlpXpUYnRej": "较好",
      "yMhJqwmMdfGnl0Qj3xkz": "很好",
      "gRtOuwToeU0c32k1R909": "未连接",
      "0lDDftiEmUpQi4wovbKf": "服务连通性",
      "ltpWhJb7kpeZyTkdn7gm": "未知",
      "lVsZIiC9CuHtV7x1yIxB": "稳定",
      "qCjpYehQzSHuku4ThHzm": "正常",
			"lskdjocwlkalskALFJKD": "重新检测"
    }
  },
  'zh-TW': {
    ping: {
      "zUOuajK9Vrj7wRdNtyr": "檢測中",
      "c662JNupxRu5DtUHwAw0": "本地IP地址:",
      "1r9dIqr2zIviPoOq2Aztc": "服務狀態(新加坡):",
      "tAdhSqcWdWeXzzSobsaZq": "服務連接耗時(新加坡):",
      "5_1K79Hqnu9TKruUjoNn": "長期連接穩定性:",
      "vkcGxcTjpTxToo35QMcdt": "PikPak作為國際化產品，當前主服務中心位於新加坡。",
      "wwzBWipPz3nkVivzStLf": "由於全球網絡環境的複雜性，以及部分國家地區存在的網絡審查和防火牆制度，可能存在服務連接不穩定的情況。",
      "zHemiM0V6bl0gEOcwbfXc": "我們會不斷優化服務性能，並逐步擴充服務中心，但無法僅靠我們自己解決所有網絡連接問題。",
      "xVfFbaIBtklnflZxR7e8": "如果出現網絡服務連通問題，您可以藉助以上診斷信息，嘗試調整您的網絡環境。",
      "vEkphatLOtyJjGhNDyPw": "反饋網絡連接問題>",
      "gnFxDuY5Zh2Cdm8Kb7cXn": "不展示",
      "fGsuBJTa5Iw3berR8JHv": "不確定",
      "1qGde72PyCdLgTt7GFi5R": "普通",
      "ce4RtjiBqWjlpXpUYnRej": "較好",
      "yMhJqwmMdfGnl0Qj3xkz": "很好",
      "gRtOuwToeU0c32k1R909": "未連接",
      "0lDDftiEmUpQi4wovbKf": "服務連通性",
      "ltpWhJb7kpeZyTkdn7gm": "未知",
      "lVsZIiC9CuHtV7x1yIxB": "穩定",
      "qCjpYehQzSHuku4ThHzm": "正常",
			"lskdjocwlkalskALFJKD": "重新檢測"
    }
  },
  'en-US': {
    ping: {
      "zUOuajK9Vrj7wRdNtyr": "Checking",
      "c662JNupxRu5DtUHwAw0": "Local IP Address:",
      "1r9dIqr2zIviPoOq2Aztc": "Service Status (Singapore):",
      "tAdhSqcWdWeXzzSobsaZq": "Service Connection Time (Singapore):",
      "5_1K79Hqnu9TKruUjoNn": "Long Term Connection Stability:",
      "vkcGxcTjpTxToo35QMcdt": "As an international product, PikPak's main service center is currently located in Singapore.",
      "wwzBWipPz3nkVivzStLf": "Due to the complexity of global network environment, as well as network censorship and firewall systems in place in some countries and regions, service connections may be unstable.",
      "zHemiM0V6bl0gEOcwbfXc": "We will continue to optimize service performance and gradually expand the service center. However, we are unable to solve all network connection problems on our own.",
      "xVfFbaIBtklnflZxR7e8": "If you experience connectivity issues with your network services, you can use the diagnostic information above to try adjusting your network environment.",
      "vEkphatLOtyJjGhNDyPw": "Feedback network connectivity issues >",
      "gnFxDuY5Zh2Cdm8Kb7cXn": "Don't show",
      "fGsuBJTa5Iw3berR8JHv": "Uncertain",
      "1qGde72PyCdLgTt7GFi5R": "Ordinary",
      "ce4RtjiBqWjlpXpUYnRej": "Better",
      "yMhJqwmMdfGnl0Qj3xkz": "Good",
      "gRtOuwToeU0c32k1R909": "Not connected",
      "0lDDftiEmUpQi4wovbKf": "Service connectivity",
      "ltpWhJb7kpeZyTkdn7gm": "Unknown",
      "lVsZIiC9CuHtV7x1yIxB": "Stable",
      "qCjpYehQzSHuku4ThHzm": "Normal",
			"lskdjocwlkalskALFJKD": "Retest"
    }
  },
  'id': {
    ping: {
      "zUOuajK9Vrj7wRdNtyr": "Memeriksa",
      "c662JNupxRu5DtUHwAw0": "Alamat IP Lokal:",
      "1r9dIqr2zIviPoOq2Aztc": "Status Layanan (Singapura):",
      "tAdhSqcWdWeXzzSobsaZq": "Waktu Koneksi Layanan (Singapura):",
      "5_1K79Hqnu9TKruUjoNn": "Stabilitas Koneksi Jangka Panjang:",
      "vkcGxcTjpTxToo35QMcdt": "Sebagai produk internasional, pusat layanan utama PikPak saat ini berlokasi di Singapura.",
      "wwzBWipPz3nkVivzStLf": "Karena kompleksitas lingkungan jaringan global, serta sensor jaringan dan sistem firewall yang diterapkan di beberapa negara dan wilayah, koneksi layanan mungkin tidak stabil.",
      "zHemiM0V6bl0gEOcwbfXc": "Kami akan terus mengoptimalkan kinerja layanan dan memperluas pusat layanan secara bertahap. Namun, kami tidak dapat menyelesaikan semua masalah koneksi jaringan sendiri.",
      "xVfFbaIBtklnflZxR7e8": "Jika Anda mengalami masalah konektivitas dengan layanan jaringan Anda, Anda dapat menggunakan informasi diagnostik di atas untuk mencoba menyesuaikan lingkungan jaringan Anda.",
      "vEkphatLOtyJjGhNDyPw": "Umpan balik masalah konektivitas jaringan >",
      "gnFxDuY5Zh2Cdm8Kb7cXn": "Jangan tampilkan",
      "fGsuBJTa5Iw3berR8JHv": "Tidak pasti",
      "1qGde72PyCdLgTt7GFi5R": "Biasa",
      "ce4RtjiBqWjlpXpUYnRej": "Lebih baik",
      "yMhJqwmMdfGnl0Qj3xkz": "Bagus",
      "gRtOuwToeU0c32k1R909": "Tidak terhubung",
      "0lDDftiEmUpQi4wovbKf": "Konektivitas layanan",
      "ltpWhJb7kpeZyTkdn7gm": "Tidak dikenal",
      "lVsZIiC9CuHtV7x1yIxB": "Stabil",
      "qCjpYehQzSHuku4ThHzm": "Normal",
			"lskdjocwlkalskALFJKD": "Tes ulang"
    }
  },
  'ar': {
    ping: {
      "zUOuajK9Vrj7wRdNtyr": "تدقيق",
      "c662JNupxRu5DtUHwAw0": "عنوان IP المحلي:",
      "1r9dIqr2zIviPoOq2Aztc": "حالة الخدمة (سنغافورة):",
      "tAdhSqcWdWeXzzSobsaZq": "وقت اتصال الخدمة (سنغافورة):",
      "5_1K79Hqnu9TKruUjoNn": "استقرار الاتصال طويل المدى:",
      "vkcGxcTjpTxToo35QMcdt": "كمنتج دولي ، يقع مركز الخدمة الرئيسي لشركة PikPak حاليًا في سنغافورة.",
      "wwzBWipPz3nkVivzStLf": "نظرًا لتعقيد بيئة الشبكة العالمية ، فضلاً عن الرقابة على الشبكة وأنظمة جدار الحماية المعمول بها في بعض البلدان والمناطق ، فقد تكون اتصالات الخدمة غير مستقرة.",
      "zHemiM0V6bl0gEOcwbfXc": "سنستمر في تحسين أداء الخدمة وتوسيع مركز الخدمة تدريجيًا. ومع ذلك ، لا يمكننا حل جميع مشكلات اتصال الشبكة بمفردنا.",
      "xVfFbaIBtklnflZxR7e8": "إذا كنت تواجه مشكلات في الاتصال بخدمات الشبكة الخاصة بك ، فيمكنك استخدام المعلومات التشخيصية أعلاه لمحاولة ضبط بيئة الشبكة الخاصة بك.",
      "vEkphatLOtyJjGhNDyPw": "تعليقات على مشكلات الاتصال بالشبكة>",
      "gnFxDuY5Zh2Cdm8Kb7cXn": "لا تظهر",
      "fGsuBJTa5Iw3berR8JHv": "غير مؤكد",
      "1qGde72PyCdLgTt7GFi5R": "عادي",
      "ce4RtjiBqWjlpXpUYnRej": "أفضل",
      "yMhJqwmMdfGnl0Qj3xkz": "جيد",
      "gRtOuwToeU0c32k1R909": "غير متصل",
      "0lDDftiEmUpQi4wovbKf": "اتصال الخدمة",
      "ltpWhJb7kpeZyTkdn7gm": "مجهول",
      "lVsZIiC9CuHtV7x1yIxB": "مستقر",
      "qCjpYehQzSHuku4ThHzm": "طبيعي",
			"lskdjocwlkalskALFJKD": "إعادة الاختبار"
    }
  },
  'ms': {
    ping: {
      "zUOuajK9Vrj7wRdNtyr": "Menyemak",
      "c662JNupxRu5DtUHwAw0": "Alamat IP Setempat:",
      "1r9dIqr2zIviPoOq2Aztc": "Status Perkhidmatan (Singapura):",
      "tAdhSqcWdWeXzzSobsaZq": "Masa Sambungan Perkhidmatan (Singapura):",
      "5_1K79Hqnu9TKruUjoNn": "Kestabilan Sambungan Jangka Panjang:",
      "vkcGxcTjpTxToo35QMcdt": "Sebagai produk antarabangsa, pusat perkhidmatan utama PikPak kini terletak di Singapura.",
      "wwzBWipPz3nkVivzStLf": "Disebabkan oleh kerumitan persekitaran rangkaian global, serta penapisan rangkaian dan sistem tembok api yang ada di sesetengah negara dan wilayah, sambungan perkhidmatan mungkin tidak stabil.",
      "zHemiM0V6bl0gEOcwbfXc": "Kami akan terus mengoptimumkan prestasi perkhidmatan dan mengembangkan pusat perkhidmatan secara beransur-ansur. Walau bagaimanapun, kami tidak dapat menyelesaikan semua masalah sambungan rangkaian sendiri.",
      "xVfFbaIBtklnflZxR7e8": "Jika anda mengalami masalah ketersambungan dengan perkhidmatan rangkaian anda, anda boleh menggunakan maklumat diagnostik di atas untuk cuba melaraskan persekitaran rangkaian anda.",
      "vEkphatLOtyJjGhNDyPw": "Maklum balas isu sambungan rangkaian >",
      "gnFxDuY5Zh2Cdm8Kb7cXn": "jangan tunjuk",
      "fGsuBJTa5Iw3berR8JHv": "Tak pasti",
      "1qGde72PyCdLgTt7GFi5R": "Biasa",
      "ce4RtjiBqWjlpXpUYnRej": "lebih baik",
      "yMhJqwmMdfGnl0Qj3xkz": "Baik",
      "gRtOuwToeU0c32k1R909": "Tidak bersambung",
      "0lDDftiEmUpQi4wovbKf": "Ketersambungan perkhidmatan",
      "ltpWhJb7kpeZyTkdn7gm": "Tidak diketahui",
      "lVsZIiC9CuHtV7x1yIxB": "Stabil",
      "qCjpYehQzSHuku4ThHzm": "Biasalah",
			"lskdjocwlkalskALFJKD": "Uji semula"
    }
  }
}
export default message;